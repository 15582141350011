import React, { Suspense, lazy, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobileDevice } from '../../../helpers/utils';
import actions from '../../../redux/socket/actions';
import appSocket from '../../../sockets/socket';
import Header from '../header';
import Footer from '../footer/footer';
import BottomMenu from '../bottom-menu';
import { isExitEvent } from './utils';
import Loader from '../../controls/loader';
import { reStoreAuthToken } from '../../../helpers/localStorageUtils';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { cookieSelectors } from '../../../redux/cookie/selectors';
import { gameReducers } from '../../../redux/games/games_list/slice';
import { notificationReducers } from '../../../redux/notifications/slice';
import { IMainLayoutProps } from './types';
import { live_chat_license } from '../../../config';
import LiveChat from '../../controls/live-chat';
import { GAME_LOAD_TYPE } from '../../../redux/settings/types';
import { settingsUI } from '../../../redux/settings/selectors';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const license = process.env.REACT_APP_LIVE_CHAT;
const LoginModal = lazy(() => {
	return import('../../modals/login');
});
const RegistrationModal = lazy(() => {
	return import('../../modals/registration');
});

const ForgetModal = lazy(() => {
	return import('../../modals/forget');
});

const ResetModal = lazy(() => {
	return import('../../modals/reset');
});

const InboxInnerModal = lazy(() => {
	return import('../../modals/inbox-inner');
});

const DeactivateModal = lazy(() => {
	return import('../../modals/deactivate');
});

const DepositeModal = lazy(() => {
	return import('../../modals/deposit');
});

const InfoModal = lazy(() => {
	return import('../../modals/info');
});

const AvatarUploadModal = lazy(() => {
	return import('../../modals/avatar-upload');
});

const DeleteAvatarModal = lazy(() => {
	return import('../../modals/delete-avatar');
});

const ReactivateModal = lazy(() => {
	return import('../../modals/reactivate');
});

const TimeOutModal = lazy(() => {
	return import('../../modals/time-out');
});

const CookieModal = lazy(() => {
	return import('../../modals/cookieModal');
});

const TournamentInnerGamesModal = lazy(() => {
	return import('../../modals/tournaments/tournament/tournamentGames');
});

const TournamentInnerTermsModal = lazy(() => {
	return import('../../modals/tournaments/tournament/tournamentTerms');
});

const VerificationModal = lazy(() => {
	return import('../../modals/verification');
});

const TwoFAModal = lazy(() => {
	return import('../../modals/TwoFA/TwoFAModal');
});

const TwoFALoginModal = lazy(() => {
	return import('../../modals/TwoFaLogin/TwoFaLogin');
});

const BonusConfirmModal = lazy(() => {
	return import('../../modals/bonus-confirm/bonus-confirm');
});

const BalloonNotification = lazy(() => {
	return import('../../balloon-notification');
});

const BonusDetailsModal = lazy(() => {
	return import('../../modals/bonus-details/bonus-details');
});


const {
	selectUI,
}= cookieSelectors;
const {
	setUI: setNotificationUI,
} = notificationReducers;

const {
	setUI,
} = gameReducers;
const MainLayout: React.FC<IMainLayoutProps>  = (props) => {
	const { cookieAccept } = useAppSelector(selectUI);
	const { hideAppHeader  } = useAppSelector(settingsUI);
	const { initialSettings, current_language } = useAppSelector((store) => store.Settings);
	const { require_cookie, game_load_type } = initialSettings;

	const onFocused = useRef(false);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { pathname } = location;
	const showHeaderAndFooter	= !hideAppHeader;
	const loadTypeInfinite		= GAME_LOAD_TYPE.INFINITE_SCROLL === game_load_type;
	const showFooter			= !(loadTypeInfinite && (
		pathname.includes('search-result')
			|| pathname === `/${current_language?.code}`
			|| hideAppHeader));


	const onFocus = useCallback((isDeviceMobile: boolean, init = false) => {
		const token = reStoreAuthToken();
		if (isDeviceMobile) {
			dispatch(actions.reconnect(token || ''));
			appSocket.socket.connect();

			if (!init && !onFocused.current) {
				onFocused.current = true;
				// dispatch(profileActions.profileDataReload());
				dispatch(setNotificationUI({ dropDownVisible: false }));
			}
		}
	}, [dispatch]);

	// User has switched away from the tab
	const onBlur = useCallback((isDeviceMobile: boolean) => {
		isDeviceMobile && appSocket.socket.disconnect();
		onFocused.current = false;
	}, []);


	// window message handler
	const onMessage  = useCallback((e: MessageEvent) => {
		const { data } = e;
		const hasExitedGame = isExitEvent(data);
		if (hasExitedGame) {
			dispatch(setUI({ hasExitedGame }));
		}
	}, [dispatch]);


	useEffect(() => {
		const isDeviceMobile: boolean = isMobileDevice();
		window.addEventListener('focus',() => onFocus(isDeviceMobile));
		window.addEventListener('blur', () => onBlur(isDeviceMobile));
		window.addEventListener('message', onMessage);
		// Calls onFocus when the window first loads
		onFocus(isDeviceMobile, true);
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener('focus', () => onFocus(isDeviceMobile));
			window.removeEventListener('blur', () => onBlur(isDeviceMobile));
			window.removeEventListener('message', onMessage);
		};
	}, [onBlur, onFocus, onMessage]);


	return (
		<Suspense fallback={<Loader />}>
			<LoginModal/>
			<DepositeModal/>
			<RegistrationModal/>
			<ForgetModal />
			<ResetModal />
			<InboxInnerModal />
			<DeactivateModal />
			<AvatarUploadModal />
			<DeleteAvatarModal />
			<TimeOutModal />
			<TournamentInnerGamesModal />
			<TournamentInnerTermsModal />
			<InfoModal />
			<VerificationModal/>
			<TwoFAModal/>
			<TwoFALoginModal/>
			<ReactivateModal />
			{showHeaderAndFooter && <Header/>}
			<BonusConfirmModal />
			<BonusDetailsModal />
			{props.children}
			<BottomMenu/>
			{showFooter && <Footer />}
			{!cookieAccept && require_cookie && <CookieModal/>}
			<BalloonNotification/>
			{live_chat_license && <LiveChat />}
		</Suspense>
	);
};


export default MainLayout;
