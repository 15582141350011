import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Desktop } from '../../helpers/devices';
import SidebarUserActions from '../sidebar/sidebar-user-actions';
import { kFormatter } from '../../helpers/utils';
import { currencyList } from '../../config';
import { ROUTE_PATH } from '../../routes/utils';
import { AppNavLink } from '../uiElements/NavLink/AppNavLink/AppNavlink';
import { profileSelectors } from '../../redux/profile/selectors';
import { useAppSelector } from '../../hooks/storeHooks';

import './header-profile.scss';

const {
	selectProfileData,
} = profileSelectors;
const HeaderProfile = () => {
	const profileData = useAppSelector(selectProfileData);
	const { pathname } = useLocation();

	if (!profileData) {
		return false;
	}

	const { first_name, last_name, username, email } = profileData;

	const name = !first_name && !last_name && username ? username :
		!first_name && !last_name && email ? email :
			`${first_name} ${last_name}`;

	return (
		<div className="profile_block">
			<div className="user_block">
				<AppNavLink to={ROUTE_PATH.profile} className="user_info">
					<span className="user_image">
						{
							profileData.avatar ? (
								<img src = {`${profileData.avatar}`} alt = '' title = ''/>
							) : <img src="/resources/images/avatar.jpg" alt="" title=""/>
						}
					</span>
					<span className="name_balance">
						<span className="user_name">{name}</span>
						<span className="user_balance">{currencyList[profileData.currency_code as keyof typeof currencyList] || profileData.currency_code}{kFormatter(String(profileData.balance || ''),profileData.bonus_balance)}</span>
					</span>
				</AppNavLink>
				<AppNavLink className="deposit_btn icon_plus" to={`${ROUTE_PATH.profile}/${ROUTE_PATH.deposit}/`}/>
			</div>
			<SidebarUserActions/>
			<Desktop>
				<AppNavLink
					className={
						`deposit_btn icon_deposit
					    ${pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.profile}/${ROUTE_PATH.deposit}`) ? 'active_nav_link' : ''}`
					}
					to={`${ROUTE_PATH.profile}/${ROUTE_PATH.deposit}/`}
				>
					<span className='btn_text'><FormattedMessage id="deposit"/></span>
				</AppNavLink>
			</Desktop>
			{/*<Desktop> /!* commented in stage  *!/*/}
			{/*	<AppNavLink*/}
			{/*		className={`tournament_btn icon_cup ${pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.tournament}`) ? 'active_nav_link' : ''}`}*/}
			{/*		to={ROUTE_PATH.tournament}>*/}
			{/*		<span className='btn_text'><FormattedMessage id="tournaments"/></span>*/}
			{/*	</AppNavLink>*/}
			{/*</Desktop>*/}
			<Desktop>
				<AppNavLink
					className={`sports_book_btn icon_bet ${pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.sports}`) ? 'active_nav_link' : ''}`}
					to={ROUTE_PATH.sports}>
					<span className='btn_text'><FormattedMessage id="sports"/></span>
				</AppNavLink>
			</Desktop>
		</div>
	);
};

export default HeaderProfile;
