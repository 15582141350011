import { memo } from 'react';
import PageHeader from './page-header';
import './header.scss';
import { useAppSelector } from '../../../hooks/storeHooks';
import { TLayout } from '../../../redux/settings/types';

const Header =  () => {
	const layout: TLayout = useAppSelector((store) =>store.Settings.layout);
	return (
		<>
			{layout !== 'promotion' && layout !== 'game' && <PageHeader/> }
		</>
	);
};

export default memo(Header);
