import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Desktop, NotDesktop } from '../../../helpers/devices';
import { ROUTE_PATH } from '../../../routes/utils';
import { profileSelectors } from '../../../redux/profile/selectors';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { helpReducers } from '../../../redux/help/slice';
import NotificationMobile from '../../notifications/notification-mobile/notification-mobile';
import { menuReducers } from '../../../redux/menu/slice';
import { menuSelectors } from '../../../redux/menu/selectors';
import { TABS } from '../../../redux/menu/types';
import { notificationsSelectors } from '../../../redux/notifications/selectors';
import OutsideClickDetector from '../../../helpers/OutsideClickDetector';
import { CHAT_VIEW_STATUS } from '../../../redux/help/types';
import './bottom-menu.scss';
import { useWidgetIsReady } from '@livechat/widget-react';

const {
	setChat,
} = helpReducers;

const {
	setBottomMenuUI,
} = menuReducers;

const {
	selectAuthToken,
} = profileSelectors;

const {
	selectBottomMenuUI,
} = menuSelectors;

const {
	selectMeta,
} = notificationsSelectors;

const lang = {
	help         : <FormattedMessage id="help"/>,
	profile      : <FormattedMessage id="profile.title"/>,
	deposit      : <FormattedMessage id="deposit.capitalize"/>,
	promotions   : <FormattedMessage id="promotions"/>,
	home         : <FormattedMessage id="home"/>,
	notifications: <FormattedMessage id="notifications"/>,
};

const BottomMenu = () => {
	const dispatch = useAppDispatch();
	const authToken = useAppSelector(selectAuthToken);
	const { notificationVisible, activeTabBottomMenu } = useAppSelector(selectBottomMenuUI);
	const { unread } = useSelector(selectMeta);
	const isWidgetReady = useWidgetIsReady();
	const layout = useAppSelector(({ Settings }) => Settings.layout);

	const handleChatOpen = () => {
		dispatch(setChat(CHAT_VIEW_STATUS.OPEN));
	};

	const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
		const target = e.target as HTMLAnchorElement;
		document.body.style.overflow = '';
		dispatch(setBottomMenuUI({ notificationVisible: false, activeTabBottomMenu: target.id as TABS }));
	};

	const handleOpenNotification = () => {
		dispatch(setBottomMenuUI({ notificationVisible: !notificationVisible, activeTabBottomMenu: TABS.NOTIFICATION }));
	};

	const handleCloseNotification = () => {
		document.body.style.overflow = '';
		dispatch(setBottomMenuUI({ notificationVisible: false, activeTabBottomMenu: TABS.NONE }));
	};

	if (layout === 'promotion') {
		return false;
	}

	return (
		<>
			{
				authToken ?
					<>
						{
							layout === 'game' ?
								<button
									className="help_btn icon_help"
									onClick={handleChatOpen}>
									{lang.help}
								</button> :
								<>
									<Desktop>
										<button
											className="help_btn icon_help"
											onClick={handleChatOpen}
										>
											{lang.help}
											{/* <NavLink className="help_btn icon_help" to="/help"><FormattedMessage id="help" /></NavLink> */}
										</button>
									</Desktop>
									<NotDesktop>
										<div className="bottom_menu">
											<ul className="menu_list">
												<li>
													<Link
														onClick={handleClick}
														id={TABS.HOME}
														to={`${ROUTE_PATH.root}`}
														className={`${activeTabBottomMenu === TABS.HOME ? 'active' : ''} icon_home`}
													>
														{lang.home}
													</Link>
												</li>
												<li>
													<Link
														onClick={handleClick}
														id={TABS.PROMOTION}
														to={`${ROUTE_PATH.promotions}/`}
														className={`${activeTabBottomMenu === TABS.PROMOTION ? 'active' : ''} icon_gift`}
													>
														{lang.promotions}
													</Link>
												</li>
												<li>
													<OutsideClickDetector onOutsideClick={handleCloseNotification}>
														<NotificationMobile visible={notificationVisible}/>
														<button
															id={TABS.NOTIFICATION}
															className={`${activeTabBottomMenu === TABS.NOTIFICATION ? 'active' : ''} icon_notification`}
															onClick={handleOpenNotification}>
															{lang.notifications}
														</button>
														<span className={`${unread ? 'new_income' : ''}`}></span>
													</OutsideClickDetector>
												</li>
												<li>
													<Link
														onClick={handleClick}
														id={TABS.DEPOSIT}
														to={`${ROUTE_PATH.profile}/${ROUTE_PATH.deposit}/`}
														className={`${activeTabBottomMenu === TABS.DEPOSIT ? 'active' : ''} icon_deposit`}
													>
														{lang.deposit}
													</Link>
												</li>
												<li>
													<Link
														onClick={handleClick}
														id={TABS.PROFILE}
														to={ROUTE_PATH.profile}
														className={`${activeTabBottomMenu === TABS.PROFILE ? 'active' : ''} icon_profile`}
													>
														{lang.profile}
													</Link>
												</li>
											</ul>
										</div>
									</NotDesktop>
								</>
						}
					</> :
					<button className="help_btn icon_help" onClick={handleChatOpen} disabled={!isWidgetReady}>
						{lang.help}
					</button>
			}

		</>
	);
};

export default BottomMenu;
