import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { profileSelectors } from '../../../../redux/profile/selectors';
import { BANNER_GROUPS } from '../../../../redux/banners/types';
import { bannerActions } from '../../../../redux/banners/actions';
import { bannersSelectors } from '../../../../redux/banners/selectors';


const lang = {
	availableApps: <FormattedMessage id='available_apps'/>,
	infoBlock    : <FormattedMessage id='info_block'/>,
};

const {
	selectAuthToken,
} = profileSelectors;
const {
	selectFooterCompanies,
	selectFooterApps,
} = bannersSelectors;
const FooterApps: React.FC = ()=>{
	const footerApps = useAppSelector(selectFooterApps);
	const footerCompanies = useAppSelector(selectFooterCompanies);
	const authToken  = useAppSelector(selectAuthToken);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(bannerActions.getBanners(BANNER_GROUPS.footerCompanies));
		dispatch(bannerActions.getBanners(BANNER_GROUPS.footerApps));
	}, [dispatch, authToken]);

	return (
		<div className="apps_verify">
			<div className="apps_block">
				{
					footerApps.map((item,index)=>{
						return (
							<div key={index}>
								<a href={item.link_text} target='_blank' rel="noopener noreferrer">
									<img src={item.image_url} title={item.title} alt={item.name} />
								</a>
							</div>
						);})
				}

				<div className="available_apps"> {lang.availableApps} </div>
			</div>
			<div className="checked_organizations">
				{
					footerCompanies.map((item,index)=>{
						if (item.link) {
							return (
								<a key={index} href={item.link} target='_blank' rel="noopener noreferrer">
									<img src={item.image_url} title={item.title} alt={item.name} key={index} />
								</a>
							);
						}
						return (
							<img src={item.image_url} title={item.title} alt={item.name} key={index} />
						);
					})
				}
				<div className="info_block">
					{lang.infoBlock}
				</div>
			</div>
		</div>
	);
};
export default FooterApps;
