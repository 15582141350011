import { NotDesktop } from '../../../helpers/devices';
import React from 'react';
// import LangSwitcher from '../../lang-switcher'; // commented in prod
import { useAppDispatch } from '../../../hooks/storeHooks';
import { helpReducers } from '../../../redux/help/slice';
import './sidebar-footer.scss';
import { CHAT_VIEW_STATUS } from '../../../redux/help/types';

const {
	setChat,
} = helpReducers;

const SidebarFooter: React.FC = () => {
	const dispatch = useAppDispatch();

	const handleChatOpen = () => {
		dispatch(setChat(CHAT_VIEW_STATUS.OPEN));
	};

	return (
		<div className="sidebar_footer">
			<NotDesktop>
				{/*commented in prod*/}
				{/*<LangSwitcher/>*/}
				{/*<div className="lg_block"></div>*/}
				<div>
					<button
						className="help_btn icon_help"
						onClick={handleChatOpen}
					>
						{/* <NavLink className="help_btn icon_help" to="/help"></NavLink> */}
					</button>
				</div>
			</NotDesktop>
		</div>
	);
};

export default SidebarFooter;
