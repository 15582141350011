import moreGamesIcon from '../../assets/icons/MoreGame.svg';
import { defaultDate } from '../../pages/tournament/components/helpers/utils';

export const moreGamesBtn = {
	id          : 0,
	game_id     : 'moreGames',
	name        : 'More Games',
	image_sm_url: moreGamesIcon,
	alias       : '',
	description : '',

};

export const createTournamnet = (tournamnet = {}, timePeriod, tournamentPrizes, prizesimages, qualifiedConditions) => ({
	id                : tournamnet.id,
	title             : tournamnet.title,
	subTitle          : tournamnet.subtitle,
	description       : tournamnet.description,
	bannerUrl         : tournamnet.logo_url,
	termsAndConditions: tournamnet.terms_and_conditions,
	timePeriod,
	tournamentPrizes,
	qualifiedConditions,
	prizesimages,
});

export const createQualifiedCounditions = (qualifiedCounditions = {}) => ({
	requiredConditions: {
		...qualifiedCounditions,
		required_spins      : qualifiedCounditions.min_spins,
		required_stake_count: qualifiedCounditions.min_stake_count,
		required_spin_stake : qualifiedCounditions.min_spin_stake,
		required_deposit    : qualifiedCounditions.min_deposit,
		required_balance    : qualifiedCounditions.min_balance,
		eachStakeMinAmount  : qualifiedCounditions.each_stake_min_amount,
		currencyID          : qualifiedCounditions.currency_id,
	},
	filledConditions: {},
});

export const createFilledConditions = (filledConditions) => {
	if (filledConditions) {
		return {
			required_spins      : filledConditions.min_spins,
			required_stake_count: filledConditions.min_stake_count,
			required_spin_stake : filledConditions.min_spin_stake,
			required_deposit    : filledConditions.min_deposit,
			required_balance    : filledConditions.min_balance,
		};
	}
	return {
		required_spins      : null,
		required_stake_count: null,
		required_spin_stake : null,
		required_deposit    : null,
		required_balance    : null,
	};
};
export const createPlayerInfo = (playerInfo, name, prizesimages) => {
	if (playerInfo) {
		const { place, points } = playerInfo;
		return {
			name,
			place,
			points,
			logo            : prizesimages[place - 1],
			user_masked_name: playerInfo.user_masked_name,
		};
	}
};
export const createPrizes = (prizes = []) => {
	return prizes.reduce((acc, item) => {
		if (!item) {
			return acc;
		}
		const { bonus, amount, freespin, freebet, logo_url: logo, from_place : fromPlace, to_place: toPlace, currency_id: currencyID, title } = item;
		const bonusMoney    = bonus && !!bonus.wagering_turnover;
		const numerousItems = toPlace - fromPlace;
		const key           = fromPlace - 1;
		const prize = {
			freespin: freespin ?  freespin.spins_total : null,
			freebet : freebet ?  freebet.amount : null,
			amount,
			bonusMoney,
			logo,
			fromPlace,
			toPlace,
			currencyID,
			title,
		};
		acc.tournamentPrizes.push(prize);
		if (logo && !numerousItems) {
			acc.prizesimages[key] = logo;
		} else if (logo) {
			for (let i = key; i < toPlace; i++) {
				acc.prizesimages[i] = logo;
			}
		}
		return acc;
	}, {
		tournamentPrizes: [],
		prizesimages    : {},
	});
};

const getToTime = (date) => {
	return new Date(date).getTime();
};
export const sortTournaments = (tournaments) => {
	tournaments.active = tournaments.active.sort((a, b) => getToTime(b.start) - getToTime(a.start));
	tournaments.upcoming = tournaments.upcoming.sort((a, b) => getToTime(a.start) - getToTime(b.start));
	tournaments.recently = tournaments.recently.sort((a, b) => getToTime(a.start) - getToTime(b.start));
	return tournaments;
};

export const createTournamentLists = (tournamentList = []) => {
	return tournamentList.reduce((acc, item) => {
		if (!item.start && !item.end) {
			return acc;
		}

		const startDate = new Date(item.start).getTime();
		const endDate = new Date(item.end).getTime();

		if (startDate > defaultDate) {
			acc.upcoming.push(item);
		} else if (endDate < defaultDate) {
			acc.recently.push(item);
		} else if (startDate < defaultDate && endDate > defaultDate) {
			acc.active.push(item);
		}

		return sortTournaments(acc);
	}, {
		upcoming: [],
		active  : [],
		recently: [],
	});
};

export const getMainTournament = (tournaments) => {
	const mainInActives = tournaments.active.find(item => item.main);
	if (!mainInActives) {
		return tournaments.upcoming.find(item => item.main) || {};
	}
	return mainInActives || {};
};
