import React from 'react';

const ProfilePreloader: React.FC = () => {
	return (
		<ul className="profile_preloader">
			<li></li>
			<li></li>
			<li></li>
		</ul>
	);
};

export default ProfilePreloader;