import { ReactElement, useEffect } from 'react';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';
import { HelmetProvider } from 'react-helmet-async';

import de from 'date-fns/locale/de';
import ru from 'date-fns/locale/ru';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

import { IntlProvider } from 'react-intl';
import SimpleReactValidator from 'simple-react-validator';
import InternetConnection
	from '../../hoc/InternetConnection/InternetConnection';
import * as serviceWorker from '../../serviceWorker';
import { diffDays, widthScreen } from '../../helpers/utils';

import 'moment/locale/ru';
import 'moment/locale/de';
import 'moment/locale/fr';

import Routes from '../../routes';
import translations from '../../translations';
import profileActions from '../../redux/profile/actions';
import settingsAction from '../../redux/settings/actions';
import { settingsReducers as settingsReducers } from '../../redux/settings/slice';
import notificationsActions from '../../redux/notifications/actions';

import PwaComponent from '../pwa';
import pwaHandler from '../../pwa';

import validator_locales from '../../translations/validator-locales';
import Loader from '../controls/loader';
import HelmetLayout from '../layout/helmet-layout';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { settingsUI } from '../../redux/settings/selectors';
import { defaultLanguage } from '../../config';
import { defaultLanguages, getDeviceType } from '../../redux/settings/utils';
import {
	clearData,
	reStoreAuthToken,
	restoreDenyDate,
	restoreLocale,
	storeLocale,
} from '../../helpers/localStorageUtils';
import { profileSelectors } from '../../redux/profile/selectors';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ILanguage } from '../../redux/types';
import { ELanguageCodes } from '../../translations/types';
import { twoFASelectors } from '../../redux/2FA/selectors';
import { gamesSelectors } from '../../redux/games/games_list/selectors';
import './app.scss';


const locales = {
	'en': en,
	'de': de,
	'ru': ru,
	'fr': fr,
};

const {
	selectPendingLogout,
	selectAuthToken,
	selectUserLang,
} = profileSelectors;
const {
	selectSelectedProviders,
} = gamesSelectors;
const {
	setLanguage,
} = settingsReducers;
const {
	getCurrenciesAction,
	getInitialSettingsAction,
	getCountriesAction,
	getScriptsAction,
} = settingsAction;
const {
	getNotificationsCountAction,
} = notificationsActions;
const {
	profileDataReload,
} = profileActions;
const {
	selectLogin,
} = twoFASelectors;

function App(): ReactElement {

	const authToken = useAppSelector(selectAuthToken);
	const userLang = useAppSelector(selectUserLang);
	const pendingLogout = useAppSelector(selectPendingLogout);
	const checkedList = useAppSelector(selectSelectedProviders);
	const { enable_2fa_app, enable_2fa_sms } = useAppSelector(selectLogin);
	const isLoggedIn = reStoreAuthToken();
	const { appReady, loading, appStarted  } = useAppSelector(settingsUI);
	const languages = useAppSelector(store =>  store.Settings.languages);
	const current_language = useAppSelector(store =>  store.Settings.current_language);
	const websiteLang = useAppSelector(store =>  store.Settings.initialSettings.lang_id);
	const channelID = getDeviceType();
	const dispatch = useAppDispatch();

	const twoFAEnabled = enable_2fa_app || enable_2fa_sms;

	useEffect(() => {
		dispatch(settingsReducers.setChannel(channelID));
	}, [channelID, dispatch]);

	useEffect(() => {
		if (!twoFAEnabled && isLoggedIn && isLoggedIn !== 'undefined' && !authToken && !pendingLogout) {
			dispatch(profileDataReload());
		}
	}, [isLoggedIn, authToken, pendingLogout, dispatch, twoFAEnabled]);

	useEffect(() => {
		if (!appReady || !websiteLang) {
			return;
		}

		setLocaleSettings(current_language as ILanguage);

		const locale = restoreLocale();

		if (locale) {
			return;
		}
		const requestLocale = defaultLanguages.find((elm) => elm.id === websiteLang);
		if (requestLocale) {
			dispatch(setLanguage(requestLocale));
		} else {
			dispatch(setLanguage(defaultLanguage));
		}
	}, [languages, userLang, isLoggedIn, websiteLang, current_language, appReady, dispatch]);

	useEffect(() => {
		serviceWorker.register();
		const denyDate = restoreDenyDate();
		if (denyDate) {
			if (diffDays(new Date(), new Date(denyDate)) >= 30) {
				clearData(STORAGE_KEYS.DENY_DATE);
				pwaHandler();
			}
		} else {
			pwaHandler();
		}

		widthScreen();
		window.addEventListener('resize', widthScreen);

		return () => window.removeEventListener('resize', widthScreen);
	}, []);

	useEffect(() => {
		const onPop = () => {
			const path = window.location.pathname.split('/');
			const locale = path[1];
			dispatch(getInitialSettingsAction({ langID: (current_language?.id || defaultLanguage.id), locale: locale, withNavigate: checkedList.length > 0 }));
		};
		window.addEventListener('popstate', onPop);

		return () => window.removeEventListener('popstate', onPop);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const path = window.location.pathname.split('/');
		const locale = path[1];
		dispatch(getInitialSettingsAction({ langID: (current_language?.id || defaultLanguage.id), locale: locale, withNavigate: checkedList.length > 0 }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (appReady) {
			dispatch(getCurrenciesAction());
			dispatch(getScriptsAction());
			dispatch(getCountriesAction());
		}
	}, [appReady, dispatch]);

	useEffect(() => {
		if (current_language && authToken) {
			dispatch(getNotificationsCountAction());
		}
	}, [current_language, authToken, dispatch]);

	function setLocaleSettings(currentLanguage: ILanguage) {
		moment.locale(currentLanguage.code);
		// @ts-expect-error SimpleReactValidator does not have a type definition
		SimpleReactValidator.addLocale(currentLanguage.code, validator_locales[currentLanguage.code]);
		// @ts-expect-error SimpleReactValidator does not have a type definition
		registerLocale('locale', locales[currentLanguage.code]);
		storeLocale(currentLanguage);
	}

	if (!twoFAEnabled && isLoggedIn && isLoggedIn !== 'undefined' && !userLang) {
		return <Loader/>;
	}
	if (!twoFAEnabled && !current_language || loading || !appStarted) {
		return <Loader/>;
	}

	const langCode = current_language?.code as ELanguageCodes;
	return (
		<IntlProvider locale={current_language?.code || ''} key={current_language?.code}
			messages={translations[langCode]}>
			<PwaComponent/>
			<HelmetProvider>
				<HelmetLayout>
					<Routes/>
				</HelmetLayout>
			</HelmetProvider>
		</IntlProvider>

	);
}


export default InternetConnection(App);
