import { RootState } from '../store';
import { BANNER_GROUPS } from './types';

export const bannersSelectors = {
	selectLumos          : (state: RootState) => state.Banners[BANNER_GROUPS.lumos],
	selectFooterApps     : (state: RootState) => state.Banners[BANNER_GROUPS.footerApps],
	selectFooterCompanies: (state: RootState) => state.Banners[BANNER_GROUPS.footerCompanies],
	selectFooterPayments : (state: RootState) => state.Banners[BANNER_GROUPS.footerPayments],
	selectFooterProviders: (state: RootState) => state.Banners[BANNER_GROUPS.footerProviders],
	selectFooterSocials  : (state: RootState) => state.Banners[BANNER_GROUPS.footerSocial],
	selectSidebarBlog    : (state: RootState) => state.Banners[BANNER_GROUPS.sidebarBlog],
	
};