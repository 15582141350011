import { all } from 'redux-saga/effects';
import settingsSaga from './settings/saga';
import profileSaga from './profile/saga';
import bonusSaga from './bonus/saga';
import { gamesSaga } from './games/sagas';
import historySaga from './history/saga';
import menuSaga from './menu/saga';
import balanceSaga from './balance/saga';
import depositSaga from './deposit/saga';
import withdrawSaga from './withdraw/saga';
import promotionSaga from './promotion/saga';
import notificationsSaga from './notifications/saga';
import helpSaga from './help/saga';
import socketSaga from './socket/saga';
import gamblingSaga from './gambling/saga';
import cookiesSaga from './cookie/saga';
import tournamentsSaga from './tournaments/sagas';
import externalSocketSagas from '../sockets/sagas';
import sportsBookSaga from './sports-book/saga';
import bannersRootSaga from './banners/saga';
import twoFASaga from './2FA/saga';
import winnersSaga from './winners/saga';


export default function* rootSaga(){
	yield all([
		settingsSaga(),
		profileSaga(),
		bonusSaga(),
		gamesSaga(),
		historySaga(),
		menuSaga(),
		balanceSaga(),
		depositSaga(),
		withdrawSaga(),
		gamblingSaga(),
		promotionSaga(),
		notificationsSaga(),
		helpSaga(),
		socketSaga(),
		externalSocketSagas(),
		cookiesSaga(),
		tournamentsSaga(),
		sportsBookSaga(),
		twoFASaga(),
		bannersRootSaga(),
		winnersSaga(),
	]);
}
