import { ICurrency } from '../settings/types';
import { PaymentFieldNames } from '../../components/withdraw/components/PaymentMethodFields/types';
import { ErrorTypes } from './utils';
import {
	IProfileData,
	IProfileDoc,
	IProfileDocBinaryStatus,
	ITronAccountEntities,
	ITronlinkParams, ITronlinkSignParams, ITronlinkSignTransaction,
} from '../profile/types';
import { ILanguage } from '../types';

export interface IAmount {
    value: string;
    error: string;
}

export interface ITempTronAccount {
    id        : number;
    account   : string;
    name      : string;
    saveMethod: boolean;
    open      : boolean;
    temp      : boolean;
    amount    : IAmount;
    loading   : boolean;
}

export interface IUI {
    loading        : boolean;
    markAllRead    : boolean;
    listLoading    : boolean;
    favouriteAmount: number | null;
}

export interface IPaymentMethod {
    id                          : number;
    logo_url                    : string;
    payment_id                  : number;
    website_id                  : number;
    withdrawal_allowed          : boolean;
    deposit_allowed             : boolean;
    name                        : string;
    description                 : string;
    available_deposit_allowed   : boolean;
    available_withdrawal_allowed: boolean;
    max_deposit                 : number;
    min_deposit                 : number;
    order_id                    : number;
    favorite_amounts            : number[];
}

export enum PaymentTypes {
    wallet = 'wallet',
    phone = 'phone',
    card = 'card',
}

// type TPaymentData = {
//     value: string;
//     error: string;
// };
// type TErrorData = {
//     field: string;
//     value: string;
// };

export enum PaymentFields {
    amount = 'amount',
    phone = 'phone',
    upi_number = 'upi_number',
    service_id = 'service_id',
    email = 'email'
}

export interface IDepositBaseData {
    amount      : string ;
    phone       : string ;
    upi_number  : string;
    service_id  : string;
    payment_type: PaymentTypes;
    number      : string; // card number
    [K: string] : string; // other fields
}

export type IBaseError = {
    [key in PaymentFields]: string;
};


export interface IDepositMethods {
    currency?    : ICurrency;
    payments?    : IPaymentMethod[];
    [key: string]: unknown;
}
export interface IState {
    depositMethods: IDepositMethods;
    payment_method: IPaymentMethod | null;
    baseData      : Partial<IDepositBaseData>;
    baseError     : IBaseError;
    success: {
        showSuccess: boolean;
        successMsg : string;
    };
    tempTronAccount: ITempTronAccount;
    tronAccounts   : ITronAccountEntities;
    UI             : IUI;
}

export interface IDepositStoreData {
    languages              : ILanguage[];
    profileData            : IProfileData;
    profileDocBinaryStatus : IProfileDocBinaryStatus;
    profileDocs            : IProfileDoc[];
    Login                  : unknown;
    Register               : unknown;
    sid                    : string;
    baseData               : Partial<IDepositBaseData>;
    paymentMethod          : IPaymentMethod | null;
    tronLoginDeepLink      : ITronlinkParams;
    tronSignDeepLink       : ITronlinkSignParams;
    tronTransactionDeepLink: ITronlinkSignTransaction;
    API_URL                : string;
    tronAccounts           : ITronAccountEntities;
}

export interface IGetPaymentsPayload {
    current?: string;

}

export interface IDoDepositTronPayload {
    tronAccount: ITempTronAccount;
    params: {
            amount : string;
            account: {
                account: string;
                name   : string;
            };
        params: {
            [key: string]: string;
        };
    };
    method: IPaymentMethod;
}


export interface IDepositResponse {
    trx_data: string;
}
export interface ITronMobileDepositResponse {
    trx_data: {
        raw_data: {
            data: string;
        };
    };
}

export interface ISendSignedTronTransactionPayload {
    signedTransaction: string;
    method           : IPaymentMethod;

}

export interface ISendSignedMessageResponse {
}

export interface IAuthorizeTronResponse {
    action_id: string;
}

export interface IPaymentMethodDeleteResponse {

}

export interface IDoDepositArgs {
    method: IPaymentMethod;
    params: Partial<IDepositBaseData>;

}

export type TErrorTuple = [PaymentFieldNames, ErrorTypes];
