import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { gameReducers } from '../../../redux/games/games_list/slice';
import { useAppDispatch } from '../../../hooks/storeHooks';
import { IListingProps } from './types';

import './listing.scss';

const {
	setPageNumber,
} = gameReducers;
const Listing: React.FC<IListingProps> = (props) => {

	const dispatch = useAppDispatch();
	const location = useLocation();
	const { data, button, langCode } = props;

	const pathname =  location.pathname.split('/');

	const receiveGames =()=>{
		dispatch(setPageNumber(1));
	};

	const ShowButton = button ? <button  className="play_btn"><FormattedMessage id={button} /></button> : null;

	const Result = data.map((item, idx) => {
		const label = item.name ? item.name : <FormattedMessage id={item.alias} />;
		const icon = item.icon ? `icon_${item.icon}` : '';
		return (
			<li key={`list-item-${idx}`}>
				{item.thumb_url ? <img src={`${item.thumb_url}`} alt="" title="" /> : null}
				
				{
					item.url 
						? (
							<Link
								onClick={()=>{receiveGames();}}
								className={`menu_item ${icon} ${pathname[2] === item.alias? 'current' : '' }  `}
								to={`/${langCode}${item.url}`}>
								{label}
							</Link>) 
						: <div className={`menu_item ${icon} `}>{label}</div>
				}

				{ShowButton}
			</li>
		);
	});


	return (
		<ul>
			{Result}
		</ul>

	);
};

export default Listing;
