import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { CHANNELS, defaultLanguages } from './utils';
import {
	ICountry,
	ICurrency,
	IInitSettings,
	ISEO,
	ISettings,
	ISettingsUI,
	ScriptItem,
	TLayout,
} from './types';
import { detectMediaSize, fill } from '../../helpers/utils';
import { ILanguage } from '../types';
import { restoreLocale, storeLocale } from '../../helpers/localStorageUtils';

const initialState: IInitSettings={
	countries       : [],
	slider          : [],
	currencies      : [],
	current_language: restoreLocale(),
	layout          : '',
	loader          : {
		data: {
			group_name        : '',
			request_from_front: false,
			website_id        : 0,
			channel_id        : CHANNELS.desktop,
		}, section: '',
	},
	scripts: [],
	state  : {
		data: {
			group_name        : '',
			request_from_front: false,
			website_id        : 0,
			channel_id        : CHANNELS.desktop,
		}, section: '',
	},
	languages: defaultLanguages,
	SEO      : {
		pageName      : null,
		seoTitle      : null,
		seoDescription: null,
		seoKeywords   : null,
		ogTitle       : null,
		ogDescription : null,
		ogImageURL    : null,
	},
	appMediaSize: detectMediaSize(),
	UI          : {
		loading      : false,
		appStarted   : false,
		appReady     : false,
		hideAppHeader: false,
	},
	cookieScripts   : [],
	initialSettings : {},
	selectedCategory: 0,
	selectedSubIDs 	: [],
	channelID       : CHANNELS.backend,
};

const settingsSlice = createSlice({
	name    : 'settings',
	initialState,
	reducers: {
		setScripts: (state: Draft<IInitSettings>, action: PayloadAction<ScriptItem[]>) => {
			state.scripts = action.payload;
		},
		setCookieScripts: (state: Draft<IInitSettings>, action: PayloadAction<ScriptItem[]>) => {
			state.cookieScripts = action.payload;
		},
		setLanguage: (state: Draft<IInitSettings>, action: PayloadAction<ILanguage>) => {
			storeLocale(action.payload);
			state.current_language = action.payload;
		},
		setCurrencies: (state: Draft<IInitSettings>, action: PayloadAction<ICurrency[]>) => {
			state.currencies = action.payload;
		},
		layoutUpdate: (state: Draft<IInitSettings>, action: PayloadAction<TLayout>) => { // Check layout update
			state.layout = action.payload;
		},
		setInitialSettings: (state: Draft<IInitSettings>, action: PayloadAction<ISettings>) => {
			state.initialSettings = action.payload;
		},
		setCountries: (state: Draft<IInitSettings>, action: PayloadAction<ICountry[]>) => {
			state.countries = action.payload;
		},
		setSEOData: (state: Draft<IInitSettings>, action: PayloadAction<ISEO>) => {
			state.SEO = action.payload;
		},
		setSelectedCategory: (state: Draft<IInitSettings>, action: PayloadAction<number>) => {
			state.selectedCategory = action.payload;
		},
		addSelectedSubIDs: (state: Draft<IInitSettings>, action: PayloadAction<Array<number>>) => {
			state.selectedSubIDs = action.payload;
		},
		toggleSelectedIDs: (state: Draft<IInitSettings>, action: PayloadAction<number>) => {
			const subID = action.payload;
			const data = Array.from(state.selectedSubIDs);
			if (data.includes(subID)) {
				state.selectedSubIDs = data.filter(item => item !== subID);
			} else {
				data.push(action.payload);
				state.selectedSubIDs = data;
			}

		},
		removeSelectedSubIDs: (state: Draft<IInitSettings>, action: PayloadAction<number>) => {
			state.selectedSubIDs = Array.from(state.selectedSubIDs).filter(item => item !== action.payload);
		},

		setSettingsUI: (state: Draft<IInitSettings>, action: PayloadAction<Partial<ISettingsUI>>) => {
			const target = state.UI;
			const result = fill(action.payload, target, true);
			state.UI = result;
		},
		setChannel: (state: Draft<IInitSettings>, action: PayloadAction<CHANNELS>) => {
			state.channelID = action.payload;
		},
		resetSEOData: (state: Draft<IInitSettings>) => {
			const target = initialState.SEO;
			state.SEO = target;
		},
	},
});


export const settingsReducers = {
	...settingsSlice.actions,
};


export { settingsSlice };
export default settingsSlice.reducer;

