import { FormattedMessage } from 'react-intl';

export enum DOCUMENT_RESPONSE  {
	success = 2,
	decline = 3,
}

export const documentResponseTitle = (id: DOCUMENT_RESPONSE) => {
	switch (id) {
		case DOCUMENT_RESPONSE.success: {
			return <FormattedMessage id={'document.upload.approved.title'}/>;
		}
		case DOCUMENT_RESPONSE.decline: {
			return <FormattedMessage id={'document.upload.declined.title'}/>;
		}
		default: {
			return <FormattedMessage id={'no.data'} />;
		}
	}
};

export const documentResponseBody = (id: DOCUMENT_RESPONSE) => {
	switch (id) {
		case DOCUMENT_RESPONSE.success: {
			return <FormattedMessage id={'document.upload.approved.body'}/>;
		}
		case DOCUMENT_RESPONSE.decline: {
			return <FormattedMessage id={'document.upload.declined.body'}/>;
		}
		default: {
			return <FormattedMessage id={'no.data'} />;
		}
	}
};
