import React from 'react';

const GamesListPreloader: React.FC = () => {
	return (
		<>
			<div className="list_head">
				<span className="animate_block"></span>
			</div>
			<ul className="games_preloader">
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
				<li><span></span></li>
			</ul>
		</>
	);
};

export default GamesListPreloader;