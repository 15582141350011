import { TimeZoneCityToCountry } from '../components/controls/phoneMasked/types';
import { API_URL, REACT_APP_WEBSITE_ID } from '../config';

export enum RGPeriod {
	_24H   = '24h',
	_48H   = '48h',
	_7D    = '7d',
	_30D   = '30d',
	_3M    = '3m',
	_6M    = '6m',
	_1Y    = '1y',
	CUSTOM = 'custom',
}

export enum RGType {
	TIME_OUT = 1,
	AVAILABLE_TIME = 2,
	SELF_EXCLUSION = 3,
	DEPOSIT_LIMIT = 4
}

export enum GamblingOptions {
	_24H   = '24 hours',
	_48H   = '48 hours',
	_7D    = '7 days',
	_30D   = '30 days',
	_3M    = '3 month',
	_6M    = '6 month',
	_1Y    = '1 year',
	CUSTOM = 'Custom',
}

export const timeOutOptions = [
	{ value: 1, label: GamblingOptions._24H },
	{ value: 2, label: GamblingOptions._48H },
	{ value: 7, label: GamblingOptions._7D },
	{ value: 30, label: GamblingOptions._30D },
	{ value: RGPeriod.CUSTOM, label: GamblingOptions.CUSTOM },
];

export const selfExclusionPeriodOptions = [
	{ value: 3, label: GamblingOptions._3M },
	{ value: 6, label: GamblingOptions._6M },
	{ value: 12, label: GamblingOptions._1Y },
];

export const GamblingTypes = {
	TIME_OUT      : 'TIME_OUT',
	AVAILABLE_TIME: 'AVAILABLE_TIME',
	SELF_EXCLUSION: 'SELF_EXCLUSION',
	DEPOSIT_LIMITS: 'DEPOSIT_LIMITS',
};

export const availableTimePeriodOptions = {
	perDay  : { min: 1, max: 23 },
	perWeek : { min: 1, max: 69 },
	perMonth: { min: 1, max: 719 },
};

export const TRONLINK_DEEP_LINK_URL = 'tronlinkoutside://pull.activity?param=';
export const TRONLINK_WEBSITE = 'https://www.tronlink.org/';
export const TRONLINK_APPSTORE_ID = 'itms-apps://apps.apple.com/id/app/tronlink/id1453530188';
export const TRONLINK_PLAY_MARKET_ID = 'market://details?id=com.tronlinkpro.wallet';
export const TRONLINK_CALLBACK_URL = `${API_URL}/v1/profile/deactivate/tronlink/callback?website_id=${REACT_APP_WEBSITE_ID}`;
export const gamblingOptionsToRGPeriodMap: Record<GamblingOptions, RGPeriod> = {
	[GamblingOptions._24H]  : RGPeriod._24H,
	[GamblingOptions._48H]  : RGPeriod._48H,
	[GamblingOptions._7D]   : RGPeriod._7D,
	[GamblingOptions._30D]  : RGPeriod._30D,
	[GamblingOptions._3M]   : RGPeriod._3M,
	[GamblingOptions._6M]   : RGPeriod._6M,
	[GamblingOptions._1Y]   : RGPeriod._1Y,
	[GamblingOptions.CUSTOM]: RGPeriod.CUSTOM,
};

export const RGErrorKey = 'RESPONSIBLE_GAMBLING_TIME_OUT_LIMIT';
export const RGDateFormatWithTimeZone = 'dddd DD MMMM YYYY [at] HH:mm [GMT]Z';
export const RGDateFormat = 'dddd DD MMMM YYYY HH:mm';
export const RGPayloadDateFormat = 'YYYY-MM-DD HH:mm';

export enum STORAGE_KEYS {
	LOCALE = 'locale',
	THEME = 'theme',
	AUTH_TOKEN = 'authToken',
	DENY_DATE = 'denyDate',
	REACTIVE_TOKEN = 'reactiveToken',
	SOCKET_AUTH_TOKEN = 'socketAuthToken',
	TRON_TRANSACTION_LS_KEY = 'TronTrxID',
	WAIT_PAYMENT = 'wait_payment',
	REFERER = 'referer',
	OS = 'os',
	COUNT_DOWN = 'countdownStartTime'
}

export enum WAIT_PAYMENT_STATUS {
	DEPOSIT = 'deposit',
	WITHDRAW = 'withdraw',
}

export enum CountryCode {
	AC = 'AC',
	AD = 'AD',
	AE = 'AE',
	AF = 'AF',
	AG = 'AG',
	AI = 'AI',
	AL = 'AL',
	AM = 'AM',
	AO = 'AO',
	AQ = 'AQ',
	AR = 'AR',
	AS = 'AS',
	AT = 'AT',
	AU = 'AU',
	AW = 'AW',
	AX = 'AX',
	AZ = 'AZ',
	BA = 'BA',
	BB = 'BB',
	BD = 'BD',
	BE = 'BE',
	BF = 'BF',
	BG = 'BG',
	BH = 'BH',
	BI = 'BI',
	BJ = 'BJ',
	BL = 'BL',
	BM = 'BM',
	BN = 'BN',
	BO = 'BO',
	BQ = 'BQ',
	BR = 'BR',
	BS = 'BS',
	BT = 'BT',
	BV = 'BV',
	BW = 'BW',
	BY = 'BY',
	BZ = 'BZ',
	CA = 'CA',
	CC = 'CC',
	CD = 'CD',
	CF = 'CF',
	CG = 'CG',
	CH = 'CH',
	CI = 'CI',
	CK = 'CK',
	CL = 'CL',
	CM = 'CM',
	CN = 'CN',
	CO = 'CO',
	CR = 'CR',
	CU = 'CU',
	CV = 'CV',
	CW = 'CW',
	CX = 'CX',
	CY = 'CY',
	CZ = 'CZ',
	DE = 'DE',
	DG = 'DG',
	DJ = 'DJ',
	DK = 'DK',
	DM = 'DM',
	DO = 'DO',
	DZ = 'DZ',
	EA = 'EA',
	EC = 'EC',
	EE = 'EE',
	EG = 'EG',
	EH = 'EH',
	ER = 'ER',
	ES = 'ES',
	ET = 'ET',
	EU = 'EU',
	FI = 'FI',
	FJ = 'FJ',
	FK = 'FK',
	FM = 'FM',
	FO = 'FO',
	FR = 'FR',
	GA = 'GA',
	GB = 'GB',
	GD = 'GD',
	GE = 'GE',
	GF = 'GF',
	GG = 'GG',
	GH = 'GH',
	GI = 'GI',
	GL = 'GL',
	GM = 'GM',
	GN = 'GN',
	GP = 'GP',
	GQ = 'GQ',
	GR = 'GR',
	GS = 'GS',
	GT = 'GT',
	GU = 'GU',
	GW = 'GW',
	GY = 'GY',
	HK = 'HK',
	HM = 'HM',
	HN = 'HN',
	HR = 'HR',
	HT = 'HT',
	HU = 'HU',
	IC = 'IC',
	ID = 'ID',
	IE = 'IE',
	IL = 'IL',
	IM = 'IM',
	IN = 'IN',
	IO = 'IO',
	IQ = 'IQ',
	IR = 'IR',
	IS = 'IS',
	IT = 'IT',
	JE = 'JE',
	JM = 'JM',
	JO = 'JO',
	JP = 'JP',
	KE = 'KE',
	KG = 'KG',
	KH = 'KH',
	KI = 'KI',
	KM = 'KM',
	KN = 'KN',
	KP = 'KP',
	KR = 'KR',
	KW = 'KW',
	KY = 'KY',
	KZ = 'KZ',
	LA = 'LA',
	LB = 'LB',
	LC = 'LC',
	LI = 'LI',
	LK = 'LK',
	LR = 'LR',
	LS = 'LS',
	LT = 'LT',
	LU = 'LU',
	LV = 'LV',
	LY = 'LY',
	MA = 'MA',
	MC = 'MC',
	MD = 'MD',
	ME = 'ME',
	MF = 'MF',
	MG = 'MG',
	MH = 'MH',
	MK = 'MK',
	ML = 'ML',
	MM = 'MM',
	MN = 'MN',
	MO = 'MO',
	MP = 'MP',
	MQ = 'MQ',
	MR = 'MR',
	MS = 'MS',
	MT = 'MT',
	MU = 'MU',
	MV = 'MV',
	MW = 'MW',
	MX = 'MX',
	MY = 'MY',
	MZ = 'MZ',
	NA = 'NA',
	NC = 'NC',
	NE = 'NE',
	NF = 'NF',
	NG = 'NG',
	NI = 'NI',
	NL = 'NL',
	NO = 'NO',
	NP = 'NP',
	NR = 'NR',
	NU = 'NU',
	NZ = 'NZ',
	O1 = 'O1',
	OM = 'OM',
	PA = 'PA',
	PE = 'PE',
	PF = 'PF',
	PG = 'PG',
	PH = 'PH',
	PK = 'PK',
	PL = 'PL',
	PM = 'PM',
	PN = 'PN',
	PR = 'PR',
	PS = 'PS',
	PT = 'PT',
	PW = 'PW',
	PY = 'PY',
	QA = 'QA',
	RE = 'RE',
	RO = 'RO',
	RS = 'RS',
	RU = 'RU',
	RW = 'RW',
	SA = 'SA',
	SB = 'SB',
	SC = 'SC',
	SD = 'SD',
	SE = 'SE',
	SG = 'SG',
	SH = 'SH',
	SI = 'SI',
	SJ = 'SJ',
	SK = 'SK',
	SL = 'SL',
	SM = 'SM',
	SN = 'SN',
	SO = 'SO',
	SR = 'SR',
	SS = 'SS',
	ST = 'ST',
	SU = 'SU',
	SV = 'SV',
	SX = 'SX',
	SY = 'SY',
	SZ = 'SZ',
	TA = 'TA',
	TC = 'TC',
	TD = 'TD',
	TF = 'TF',
	TG = 'TG',
	TH = 'TH',
	TJ = 'TJ',
	TK = 'TK',
	TL = 'TL',
	TM = 'TM',
	TN = 'TN',
	TO = 'TO',
	TR = 'TR',
	TT = 'TT',
	TV = 'TV',
	TW = 'TW',
	TZ = 'TZ',
	UA = 'UA',
	UG = 'UG',
	UM = 'UM',
	US = 'US',
	UY = 'UY',
	UZ = 'UZ',
	VA = 'VA',
	VC = 'VC',
	VE = 'VE',
	VG = 'VG',
	VI = 'VI',
	VN = 'VN',
	VU = 'VU',
	WF = 'WF',
	WS = 'WS',
	XK = 'XK',
	YE = 'YE',
	YT = 'YT',
	ZA = 'ZA',
	ZM = 'ZM',
	ZW = 'ZW',
}

export const maskList = [
	{ 'code': '+247 ####', 'country': 'Ascension' },
	{ 'code': '+290 ####', 'country': 'Saint Helena' },
	{ 'code': '+683 ####', 'country': 'Niue' },
	{ 'code': '+690 ####', 'country': 'Tokelau' },
	{ 'code': '+500 #####', 'country': 'Falkland Islands' },
	{ 'code': '+676 #####', 'country': 'Tonga' },
	{ 'code': '+677 #####', 'country': 'Solomon Islands' },
	{ 'code': '+678 #####', 'country': 'Vanuatu' },
	{ 'code': '+688 2####', 'country': 'Tuvalu' },
	{ 'code': '+49 ### ###', 'country': 'Germany' },
	{ 'code': '+682 ## ###', 'country': 'Cook Islands' },
	{ 'code': '+686 ## ###', 'country': 'Kiribati' },
	{ 'code': '+688 90####', 'country': 'Tuvalu' },
	{ 'code': '+95 ### ###', 'country': 'Myanmar' },
	{ 'code': '+298 ### ###', 'country': 'Faroe Islands' },
	{ 'code': '+376 ### ###', 'country': 'Andorra' },
	{ 'code': '+387 ## ####', 'country': 'Bosnia and Herzegovina' },
	{ 'code': '+508 ## ####', 'country': 'Saint Pierre and Miquelon' },
	{ 'code': '+597 ### ###', 'country': 'Suriname' },
	{ 'code': '+672 1## ###', 'country': 'Australian External Territories' },
	{ 'code': '+672 3## ###', 'country': 'Norfolk Island' },
	{ 'code': '+681 ## ####', 'country': 'Wallis and Futuna' },
	{ 'code': '+685 ## ####', 'country': 'Samoa' },
	{ 'code': '+687 ## ####', 'country': 'New Caledonia' },
	{ 'code': '+850 ### ###', 'country': 'North Korea' },
	{ 'code': '+230 ### ####', 'country': 'Mauritius' },
	{ 'code': '+239 ## #####', 'country': 'São Tomé and Príncipe' },
	{ 'code': '+245 # ######', 'country': 'Guinea-Bissau' },
	{ 'code': '+246 ### ####', 'country': 'Diego Garcia' },
	{ 'code': '+263 # ######', 'country': 'Zimbabwe' },
	{ 'code': '+269 ## #####', 'country': 'Comoros' },
	{ 'code': '+297 ### ####', 'country': 'Aruba' },
	{ 'code': '+299 ## ## ##', 'country': 'Greenland' },
	{ 'code': '+354 ### ####', 'country': 'Iceland' },
	{ 'code': '+387 ## #####', 'country': 'Bosnia and Herzegovina' },
	{ 'code': '+49 ### ## ##', 'country': 'Germany' },
	{ 'code': '+501 ### ####', 'country': 'Belize' },
	{ 'code': '+507 ### ####', 'country': 'Panama' },
	{ 'code': '+592 ### ####', 'country': 'Guyana' },
	{ 'code': '+597 ### ####', 'country': 'Suriname' },
	{ 'code': '+599 ### ####', 'country': 'Caribbean Netherlands' },
	{ 'code': '+599 ### ####', 'country': 'Curaçao' },
	{ 'code': '+599 ### ####', 'country': 'Sint Maarten' },
	{ 'code': '+60 # ### ###', 'country': 'Malaysia' },
	{ 'code': '+62 ## ### ##', 'country': 'Indonesia' },
	{ 'code': '+65 #### ####', 'country': 'Singapore' },
	{ 'code': '+670 ### ####', 'country': 'Timor-Leste' },
	{ 'code': '+673 ### ####', 'country': 'Brunei' },
	{ 'code': '+674 ### ####', 'country': 'Nauru' },
	{ 'code': '+677 ### ####', 'country': 'Solomon Islands' },
	{ 'code': '+678 ## #####', 'country': 'Vanuatu' },
	{ 'code': '+679 ## #####', 'country': 'Fiji' },
	{ 'code': '+680 ### ####', 'country': 'Palau' },
	{ 'code': '+689 ## ## ##', 'country': 'French Polynesia' },
	{ 'code': '+691 ### ####', 'country': 'Micronesia' },
	{ 'code': '+692 ### ####', 'country': 'Marshall Islands' },
	{ 'code': '+95 # ### ###', 'country': 'Myanmar' },
	{ 'code': '+960 ### ####', 'country': 'Maldives' },
	{ 'code': '+220 ### ## ##', 'country': 'Gambia' },
	{ 'code': '+232 ## ######', 'country': 'Sierra Leone' },
	{ 'code': '+234 ## ### ##', 'country': 'Nigeria' },
	{ 'code': '+237 #### ####', 'country': 'Cameroon' },
	{ 'code': '+238 ### ## ##', 'country': 'Cape Verde' },
	{ 'code': '+248 # ### ###', 'country': 'Seychelles' },
	{ 'code': '+252 # ### ###', 'country': 'Somalia' },
	{ 'code': '+252 # ### ###', 'country': 'Somalia' },
	{ 'code': '+265 1 ### ###', 'country': 'Malawi' },
	{ 'code': '+291 # ### ###', 'country': 'Eritrea' },
	{ 'code': '+350 ### #####', 'country': 'Gibraltar' },
	{ 'code': '+356 #### ####', 'country': 'Malta' },
	{ 'code': '+372 #### ####', 'country': 'Estonia' },
	{ 'code': '+373 #### ####', 'country': 'Moldova' },
	{ 'code': '+47 ### ## ###', 'country': 'Norway' },
	{ 'code': '+49 ### ## ###', 'country': 'Germany' },
	{ 'code': '+504 #### ####', 'country': 'Honduras' },
	{ 'code': '+505 #### ####', 'country': 'Nicaragua' },
	{ 'code': '+506 #### ####', 'country': 'Costa Rica' },
	{ 'code': '+52 ## ## ####', 'country': 'Mexico' },
	{ 'code': '+53 # ### ####', 'country': 'Cuba' },
	{ 'code': '+599 9### ####', 'country': 'Curaçao' },
	{ 'code': '+60 ## ### ###', 'country': 'Malaysia' },
	{ 'code': '+62 ## ### ###', 'country': 'Indonesia' },
	{ 'code': '+64 ## ### ###', 'country': 'New Zealand' },
	{ 'code': '+66 ## ### ###', 'country': 'Thailand' },
	{ 'code': '+670 77# #####', 'country': 'Timor-Leste' },
	{ 'code': '+670 78# #####', 'country': 'Timor-Leste' },
	{ 'code': '+850 #### ####', 'country': 'North Korea' },
	{ 'code': '+852 #### ####', 'country': 'Hong Kong' },
	{ 'code': '+853 #### ####', 'country': 'Macau' },
	{ 'code': '+886 #### ####', 'country': 'Taiwan' },
	{ 'code': '+95 ## ### ###', 'country': 'Myanmar' },
	{ 'code': '+961 # ### ###', 'country': 'Lebanon' },
	{ 'code': '+965 #### ####', 'country': 'Kuwait' },
	{ 'code': '+967 # ### ###', 'country': 'Yemen' },
	{ 'code': '+973 #### ####', 'country': 'Bahrain' },
	{ 'code': '+974 #### ####', 'country': 'Qatar' },
	{ 'code': '+975 # ### ###', 'country': 'Bhutan' },
	{ 'code': '+1 ### ### ####', 'country': 'United States' },
	{ 'code': '+1 242 ### ####', 'country': 'Bahamas' },
	{ 'code': '+1 246 ### ####', 'country': 'Barbados' },
	{ 'code': '+1 264 ### ####', 'country': 'Anguilla' },
	{ 'code': '+1 268 ### ####', 'country': 'Antigua and Barbuda' },
	{ 'code': '+1 284 ### ####', 'country': 'British Virgin Islands' },
	{ 'code': '+1 340 ### ####', 'country': 'United States Virgin Islands' },
	{ 'code': '+1 345 ### ####', 'country': 'Cayman Islands' },
	{ 'code': '+1 441 ### ####', 'country': 'Bermuda' },
	{ 'code': '+1 473 ### ####', 'country': 'Grenada' },
	{ 'code': '+1 649 ### ####', 'country': 'Turks and Caicos Islands' },
	{ 'code': '+1 664 ### ####', 'country': 'Montserrat' },
	{ 'code': '+1 670 ### ####', 'country': 'Northern Mariana Islands' },
	{ 'code': '+1 671 ### ####', 'country': 'Guam' },
	{ 'code': '+1 684 ### ####', 'country': 'American Samoa' },
	{ 'code': '+1 758 ### ####', 'country': 'Saint Lucia' },
	{ 'code': '+1 767 ### ####', 'country': 'Dominica' },
	{ 'code': '+1 784 ### ####', 'country': 'Saint Vincent and the Grenadines' },
	{ 'code': '+1 809 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 829 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 849 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 868 ### ####', 'country': 'Trinidad and Tobago' },
	{ 'code': '+1 869 ### ####', 'country': 'Saint Kitts and Nevis' },
	{ 'code': '+1 876 ### ####', 'country': 'Jamaica' },
	{ 'code': '+1 939 ### ####', 'country': 'Puerto Rico' },
	{ 'code': '+1 242 ### ####', 'country': 'Bahamas' },
	{ 'code': '+1 246 ### ####', 'country': 'Barbados' },
	{ 'code': '+1 264 ### ####', 'country': 'Anguilla' },
	{ 'code': '+1 268 ### ####', 'country': 'Antigua and Barbuda' },
	{ 'code': '+1 284 ### ####', 'country': 'British Virgin Islands' },
	{ 'code': '+1 340 ### ####', 'country': 'United States Virgin Islands' },
	{ 'code': '+1 345 ### ####', 'country': 'Cayman Islands' },
	{ 'code': '+1 441 ### ####', 'country': 'Bermuda' },
	{ 'code': '+1 473 ### ####', 'country': 'Grenada' },
	{ 'code': '+1 649 ### ####', 'country': 'Turks and Caicos Islands' },
	{ 'code': '+1 664 ### ####', 'country': 'Montserrat' },
	{ 'code': '+1 670 ### ####', 'country': 'Northern Mariana Islands' },
	{ 'code': '+1 671 ### ####', 'country': 'Guam' },
	{ 'code': '+1 684 ### ####', 'country': 'American Samoa' },
	{ 'code': '+1 758 ### ####', 'country': 'Saint Lucia' },
	{ 'code': '+1 767 ### ####', 'country': 'Dominica' },
	{ 'code': '+1 784 ### ####', 'country': 'Saint Vincent and the Grenadines' },
	{ 'code': '+1 809 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 829 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 849 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 868 ### ####', 'country': 'Trinidad and Tobago' },
	{ 'code': '+1 869 ### ####', 'country': 'Saint Kitts and Nevis' },
	{ 'code': '+1 876 ### ####', 'country': 'Jamaica' },
	{ 'code': '+1 939 ### ####', 'country': 'Puerto Rico' },
	{ 'code': '+1 246 ### ####', 'country': 'Barbados' },
	{ 'code': '+1 268 ### ####', 'country': 'Antigua and Barbuda' },
	{ 'code': '+1 284 ### ####', 'country': 'British Virgin Islands' },
	{ 'code': '+1 345 ### ####', 'country': 'Cayman Islands' },
	{ 'code': '+1 441 ### ####', 'country': 'Bermuda' },
	{ 'code': '+1 473 ### ####', 'country': 'Grenada' },
	{ 'code': '+1 649 ### ####', 'country': 'Turks and Caicos Islands' },
	{ 'code': '+1 664 ### ####', 'country': 'Montserrat' },
	{ 'code': '+1 670 ### ####', 'country': 'Northern Mariana Islands' },
	{ 'code': '+1 671 ### ####', 'country': 'Guam' },
	{ 'code': '+1 684 ### ####', 'country': 'American Samoa' },
	{ 'code': '+1 758 ### ####', 'country': 'Saint Lucia' },
	{ 'code': '+1 767 ### ####', 'country': 'Dominica' },
	{ 'code': '+1 784 ### ####', 'country': 'Saint Vincent and the Grenadines' },
	{ 'code': '+1 809 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 829 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 849 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1 868 ### ####', 'country': 'Trinidad and Tobago' },
	{ 'code': '+1 869 ### ####', 'country': 'Saint Kitts and Nevis' },
	{ 'code': '+1 876 ### ####', 'country': 'Jamaica' },
	{ 'code': '+1 939 ### ####', 'country': 'Puerto Rico' },
	{ 'code': '+20 ### ### ####', 'country': 'Egypt' },
	{ 'code': '+211 ### ### ###', 'country': 'South Sudan' },
	{ 'code': '+212 ### ### ###', 'country': 'Morocco' },
	{ 'code': '+212 ### ### ###', 'country': 'Western Sahara' },
	{ 'code': '+213 ### ### ###', 'country': 'Algeria' },
	{ 'code': '+216 ## ### ###', 'country': 'Tunisia' },
	{ 'code': '+218 ## ### ###', 'country': 'Libya' },
	{ 'code': '+220 ### ## ##', 'country': 'Gambia' },
	{ 'code': '+221 ## ### ##', 'country': 'Senegal' },
	{ 'code': '+222 ## ## ## ##', 'country': 'Mauritania' },
	{ 'code': '+223 ## ## ## ##', 'country': 'Mali' },
	{ 'code': '+224 ## ### ###', 'country': 'Guinea' },
	{ 'code': '+225 ## ### ###', 'country': 'Ivory Coast' },
	{ 'code': '+226 ## ## ## ##', 'country': 'Burkina Faso' },
	{ 'code': '+227 ## ## ## ##', 'country': 'Niger' },
	{ 'code': '+228 ## ### ###', 'country': 'Togo' },
	{ 'code': '+229 ## ### ###', 'country': 'Benin' },
	{ 'code': '+230 ### ####', 'country': 'Mauritius' },
	{ 'code': '+231 ## ### ###', 'country': 'Liberia' },
	{ 'code': '+232 ## ######', 'country': 'Sierra Leone' },
	{ 'code': '+233 ## ### ###', 'country': 'Ghana' },
	{ 'code': '+234 ## ### ##', 'country': 'Nigeria' },
	{ 'code': '+235 ## ## ## ##', 'country': 'Chad' },
	{ 'code': '+236 ## ## ## ##', 'country': 'Central African Republic' },
	{ 'code': '+237 #### ####', 'country': 'Cameroon' },
	{ 'code': '+238 ### ## ##', 'country': 'Cape Verde' },
	{ 'code': '+239 ## #####', 'country': 'São Tomé and Príncipe' },
	{ 'code': '+240 ## ### ####', 'country': 'Equatorial Guinea' },
	{ 'code': '+241 ## ## ## ##', 'country': 'Gabon' },
	{ 'code': '+242 ## ### ####', 'country': 'Republic of the Congo' },
	{ 'code': '+243 ### ### ###', 'country': 'Democratic Republic of the Congo' },
	{ 'code': '+244 ### ### ###', 'country': 'Angola' },
	{ 'code': '+245 # ######', 'country': 'Guinea-Bissau' },
	{ 'code': '+246 ### ####', 'country': 'Diego Garcia' },
	{ 'code': '+247 ####', 'country': 'Ascension' },
	{ 'code': '+248 # ### ###', 'country': 'Seychelles' },
	{ 'code': '+249 ## ### ####', 'country': 'Sudan' },
	{ 'code': '+250 ### ### ###', 'country': 'Rwanda' },
	{ 'code': '+251 ## ### ####', 'country': 'Ethiopia' },
	{ 'code': '+252 ## ### ###', 'country': 'Somalia' },
	{ 'code': '+253 ## ## ## ##', 'country': 'Djibouti' },
	{ 'code': '+254 ### ### ###', 'country': 'Kenya' },
	{ 'code': '+255 ## ### ###', 'country': 'Tanzania' },
	{ 'code': '+255 24 ### ####', 'country': 'Zanzibar' },
	{ 'code': '+256 ### ####', 'country': 'Uganda' },
	{ 'code': '+257 ## ## ####', 'country': 'Burundi' },
	{ 'code': '+258 ## ### ####', 'country': 'Mozambique' },
	{ 'code': '+260 ### ### ###', 'country': 'Zambia' },
	{ 'code': '+261 ## ## ####', 'country': 'Madagascar' },
	{ 'code': '+262 ##### ####', 'country': 'Reunion' },
	{ 'code': '+262 ##### ####', 'country': 'Mayotte' },
	{ 'code': '+263 ### ####', 'country': 'Zimbabwe' },
	{ 'code': '+264 ## ### ###', 'country': 'Namibia' },
	{ 'code': '+265 # #### ####', 'country': 'Malawi' },
	{ 'code': '+266 ## ## ####', 'country': 'Lesotho' },
	{ 'code': '+267 ## ### ###', 'country': 'Botswana' },
	{ 'code': '+268 ## ## ####', 'country': 'Eswatini' },
	{ 'code': '+269 ## ## ####', 'country': 'Comoros' },
	{ 'code': '+27 ## ### ####', 'country': 'South Africa' },
	{ 'code': '+290 ####', 'country': 'Saint Helena' },
	{ 'code': '+291 # ### ###', 'country': 'Eritrea' },
	{ 'code': '+297 ### ####', 'country': 'Aruba' },
	{ 'code': '+298 ### ###', 'country': 'Faroe Islands' },
	{ 'code': '+299 ## ## ##', 'country': 'Greenland' },
	{ 'code': '+30 ### ### ####', 'country': 'Greece' },
	{ 'code': '+31 ## ### ####', 'country': 'Netherlands' },
	{ 'code': '+32 ### ## ## ##', 'country': 'Belgium' },
	{ 'code': '+33 # ## ## ## ##', 'country': 'France' },
	{ 'code': '+34 ### ### ###', 'country': 'Spain' },
	{ 'code': '+350 ### #####', 'country': 'Gibraltar' },
	{ 'code': '+351 ## ### ####', 'country': 'Portugal' },
	{ 'code': '+352 #### ####', 'country': 'Luxembourg' },
	{ 'code': '+353 ### ### ###', 'country': 'Ireland' },
	{ 'code': '+354 ### ####', 'country': 'Iceland' },
	{ 'code': '+355 ### ### ###', 'country': 'Albania' },
	{ 'code': '+356 #### ####', 'country': 'Malta' },
	{ 'code': '+357 ## ### ###', 'country': 'Cyprus' },
	{ 'code': '+358 ### ### ##', 'country': 'Finland' },
	{ 'code': '+359 ### ### ###', 'country': 'Bulgaria' },
	{ 'code': '+36 ### ### ###', 'country': 'Hungary' },
	{ 'code': '+370 ### ## ###', 'country': 'Lithuania' },
	{ 'code': '+371 #### ####', 'country': 'Latvia' },
	{ 'code': '+372 #### ####', 'country': 'Estonia' },
	{ 'code': '+373 #### ####', 'country': 'Moldova' },
	{ 'code': '+374 ## ### ###', 'country': 'Armenia' },
	{ 'code': '+375 ## ### ####', 'country': 'Belarus' },
	{ 'code': '+376 #### ###', 'country': 'Andorra' },
	{ 'code': '+377 ### ### ###', 'country': 'Monaco' },
	{ 'code': '+378 #### ####', 'country': 'San Marino' },
	{ 'code': '+379 #### ####', 'country': 'Vatican' },
	{ 'code': '+380 ## ### ## ##', 'country': 'Ukraine' },
	{ 'code': '+381 ## ### ###', 'country': 'Serbia' },
	{ 'code': '+382 ## ### ###', 'country': 'Montenegro' },
	{ 'code': '+383 ## ### ###', 'country': 'Kosovo' },
	{ 'code': '+385 ## ### ###', 'country': 'Croatia' },
	{ 'code': '+386 ## ### ###', 'country': 'Slovenia' },
	{ 'code': '+387 ## ### ###', 'country': 'Bosnia and Herzegovina' },
	{ 'code': '+389 ## ### ###', 'country': 'North Macedonia' },
	{ 'code': '+39 ### #### ###', 'country': 'Italy' },
	{ 'code': '+40 ## ### ####', 'country': 'Romania' },
	{ 'code': '+41 ## ### ## ##', 'country': 'Switzerland' },
	{ 'code': '+420 ### ### ###', 'country': 'Czech Republic' },
	{ 'code': '+421 ## ### ####', 'country': 'Slovakia' },
	{ 'code': '+423 ### ####', 'country': 'Liechtenstein' },
	{ 'code': '+43 ### ### ####', 'country': 'Austria' },
	{ 'code': '+44 #### ### ###', 'country': 'United Kingdom' },
	{ 'code': '+45 ## ## ## ##', 'country': 'Denmark' },
	{ 'code': '+46 ### ### ###', 'country': 'Sweden' },
	{ 'code': '+47 ### ## ###', 'country': 'Norway' },
	{ 'code': '+48 ### ### ###', 'country': 'Poland' },
	{ 'code': '+49 ### ###', 'country': 'Germany' },
	{ 'code': '+500 #####', 'country': 'Falkland Islands' },
	{ 'code': '+501 ### ####', 'country': 'Belize' },
	{ 'code': '+502 # ### ####', 'country': 'Guatemala' },
	{ 'code': '+503 ## ### ####', 'country': 'El Salvador' },
	{ 'code': '+504 #### ####', 'country': 'Honduras' },
	{ 'code': '+505 #### ####', 'country': 'Nicaragua' },
	{ 'code': '+506 #### ####', 'country': 'Costa Rica' },
	{ 'code': '+507 ### ####', 'country': 'Panama' },
	{ 'code': '+508 ## ####', 'country': 'Saint Pierre and Miquelon' },
	{ 'code': '+509 ## ## ####', 'country': 'Haiti' },
	{ 'code': '+51 ### ### ###', 'country': 'Peru' },
	{ 'code': '+52 ### ### ###', 'country': 'Mexico' },
	{ 'code': '+53 # ### ####', 'country': 'Cuba' },
	{ 'code': '+54 # ### ###', 'country': 'Argentina' },
	{ 'code': '+55 ## ##### ####', 'country': 'Brazil' },
	{ 'code': '+56 # #### ####', 'country': 'Chile' },
	{ 'code': '+57 ### ### ####', 'country': 'Colombia' },
	{ 'code': '+58 ### ### ####', 'country': 'Venezuela' },
	{ 'code': '+590 ### ### ###', 'country': 'Guadeloupe' },
	{ 'code': '+591 # ### ####', 'country': 'Bolivia' },
	{ 'code': '+592 ### ####', 'country': 'Guyana' },
	{ 'code': '+593 ## ### ####', 'country': 'Ecuador' },
	{ 'code': '+595 ### ### ###', 'country': 'Paraguay' },
	{ 'code': '+597 ### ###', 'country': 'Suriname' },
	{ 'code': '+598 # ### ## ##', 'country': 'Uruguay' },
	{ 'code': '+599 ### ####', 'country': 'Netherlands Antilles' },
	{ 'code': '+60 # ### ####', 'country': 'Malaysia' },
	{ 'code': '+61 # #### ####', 'country': 'Australia' },
	{ 'code': '+61 # #### ####', 'country': 'Christmas Island' },
	{ 'code': '+61 # #### ####', 'country': 'Cocos Islands' },
	{ 'code': '+61 # #### ####', 'country': 'Norfolk Island' },
	{ 'code': '+62 ## ### ####', 'country': 'Indonesia' },
	{ 'code': '+63 ### ### ####', 'country': 'Philippines' },
	{ 'code': '+64 ### ### ####', 'country': 'New Zealand' },
	{ 'code': '+65 #### ####', 'country': 'Singapore' },
	{ 'code': '+66 ## ### ####', 'country': 'Thailand' },
	{ 'code': '+670 ### ####', 'country': 'East Timor' },
	{ 'code': '+671 ### ####', 'country': 'Guam' },
	{ 'code': '+672 # ### ####', 'country': 'Antarctica' },
	{ 'code': '+673 ### ####', 'country': 'Brunei' },
	{ 'code': '+674 ### ####', 'country': 'Nauru' },
	{ 'code': '+675 ### ####', 'country': 'Papua New Guinea' },
	{ 'code': '+676 ## ###', 'country': 'Tonga' },
	{ 'code': '+677 ### ####', 'country': 'Solomon Islands' },
	{ 'code': '+678 ## ###', 'country': 'Vanuatu' },
	{ 'code': '+679 ## ###', 'country': 'Fiji' },
	{ 'code': '+680 ### ####', 'country': 'Palau' },
	{ 'code': '+681 ## ####', 'country': 'Wallis and Futuna' },
	{ 'code': '+682 ## ###', 'country': 'Cook Islands' },
	{ 'code': '+683 ####', 'country': 'Niue' },
	{ 'code': '+685 ## ###', 'country': 'Samoa' },
	{ 'code': '+686 ## ###', 'country': 'Kiribati' },
	{ 'code': '+687 ## ###', 'country': 'New Caledonia' },
	{ 'code': '+688 # ####', 'country': 'Tuvalu' },
	{ 'code': '+689 ## ##', 'country': 'French Polynesia' },
	{ 'code': '+690 ###', 'country': 'Tokelau' },
	{ 'code': '+691 ###', 'country': 'Micronesia' },
	{ 'code': '+692 ###', 'country': 'Marshall Islands' },
	{ 'code': '+7 ### ### ####', 'country': 'Russia' },
	{ 'code': '+7 ### ### ####', 'country': 'Kazakhstan' },
	{ 'code': '+81 ## #### ####', 'country': 'Japan' },
	{ 'code': '+82 ## #### ####', 'country': 'South Korea' },
	{ 'code': '+84 ## ### ####', 'country': 'Vietnam' },
	{ 'code': '+850 #### ####', 'country': 'North Korea' },
	{ 'code': '+852 #### ####', 'country': 'Hong Kong' },
	{ 'code': '+853 #### ####', 'country': 'Macau' },
	{ 'code': '+855 ## ### ###', 'country': 'Cambodia' },
	{ 'code': '+856 ## ### ###', 'country': 'Laos' },
	{ 'code': '+86 ## ########', 'country': 'China' },
	{ 'code': '+880 ## ### ####', 'country': 'Bangladesh' },
	{ 'code': '+886 # #### ####', 'country': 'Taiwan' },
	{ 'code': '+90 ### ### ## ##', 'country': 'Turkey' },
	{ 'code': '+91 ##### #####', 'country': 'India' },
	{ 'code': '+92 ### ### ####', 'country': 'Pakistan' },
	{ 'code': '+93 ## ### ####', 'country': 'Afghanistan' },
	{ 'code': '+94 ## ### ####', 'country': 'Sri Lanka' },
	{ 'code': '+95 # ### ###', 'country': 'Myanmar' },
	{ 'code': '+960 ### ####', 'country': 'Maldives' },
	{ 'code': '+961 ## ### ###', 'country': 'Lebanon' },
	{ 'code': '+962 # #### ####', 'country': 'Jordan' },
	{ 'code': '+963 ## ### ###', 'country': 'Syria' },
	{ 'code': '+964 ## ### ####', 'country': 'Iraq' },
	{ 'code': '+965 #### ####', 'country': 'Kuwait' },
	{ 'code': '+966 # ### ####', 'country': 'Saudi Arabia' },
	{ 'code': '+967 ### ### ###', 'country': 'Yemen' },
	{ 'code': '+968 #### ####', 'country': 'Oman' },
	{ 'code': '+970 ### ### ###', 'country': 'Palestine' },
	{ 'code': '+971 ## ### ####', 'country': 'United Arab Emirates' },
	{ 'code': '+972 # ### ####', 'country': 'Israel' },
	{ 'code': '+973 #### ####', 'country': 'Bahrain' },
	{ 'code': '+974 #### ####', 'country': 'Qatar' },
	{ 'code': '+975 #### ####', 'country': 'Bhutan' },
	{ 'code': '+976 ## ######', 'country': 'Mongolia' },
	{ 'code': '+977 ## ######', 'country': 'Nepal' },
	{ 'code': '+98 ### ### ####', 'country': 'Iran' },
	{ 'code': '+992 ### ## ####', 'country': 'Tajikistan' },
	{ 'code': '+993 ## ### ####', 'country': 'Turkmenistan' },
	{ 'code': '+994 ## ### ## ##', 'country': 'Azerbaijan' },
	{ 'code': '+995 ### ### ###', 'country': 'Georgia' },
	{ 'code': '+996 ### ### ###', 'country': 'Kyrgyzstan' },
	{ 'code': '+998 ## ### ## ##', 'country': 'Uzbekistan' },
	{ 'code': '+1242 ### ####', 'country': 'Bahamas' },
	{ 'code': '+1246 ### ####', 'country': 'Barbados' },
	{ 'code': '+1264 ### ####', 'country': 'Anguilla' },
	{ 'code': '+1268 ### ####', 'country': 'Antigua and Barbuda' },
	{ 'code': '+1284 ### ####', 'country': 'British Virgin Islands' },
	{ 'code': '+1340 ### ####', 'country': 'United States Virgin Islands' },
	{ 'code': '+1345 ### ####', 'country': 'Cayman Islands' },
	{ 'code': '+1441 ### ####', 'country': 'Bermuda' },
	{ 'code': '+1473 ### ####', 'country': 'Grenada' },
	{ 'code': '+1649 ### ####', 'country': 'Turks and Caicos Islands' },
	{ 'code': '+1664 ### ####', 'country': 'Montserrat' },
	{ 'code': '+1670 ### ####', 'country': 'Northern Mariana Islands' },
	{ 'code': '+1671 ### ####', 'country': 'Guam' },
	{ 'code': '+1684 ### ####', 'country': 'American Samoa' },
	{ 'code': '+1758 ### ####', 'country': 'Saint Lucia' },
	{ 'code': '+1767 ### ####', 'country': 'Dominica' },
	{ 'code': '+1784 ### ####', 'country': 'Saint Vincent and the Grenadines' },
	{ 'code': '+1809 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1829 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1849 ### ####', 'country': 'Dominican Republic' },
	{ 'code': '+1868 ### ####', 'country': 'Trinidad and Tobago' },
	{ 'code': '+1869 ### ####', 'country': 'Saint Kitts and Nevis' },
	{ 'code': '+1876 ### ####', 'country': 'Jamaica' },
	{ 'code': '+1939 ### ####', 'country': 'Puerto Rico' },
];

export const MouseEvents = [
	'Delete',
	'Backspace',
	'ArrowRight',
	'ArrowLeft',
	'ArrowDown',
	'ArrowUp',
];

export const timeZoneCityToCountry: TimeZoneCityToCountry = {
	'Andorra'       : 'Andorra',
	'Dubai'         : 'United Arab Emirates',
	'Kabul'         : 'Afghanistan',
	'Tirane'        : 'Albania',
	'Yerevan'       : 'Armenia',
	'Casey'         : 'Antarctica',
	'Davis'         : 'Antarctica',
	'Mawson'        : 'Antarctica',
	'Palmer'        : 'Antarctica',
	'Rothera'       : 'Antarctica',
	'Troll'         : 'Antarctica',
	'Vostok'        : 'Antarctica',
	'Buenos_Aires'  : 'Argentina',
	'Cordoba'       : 'Argentina',
	'Salta'         : 'Argentina',
	'Jujuy'         : 'Argentina',
	'Tucuman'       : 'Argentina',
	'Catamarca'     : 'Argentina',
	'La_Rioja'      : 'Argentina',
	'San_Juan'      : 'Argentina',
	'Mendoza'       : 'Argentina',
	'San_Luis'      : 'Argentina',
	'Rio_Gallegos'  : 'Argentina',
	'Ushuaia'       : 'Argentina',
	'Pago_Pago'     : 'Samoa (American)',
	'Vienna'        : 'Austria',
	'Lord_Howe'     : 'Australia',
	'Macquarie'     : 'Australia',
	'Hobart'        : 'Australia',
	'Melbourne'     : 'Australia',
	'Sydney'        : 'Australia',
	'Broken_Hill'   : 'Australia',
	'Brisbane'      : 'Australia',
	'Lindeman'      : 'Australia',
	'Adelaide'      : 'Australia',
	'Darwin'        : 'Australia',
	'Perth'         : 'Australia',
	'Eucla'         : 'Australia',
	'Baku'          : 'Azerbaijan',
	'Barbados'      : 'Barbados',
	'Dhaka'         : 'Bangladesh',
	'Brussels'      : 'Belgium',
	'Sofia'         : 'Bulgaria',
	'Bermuda'       : 'Bermuda',
	'Brunei'        : 'Brunei',
	'La_Paz'        : 'Bolivia',
	'Noronha'       : 'Brazil',
	'Belem'         : 'Brazil',
	'Fortaleza'     : 'Brazil',
	'Recife'        : 'Brazil',
	'Araguaina'     : 'Brazil',
	'Maceio'        : 'Brazil',
	'Bahia'         : 'Brazil',
	'Sao_Paulo'     : 'Brazil',
	'Campo_Grande'  : 'Brazil',
	'Cuiaba'        : 'Brazil',
	'Santarem'      : 'Brazil',
	'Porto_Velho'   : 'Brazil',
	'Boa_Vista'     : 'Brazil',
	'Manaus'        : 'Brazil',
	'Eirunepe'      : 'Brazil',
	'Rio_Branco'    : 'Brazil',
	'Thimphu'       : 'Bhutan',
	'Minsk'         : 'Belarus',
	'Belize'        : 'Belize',
	'St_Johns'      : 'Canada',
	'Halifax'       : 'Canada',
	'Glace_Bay'     : 'Canada',
	'Moncton'       : 'Canada',
	'Goose_Bay'     : 'Canada',
	'Toronto'       : 'Canada',
	'Nipigon'       : 'Canada',
	'Thunder_Bay'   : 'Canada',
	'Iqaluit'       : 'Canada',
	'Pangnirtung'   : 'Canada',
	'Winnipeg'      : 'Canada',
	'Rainy_River'   : 'Canada',
	'Resolute'      : 'Canada',
	'Rankin_Inlet'  : 'Canada',
	'Regina'        : 'Canada',
	'Swift_Current' : 'Canada',
	'Edmonton'      : 'Canada',
	'Cambridge_Bay' : 'Canada',
	'Yellowknife'   : 'Canada',
	'Inuvik'        : 'Canada',
	'Dawson_Creek'  : 'Canada',
	'Fort_Nelson'   : 'Canada',
	'Whitehorse'    : 'Canada',
	'Dawson'        : 'Canada',
	'Vancouver'     : 'Canada',
	'Cocos'         : 'Cocos (Keeling) Islands',
	'Zurich'        : 'Switzerland',
	'Abidjan'       : 'Côte d\'Ivoire',
	'Rarotonga'     : 'Cook Islands',
	'Santiago'      : 'Chile',
	'Punta_Arenas'  : 'Chile',
	'Easter'        : 'Chile',
	'Shanghai'      : 'China',
	'Urumqi'        : 'China',
	'Bogota'        : 'Colombia',
	'Costa_Rica'    : 'Costa Rica',
	'Havana'        : 'Cuba',
	'Cape_Verde'    : 'Cape Verde',
	'Christmas'     : 'Christmas Island',
	'Nicosia'       : 'Cyprus',
	'Famagusta'     : 'Cyprus',
	'Prague'        : 'Czech Republic',
	'Berlin'        : 'Germany',
	'Copenhagen'    : 'Denmark',
	'Santo_Domingo' : 'Dominican Republic',
	'Algiers'       : 'Algeria',
	'Guayaquil'     : 'Ecuador',
	'Galapagos'     : 'Ecuador',
	'Tallinn'       : 'Estonia',
	'Cairo'         : 'Egypt',
	'El_Aaiun'      : 'Western Sahara',
	'Madrid'        : 'Spain',
	'Ceuta'         : 'Spain',
	'Canary'        : 'Spain',
	'Helsinki'      : 'Finland',
	'Fiji'          : 'Fiji',
	'Stanley'       : 'Falkland Islands',
	'Chuuk'         : 'Micronesia',
	'Pohnpei'       : 'Micronesia',
	'Kosrae'        : 'Micronesia',
	'Faroe'         : 'Faroe Islands',
	'Paris'         : 'France',
	'London'        : 'Britain (UK)',
	'Tbilisi'       : 'Georgia',
	'Cayenne'       : 'French Guiana',
	'Gibraltar'     : 'Gibraltar',
	'Nuuk'          : 'Greenland',
	'Danmarkshavn'  : 'Greenland',
	'Scoresbysund'  : 'Greenland',
	'Thule'         : 'Greenland',
	'Athens'        : 'Greece',
	'South_Georgia' : 'South Georgia & the South Sandwich Islands',
	'Guatemala'     : 'Guatemala',
	'Guam'          : 'Guam',
	'Bissau'        : 'Guinea-Bissau',
	'Guyana'        : 'Guyana',
	'Hong_Kong'     : 'Hong Kong',
	'Tegucigalpa'   : 'Honduras',
	'Port-au-Prince': 'Haiti',
	'Budapest'      : 'Hungary',
	'Jakarta'       : 'Indonesia',
	'Pontianak'     : 'Indonesia',
	'Makassar'      : 'Indonesia',
	'Jayapura'      : 'Indonesia',
	'Dublin'        : 'Ireland',
	'Jerusalem'     : 'Israel',
	'Kolkata'       : 'India',
	'Calcutta'      : 'India',
	'Chagos'        : 'British Indian Ocean Territory',
	'Baghdad'       : 'Iraq',
	'Tehran'        : 'Iran',
	'Reykjavik'     : 'Iceland',
	'Rome'          : 'Italy',
	'Jamaica'       : 'Jamaica',
	'Amman'         : 'Jordan',
	'Tokyo'         : 'Japan',
	'Nairobi'       : 'Kenya',
	'Bishkek'       : 'Kyrgyzstan',
	'Tarawa'        : 'Kiribati',
	'Kanton'        : 'Kiribati',
	'Kiritimati'    : 'Kiribati',
	'Pyongyang'     : 'Korea (North)',
	'Seoul'         : 'Korea (South)',
	'Almaty'        : 'Kazakhstan',
	'Qyzylorda'     : 'Kazakhstan',
	'Qostanay'      : 'Kazakhstan',
	'Aqtobe'        : 'Kazakhstan',
	'Aqtau'         : 'Kazakhstan',
	'Atyrau'        : 'Kazakhstan',
	'Oral'          : 'Kazakhstan',
	'Beirut'        : 'Lebanon',
	'Colombo'       : 'Sri Lanka',
	'Monrovia'      : 'Liberia',
	'Vilnius'       : 'Lithuania',
	'Luxembourg'    : 'Luxembourg',
	'Riga'          : 'Latvia',
	'Tripoli'       : 'Libya',
	'Casablanca'    : 'Morocco',
	'Monaco'        : 'Monaco',
	'Chisinau'      : 'Moldova',
	'Majuro'        : 'Marshall Islands',
	'Kwajalein'     : 'Marshall Islands',
	'Yangon'        : 'Myanmar (Burma)',
	'Ulaanbaatar'   : 'Mongolia',
	'Hovd'          : 'Mongolia',
	'Choibalsan'    : 'Mongolia',
	'Macau'         : 'Macau',
	'Martinique'    : 'Martinique',
	'Malta'         : 'Malta',
	'Mauritius'     : 'Mauritius',
	'Maldives'      : 'Maldives',
	'Mexico_City'   : 'Mexico',
	'Cancun'        : 'Mexico',
	'Merida'        : 'Mexico',
	'Monterrey'     : 'Mexico',
	'Matamoros'     : 'Mexico',
	'Mazatlan'      : 'Mexico',
	'Chihuahua'     : 'Mexico',
	'Ojinaga'       : 'Mexico',
	'Hermosillo'    : 'Mexico',
	'Tijuana'       : 'Mexico',
	'Bahia_Banderas': 'Mexico',
	'Kuala_Lumpur'  : 'Malaysia',
	'Kuching'       : 'Malaysia',
	'Maputo'        : 'Mozambique',
	'Windhoek'      : 'Namibia',
	'Noumea'        : 'New Caledonia',
	'Norfolk'       : 'Norfolk Island',
	'Lagos'         : 'Nigeria',
	'Managua'       : 'Nicaragua',
	'Amsterdam'     : 'Netherlands',
	'Oslo'          : 'Norway',
	'Kathmandu'     : 'Nepal',
	'Nauru'         : 'Nauru',
	'Niue'          : 'Niue',
	'Auckland'      : 'New Zealand',
	'Chatham'       : 'New Zealand',
	'Panama'        : 'Panama',
	'Lima'          : 'Peru',
	'Tahiti'        : 'French Polynesia',
	'Marquesas'     : 'French Polynesia',
	'Gambier'       : 'French Polynesia',
	'Port_Moresby'  : 'Papua New Guinea',
	'Bougainville'  : 'Papua New Guinea',
	'Manila'        : 'Philippines',
	'Karachi'       : 'Pakistan',
	'Warsaw'        : 'Poland',
	'Miquelon'      : 'St Pierre & Miquelon',
	'Pitcairn'      : 'Pitcairn',
	'Puerto_Rico'   : 'Puerto Rico',
	'Gaza'          : 'Palestine',
	'Hebron'        : 'Palestine',
	'Lisbon'        : 'Portugal',
	'Madeira'       : 'Portugal',
	'Azores'        : 'Portugal',
	'Palau'         : 'Palau',
	'Asuncion'      : 'Paraguay',
	'Qatar'         : 'Qatar',
	'Reunion'       : 'Réunion',
	'Bucharest'     : 'Romania',
	'Belgrade'      : 'Serbia',
	'Kaliningrad'   : 'Russia',
	'Moscow'        : 'Russia',
	'Simferopol'    : 'Russia',
	'Kirov'         : 'Russia',
	'Volgograd'     : 'Russia',
	'Astrakhan'     : 'Russia',
	'Saratov'       : 'Russia',
	'Ulyanovsk'     : 'Russia',
	'Samara'        : 'Russia',
	'Yekaterinburg' : 'Russia',
	'Omsk'          : 'Russia',
	'Novosibirsk'   : 'Russia',
	'Barnaul'       : 'Russia',
	'Tomsk'         : 'Russia',
	'Novokuznetsk'  : 'Russia',
	'Krasnoyarsk'   : 'Russia',
	'Irkutsk'       : 'Russia',
	'Chita'         : 'Russia',
	'Yakutsk'       : 'Russia',
	'Khandyga'      : 'Russia',
	'Vladivostok'   : 'Russia',
	'Ust-Nera'      : 'Russia',
	'Magadan'       : 'Russia',
	'Sakhalin'      : 'Russia',
	'Srednekolymsk' : 'Russia',
	'Kamchatka'     : 'Russia',
	'Anadyr'        : 'Russia',
	'Riyadh'        : 'Saudi Arabia',
	'Guadalcanal'   : 'Solomon Islands',
	'Mahe'          : 'Seychelles',
	'Khartoum'      : 'Sudan',
	'Stockholm'     : 'Sweden',
	'Singapore'     : 'Singapore',
	'Paramaribo'    : 'Suriname',
	'Juba'          : 'South Sudan',
	'Sao_Tome'      : 'Sao Tome & Principe',
	'El_Salvador'   : 'El Salvador',
	'Damascus'      : 'Syria',
	'Grand_Turk'    : 'Turks & Caicos Is',
	'Ndjamena'      : 'Chad',
	'Kerguelen'     : 'French Southern & Antarctic Lands',
	'Bangkok'       : 'Thailand',
	'Dushanbe'      : 'Tajikistan',
	'Fakaofo'       : 'Tokelau',
	'Dili'          : 'East Timor',
	'Ashgabat'      : 'Turkmenistan',
	'Tunis'         : 'Tunisia',
	'Tongatapu'     : 'Tonga',
	'Istanbul'      : 'Turkey',
	'Funafuti'      : 'Tuvalu',
	'Taipei'        : 'Taiwan',
	'Kiev'          : 'Ukraine',
	'Uzhgorod'      : 'Ukraine',
	'Zaporozhye'    : 'Ukraine',
	'Wake'          : 'US minor outlying islands',
	'New_York'      : 'United States',
	'Detroit'       : 'United States',
	'Louisville'    : 'United States',
	'Monticello'    : 'United States',
	'Indianapolis'  : 'United States',
	'Vincennes'     : 'United States',
	'Winamac'       : 'United States',
	'Marengo'       : 'United States',
	'Petersburg'    : 'United States',
	'Vevay'         : 'United States',
	'Chicago'       : 'United States',
	'Tell_City'     : 'United States',
	'Knox'          : 'United States',
	'Menominee'     : 'United States',
	'Center'        : 'United States',
	'New_Salem'     : 'United States',
	'Beulah'        : 'United States',
	'Denver'        : 'United States',
	'Boise'         : 'United States',
	'Phoenix'       : 'United States',
	'Los_Angeles'   : 'United States',
	'Anchorage'     : 'United States',
	'Juneau'        : 'United States',
	'Sitka'         : 'United States',
	'Metlakatla'    : 'United States',
	'Yakutat'       : 'United States',
	'Nome'          : 'United States',
	'Adak'          : 'United States',
	'Honolulu'      : 'United States',
	'Montevideo'    : 'Uruguay',
	'Samarkand'     : 'Uzbekistan',
	'Tashkent'      : 'Uzbekistan',
	'Caracas'       : 'Venezuela',
	'Ho_Chi_Minh'   : 'Vietnam',
	'Efate'         : 'Vanuatu',
	'Wallis'        : 'Wallis & Futuna',
	'Apia'          : 'Samoa (western)',
	'Johannesburg'  : 'South Africa',
	'Antigua'       : 'Antigua & Barbuda',
	'Anguilla'      : 'Anguilla',
	'Luanda'        : 'Angola',
	'McMurdo'       : 'Antarctica',
	'DumontDUrville': 'Antarctica',
	'Syowa'         : 'Antarctica',
	'Aruba'         : 'Aruba',
	'Mariehamn'     : 'Åland Islands',
	'Sarajevo'      : 'Bosnia & Herzegovina',
	'Ouagadougou'   : 'Burkina Faso',
	'Bahrain'       : 'Bahrain',
	'Bujumbura'     : 'Burundi',
	'Porto-Novo'    : 'Benin',
	'St_Barthelemy' : 'St Barthelemy',
	'Kralendijk'    : 'Caribbean NL',
	'Nassau'        : 'Bahamas',
	'Gaborone'      : 'Botswana',
	'Blanc-Sablon'  : 'Canada',
	'Atikokan'      : 'Canada',
	'Creston'       : 'Canada',
	'Kinshasa'      : 'Congo (Dem. Rep.)',
	'Lubumbashi'    : 'Congo (Dem. Rep.)',
	'Bangui'        : 'Central African Rep.',
	'Brazzaville'   : 'Congo (Rep.)',
	'Douala'        : 'Cameroon',
	'Curacao'       : 'Curaçao',
	'Busingen'      : 'Germany',
	'Djibouti'      : 'Djibouti',
	'Dominica'      : 'Dominica',
	'Asmara'        : 'Eritrea',
	'Addis_Ababa'   : 'Ethiopia',
	'Libreville'    : 'Gabon',
	'Grenada'       : 'Grenada',
	'Guernsey'      : 'Guernsey',
	'Accra'         : 'Ghana',
	'Banjul'        : 'Gambia',
	'Conakry'       : 'Guinea',
	'Guadeloupe'    : 'Guadeloupe',
	'Malabo'        : 'Equatorial Guinea',
	'Zagreb'        : 'Croatia',
	'Isle_of_Man'   : 'Isle of Man',
	'Jersey'        : 'Jersey',
	'Phnom_Penh'    : 'Cambodia',
	'Comoro'        : 'Comoros',
	'St_Kitts'      : 'St Kitts & Nevis',
	'Kuwait'        : 'Kuwait',
	'Cayman'        : 'Cayman Islands',
	'Vientiane'     : 'Laos',
	'St_Lucia'      : 'St Lucia',
	'Vaduz'         : 'Liechtenstein',
	'Maseru'        : 'Lesotho',
	'Podgorica'     : 'Montenegro',
	'Marigot'       : 'St Martin (French)',
	'Antananarivo'  : 'Madagascar',
	'Skopje'        : 'North Macedonia',
	'Bamako'        : 'Mali',
	'Saipan'        : 'Northern Mariana Islands',
	'Nouakchott'    : 'Mauritania',
	'Montserrat'    : 'Montserrat',
	'Blantyre'      : 'Malawi',
	'Niamey'        : 'Niger',
	'Muscat'        : 'Oman',
	'Kigali'        : 'Rwanda',
	'St_Helena'     : 'St Helena',
	'Ljubljana'     : 'Slovenia',
	'Longyearbyen'  : 'Svalbard & Jan Mayen',
	'Bratislava'    : 'Slovakia',
	'Freetown'      : 'Sierra Leone',
	'San_Marino'    : 'San Marino',
	'Dakar'         : 'Senegal',
	'Mogadishu'     : 'Somalia',
	'Lower_Princes' : 'St Maarten (Dutch)',
	'Mbabane'       : 'Eswatini (Swaziland)',
	'Lome'          : 'Togo',
	'Port_of_Spain' : 'Trinidad & Tobago',
	'Dar_es_Salaam' : 'Tanzania',
	'Kampala'       : 'Uganda',
	'Midway'        : 'US minor outlying islands',
	'Vatican'       : 'Vatican City',
	'St_Vincent'    : 'St Vincent',
	'Tortola'       : 'Virgin Islands (UK)',
	'St_Thomas'     : 'Virgin Islands (US)',
	'Aden'          : 'Yemen',
	'Mayotte'       : 'Mayotte',
	'Lusaka'        : 'Zambia',
	'Harare'        : 'Zimbabwe',
};

export const AppCreateDate = new Date('2018-01-01T00:00:00Z');