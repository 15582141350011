import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks/storeHooks';
import { profileSelectors } from '../../../redux/profile/selectors';
import { BANNER_GROUPS } from '../../../redux/banners/types';
import { bannerActions } from '../../../redux/banners/actions';
import { bannersSelectors } from '../../../redux/banners/selectors';

const {
	selectAuthToken,
} = profileSelectors;
const {
	selectFooterSocials,
} = bannersSelectors;
const Social: React.FC = ()=>{
	const dispatch = useDispatch();
	const footerSocials = useAppSelector(selectFooterSocials);
	const authToken  = useAppSelector(selectAuthToken);

	useEffect(() => {
		dispatch(bannerActions.getBanners(BANNER_GROUPS.footerSocial));
	}, [dispatch, authToken]);


	return (
		<ul className="social_list">
			{footerSocials.map((item,index) => {
				return (
					<li key={index}>
						<a href={`${item.link}`} target='_blank' rel="noopener noreferrer"  className={`icon_${item.link_text}`}> </a>
					</li>
				);
			})}
		</ul>
	);
};
export default Social;
