import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { IPromotions, IPromotionsInitState, IPromotionUI } from './types';
const initState: IPromotionsInitState = {
	promotions    : null,
	promotionInner: null,
	UI            : {
		innerLoading: false,
	},
};
const promotions = createSlice({
	name        : 'promotions',
	initialState: initState,
	reducers    : {
		setPromotions(state: Draft<IPromotionsInitState>, action: PayloadAction<IPromotions[] | null>) {
			state.promotions = action.payload;
		},
		setPromotionInner(state: Draft<IPromotionsInitState>, action: PayloadAction<IPromotions | null>) {
			state.promotionInner = action.payload;
		},
		setPromotionUI(state: Draft<IPromotionsInitState>, action: PayloadAction<Partial<IPromotionUI>>) {
			state.UI = { ...state.UI, ...action.payload };
		},
	},
});


export const promotionsReducers = {
	setPromotions    : promotions.actions.setPromotions,
	setPromotionInner: promotions.actions.setPromotionInner,
	setPromotionUI   : promotions.actions.setPromotionUI,
};

export default promotions.reducer;
