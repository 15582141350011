import { Link, useLocation } from 'react-router-dom';
import SearchPanel from '../../search-panel';
// import LangSwitcher from '../../lang-switcher'; // commented in prod
import Notifications from '../../notifications';
import HeaderLogin from '../../header-login';
import HeaderProfile from '../../header-profile/header-profile';
import Breadcrumb from '../../controls/breadcrumb';
import { Desktop, NotDesktop } from '../../../helpers/devices';
import { ROUTE_PATH } from '../../../routes/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { profileSelectors } from '../../../redux/profile/selectors';
import { gameReducers } from '../../../redux/games/games_list/slice';
import { modalReducers } from '../../../redux/modals/slice';

import './header.scss';
import Button from '@mui/material/Button';
import { useRootAppNavigate } from '../../../hooks/useAppNavigate';

const {
	selectFirstCategory,
	setSelectedProviders,
} = gameReducers;
const {
	selectAuthToken,
} = profileSelectors;

const PageHeader = () => {
	const { pathname } = useLocation();

	const authToken: string | null = useAppSelector(selectAuthToken);
	const dispatch = useAppDispatch();
	const navigate = useRootAppNavigate();
	const onHomePage = () => {
		dispatch(selectFirstCategory());
		dispatch(setSelectedProviders([]));
	};

	const navigateSport = () => {
		if (authToken) {
			navigate(ROUTE_PATH.sports);
		} else {
			dispatch(modalReducers.setLoginUI({ visible: true, redirect: `/${ROUTE_PATH.sports}` }));
		}
	};

	return (
		<>
			<div className="header">
				<div className="header_inner">
					<div className="custom_container">
						{ pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.tournament}`) ?
							<div className="main_logo main_logo_tournament">
								<Link to={ROUTE_PATH.root}>
									<img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
								</Link>
							</div>
							: null }
						<Desktop>
							<SearchPanel/>
							{/*commented in prod*/}
							<div className="lg_block"></div>
							{/*<LangSwitcher/>*/}
						</Desktop>
						{ pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.errorPage}`) &&
							<div className="main_logo">
								<Link to={ROUTE_PATH.root}>
									<img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
								</Link>
							</div>
						}
						<Desktop>
							{!!authToken && <Notifications /> }
						</Desktop>

						{/*<div className='tournament_btn'> /!* commented in stage  *!/*/}
						{/*	<Button*/}
						{/*		className={`icon_cup ${pathname.includes(ROUTE_PATH.tournament) ? 'active' : ''}`}*/}
						{/*		onClick={() => navigate(ROUTE_PATH.tournament)}></Button>*/}
						{/*</div>*/}
						<div className="sports_book_btn">
							<Button
								className={`icon_bet ${pathname.includes(ROUTE_PATH.sports) ? 'active' : ''}`}
								onClick={navigateSport}>
							</Button>
						</div>
						<NotDesktop>
							<div onClick={onHomePage} className="main_logo">
								<Link to={ROUTE_PATH.root}>
									<img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
								</Link>
							</div>
						</NotDesktop>
						{ authToken ? <HeaderProfile/> :
							<HeaderLogin />
						}
					</div>
				</div>
			</div>
			<Breadcrumb excerpt={[ROUTE_PATH.root, ROUTE_PATH.errorPage]} showHome={false}/>
		</>
	);
};


export default PageHeader;
