import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import Api from './api';
import balanceActions from './actions';
import { logger } from '../../helpers/debugLogger';
import { AxiosApiResponse } from '../../service/types';
import { IBonusBalance, TBonusBalancePayload } from './types';
import { bonusBalanceReducers } from './slice';
import { ISagaActionType } from '../types';
import { profileReducers } from '../profile/slice';


const API = new Api();

function* balance() {
	yield takeEvery(balanceActions.GET_BONUS_BALANCE, function* (action: ISagaActionType<TBonusBalancePayload>) {
		const params = {
			...action.data,
		};
		try {
			const response: AxiosApiResponse<IBonusBalance[]> = yield call(API.getCasinoBonuses, params);

			if (response && response.status === 200) {
				const { data } = response.data;
				let bonusBalance = 0;
				yield put(bonusBalanceReducers.setBonusBalance(data));
				data.forEach(item => bonusBalance += item.initial_balance);
				yield put(profileReducers.setProfileBonusBalance(bonusBalance));
			}

		} catch (e) {
			if (e instanceof Error) {
				const message = {
					title  : 'Error',
					message: e.message,
					type   : 'danger',
				};
				logger.log(message);
			}
		}
	});

}

function* balanceSaga() {
	yield all([
		fork(balance),
	]);
}

export default balanceSaga;
