import {
	FormDataInterface,
	IChangePasswordAction, IDeactivateTronAction, IEmailData,
	IMarkReadAction,
	ISignInData,
	ISignUpAction, ITronLoginAction,
} from './types';

const actions = {
	PROFILE_DATA_RELOAD              : 'PROFILE_DATA_RELOAD',
	EDIT_PROFILE_DATA                : 'EDIT_PROFILE_DATA',
	EDIT_PROFILE_IMAGE               : 'EDIT_PROFILE_IMAGE',
	GET_PROFILE_DOCS                 : 'GET_PROFILE_DOCS',
	UPLOAD_PROFILE_DOC_BINARY        : 'UPLOAD_PROFILE_DOC_BINARY',
	UPLOAD_PROFILE_DOC               : 'UPLOAD_PROFILE_DOC',
	PROFILE_DOC_UPLOAD_STATUS        : 'PROFILE_DOC_UPLOAD_STATUS',
	SIGN_UP                          : 'SIGN_UP',
	RESET_PASSWORD                   : 'RESET_PASSWORD',
	FORGET_PASSWORD                  : 'FORGET_PASSWORD',
	GET_MESSAGES                     : 'GET_MESSAGES',
	GET_UNREAD_INBOX_COUNT           : 'GET_UNREAD_INBOX_COUNT',
	MARK_AS_READ_INBOX               : 'MARK_AS_READ_INBOX',
	CHANGE_PASSWORD                  : 'CHANGE_PASSWORD',
	DEACTIVATE_PROFILE               : 'DEACTIVATE_PROFILE',
	REACTIVATE_PROFILE               : 'REACTIVATE_PROFILE',
	DEACTIVATE_PROFILE_MOBILE        : 'DEACTIVATE_PROFILE_MOBILE',
	VERIFICATION_PHONE_NUMBER        : 'VERIFICATION_PHONE_NUMBER',
	VERIFICATION_CONFIRM_PHONE_NUMBER: 'VERIFICATION_CONFIRM_PHONE_NUMBER',
	VERIFICATION_EMAIL               : 'VERIFICATION_EMAIL',
	CONFIRM_EMAIL                    : 'CONFIRM_EMAIL',
	SIGN_OUT                         : 'SIGN_OUT',
	SIGN_IN                          : 'SIGN_IN',

	TRONLINK_LOGIN           : 'TRONLINK_LOGIN',
	TRONLINK_LOGIN_MOBILE_APP: 'TRONLINK_LOGIN_MOBILE_APP',


	profileDataReload: () => {
		return {
			type: actions.PROFILE_DATA_RELOAD,
		};
	},
	editProfileData: (data: Partial<FormDataInterface>, setCurrency?: boolean, is2FA?: boolean ) => {
		return {
			type: actions.EDIT_PROFILE_DATA,
			data: {
				data,
				setCurrency: !!setCurrency ,
				is2FA      : !!is2FA,
			},
		};
	},
	editProfileImage: (data: FormData) => {
		return {
			type: actions.EDIT_PROFILE_IMAGE,
			data,
		};
	},
	getProfileDocs: () => {
		return {
			type: actions.GET_PROFILE_DOCS,
		};
	},
	forgetPasswordAction: (data: IEmailData) => {
		return {
			type: actions.FORGET_PASSWORD,
			data,
		};
	},
	uploadProfileDoc: (data: { data: { type_id: number; description: string; url: string }; callBack: () => void }) => {
		return {
			type: actions.UPLOAD_PROFILE_DOC,
			data,
		};
	},
	uploadProfileDocBinary: (data: FormData) => {
		return {
			type: actions.UPLOAD_PROFILE_DOC_BINARY,
			data,
		};
	},
	signInAction: (data: FormData) => {
		return {
			type: actions.SIGN_IN,
			data,
		};
	},

	signUpAction: (data: ISignUpAction) => {
		return {
			type: actions.SIGN_UP,
			data,
		};
	},

	signOutAction: () => {
		return {
			type: actions.SIGN_OUT,
		};
	},

	getMessagesAction: () => {
		return {
			type: actions.GET_MESSAGES,
		};
	},
	getUnreadInboxCount: () => {
		return {
			type: actions.GET_UNREAD_INBOX_COUNT,
		};
	},
	markAsReadInbox: (data: IMarkReadAction) => {
		return {
			type: actions.MARK_AS_READ_INBOX,
			data,
		};
	},

	resetPasswordAction: (data: ISignInData) => {
		return {
			type: actions.RESET_PASSWORD,
			data,
		};
	},

	changePassword: (data: IChangePasswordAction) => {
		return {
			type: actions.CHANGE_PASSWORD,
			data,
		};
	},
	deactivateProfileMobile: (data: IDeactivateTronAction) => {
		return {
			type: actions.DEACTIVATE_PROFILE_MOBILE,
			data,
		};
	},

	deactivateProfile: (data: IDeactivateTronAction) => {
		return {
			type: actions.DEACTIVATE_PROFILE,
			data,
		};
	},
	reactivateProfile: () => {
		return {
			type: actions.REACTIVATE_PROFILE,
		};
	},

	tronLinkLogin: (data: ITronLoginAction) => {
		return {
			type: actions.TRONLINK_LOGIN,
			data,
		};
	},

	tronLinkLoginViaMobileApp: () => {
		return {
			type: actions.TRONLINK_LOGIN_MOBILE_APP,
		};
	},

	getVerificationPhoneNumber: (number?: string) => ({
		type: actions.VERIFICATION_PHONE_NUMBER,
		data: {
			number,
		},
	}),

	confirmPhoneNumber: (data: string) => ({
		type: actions.VERIFICATION_CONFIRM_PHONE_NUMBER,
		data,
	}),
	confirmEmail: (hash: string) => ({
		type: actions.CONFIRM_EMAIL,
		data: {
			hash,
		},
	}),
	getVerificationEmail: () => ({
		type: actions.VERIFICATION_EMAIL,
	}),

};

export default actions;
