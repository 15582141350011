import {
	ApiResponse,
	ICookieScript, ICountry, ICurrency, ISettings, TParams,
	TParamsValue,
} from './types';
import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';

interface SettingsApi {
  getScripts        : (params?: Record<string, TParamsValue>) => Promise<void | ApiResponse<ICookieScript>>;
  getCurrencies     : (params?: Record<string, TParamsValue>) => Promise<void | ApiResponse<ICurrency[]>>;
  getInitialSettings: (params?: Record<string, TParamsValue>) => Promise<void | ApiResponse<ISettings>>;
  // getBanners        : (params?: Record<string, TParamsValue>) => Promise<void | ApiResponse<IBanners[]>>;
  getCountries      : (params?: Record<string, TParamsValue>) => Promise<void | ApiResponse<ICountry[]>>;
}


class Settings implements SettingsApi {
	getScripts = (params: TParams = {}): Promise<void | ApiResponse<ICookieScript>> => {
		const url = 'v1/scripts';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
			params,
		};
		return apiRequest(config);
	};

	getCurrencies = (params: TParams = {}): Promise<void | ApiResponse<ICurrency[]>> => {
		const url = '/v1/currencies';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
			params,
		};
		return apiRequest(config);
	};

	getInitialSettings = (params: TParams = {}): Promise<void | ApiResponse<ISettings>> => {
		const url = '/v1/settings';
		const config: PartialConfigs = {
			method : 'get',
			url    : url,
			lang_id: params.lang_id as number,
			params,
		};
		return apiRequest(config);
	};

	// getBanners = (params: TParams = {}): Promise<void | ApiResponse<IBanners[]>> => {
	// 	const url = '/v1/content/banner';
	// 	const config: PartialConfigs = {
	// 		method: 'get',
	// 		url   : url,
	// 		params,
	// 	};
	// 	return apiRequest(config);
	// };

	getCountries = (params: TParams = {}): Promise<void | ApiResponse<ICountry[]>> => {
		const url = '/resources/countries.json';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
			params,
		};
		return apiRequest(config);
	};
}

export default Settings;
