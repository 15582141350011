import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IMessages,
	IProfileData,
	IProfileDoc,
	IProfileDocBinaryStatus,
	IProfileEditStatus,
	IProfileInitState,
	IProfileUI,
	ITronlinkParams,
	ITronlinkSignParams,
	ITronlinkUI,
	VERIFICATION_STATUS,
} from './types';
import { REACT_APP_WEBSITE_ID } from '../../config';
import { Statuses } from '../../constants/common';
import { tronDefaultParams, tronDefaultSignParams, tronDefaultSignTransaction } from './utils';

const initialState: IProfileInitState = {
	profileData: {
		id                 : 0,
		website_id         : REACT_APP_WEBSITE_ID,
		language_id        : null,
		gender             : null,
		verification_status: VERIFICATION_STATUS.NOT_VERIFIED,
		tronlink_address   : null,
		email              : '',
		enable_2fa_app     : false,
		enable_2fa_sms     : false,
		blocked_fields     : {
			username   : false,
			first_name : false,
			last_name  : false,
			gender     : false,
			birth      : false,
			phone      : false,
			city       : false,
			address    : false,
			national_id: false,
			country    : false,
			zip_code   : false,
		},
	},

	userLang         : null,
	profileDocs      : [],
	authToken        : null,
	scrollIntoView   : false,
	pendingLogout   	: false,
	profileEditStatus: {
		hasError : false,
		submitted: false,
		errorMsg : {},
	},
	profileDocBinaryStatus: {
		url     : '',
		hasError: false,
		errorMsg: {},
	},
	passwordReseted: false,
	messages       : [],
	hasMore        : false,
	open_document  : false,
	tronlink       : {
		params    : tronDefaultParams,
		signParams: tronDefaultSignParams,
		UI        : {
			loading       : false,
			actionIdStatus: false,
			forgetLoading : false,
			timerID       : 0,
		},
		signTransaction: tronDefaultSignTransaction,
	},
	UI: {
		statusPhoneVerification: Statuses.none,
		statusEmailVerification: Statuses.none,
		emailResendTime        : 0,
		error                  : false,
		pendingLogout          : false,
		forgetLoading          : false,
		loading                : false,
	},
};

const profileSlice = createSlice({
	name    : 'profile',
	initialState,
	reducers: {
		setProfileData(state, action: PayloadAction<IProfileData>) {
			state.profileData = action.payload;
			state.userLang = action.payload.language_id;
		},
		setProfileDocs(state, action: PayloadAction<IProfileDoc[]>) {
			state.profileDocs = action.payload;
		},
		setToken(state, action: PayloadAction<string>) {
			state.authToken = action.payload;
		},
		pendingLogout(state, action: PayloadAction<boolean>) {
			state.pendingLogout = action.payload;
		},
		setScrollIntoView(state, action: PayloadAction<boolean>) {
			state.scrollIntoView = action.payload;
		},
		setProfileEditStatus(state, action: PayloadAction<IProfileEditStatus>) {
			state.profileEditStatus = action.payload;
		},
		profileDocBinaryStatus(state, action: PayloadAction<IProfileDocBinaryStatus>) {
			state.profileDocBinaryStatus = action.payload;
		},
		setMessages(state, action: PayloadAction<IMessages[]>) {
			state.messages = action.payload;
			state.hasMore = action.payload.length > 0;
		},
		profileReset() {
			return initialState;
		},
		signOut() {
			return initialState;
		},
		unclaimedBonusCountRefresh(state, action: PayloadAction<number>) {
			state.profileData.unclaimed_bonus_count = action.payload;
		},
		updateBalance(state, action: PayloadAction<number>) {
			state.profileData.balance = action.payload;
		},
		setProfileBonusBalance(state, action: PayloadAction<number>) {
			state.profileData.bonus_balance = action.payload;
		},
		setUnreadInboxCount(state, action: PayloadAction<number>) {
			state.profileData.unread_inbox_count = action.payload;
		},
		deleteBonusCount(state) {
			if (state.profileData.unclaimed_bonus_count) {
				state.profileData.unclaimed_bonus_count -= 1;
			}
		},
		setLetterRead(state, action: PayloadAction<{ letterId: number}>) {
			const index = state.messages.findIndex((element) => element.id === action.payload.letterId);
			if (index !== -1) {
				state.messages[index].messages[0].seen_by_user = true;
			}
		},
		openDocument(state, action: PayloadAction<boolean>) {
			state.open_document = action.payload;
		},
		setVerificationStatuses(state, action: PayloadAction<Partial<IProfileUI>>) {
			state.UI = { ...state.UI, ...action.payload };
		},
		setTronlinkParams(state, action: PayloadAction<ITronlinkParams>) {
			state.tronlink.params = action.payload;
		},
		setTronlinkSignParams(state, action: PayloadAction<Partial<ITronlinkSignParams>>) {
			state.tronlink.signParams = { ...state.tronlink.signParams, ...action.payload };
		},
		setTronlinkUI(state, action: PayloadAction<Partial<ITronlinkUI>>) {
			state.tronlink.UI = { ...state.tronlink.UI, ...action.payload };
		},
		UIRefresh(state, action: PayloadAction<Partial<IProfileUI>>) {
			state.UI = { ...state.UI, ...action.payload };
		},
		setPasswordReseted(state, action: PayloadAction<boolean>) {
			state.passwordReseted = action.payload;
		},
	},
});


export const profileReducers = {
	...profileSlice.actions,
};

export default profileSlice.reducer;
