import { invert } from 'lodash';

export const PAYMENT_TYPES = {
	wallet: 'wallet',
	card  : 'card',
	phone : 'phone',
};

export const PAYMENT_PROVIDERS = {
	interkassa                          : 1,
	paymega                             : 2,
	skrill                              : 3,
	paymega_privat24                    : 4,
	skrill_creditCard                   : 5,
	skrill_visa                         : 6,
	skrill_masterCard                   : 7,
	skrill_americanExpress              : 8,
	skrill_neteller                     : 9,
	winPay                              : 10,
	cardpay                             : 11,
	neteller                            : 12,
	winPay_visa_or_masterCard_ru        : 13,
	winPay_visa_or_masterCard_crd_or_p2p: 14,
	winPay_replenishment_of_world_card  : 15,
	winPay_visa_or_masterCard_world     : 16,
	winPay_visa_or_masterCard_ua        : 17,
	winPay_visa_or_masterCard_kz        : 18,
	winPay_webmoney_r                   : 20,
	winPay_yandex_money                 : 21,
	winPay_qiwi_r                       : 22,
	winPay_qiwi_visa_wallet             : 23,
	winPay_mtc_ru                       : 24,
	winPay_megafon_ru                   : 25,
	winPay_beeline_ru                   : 26,
	winPay_tele2_ru                     : 27,
	winPay_motive_ru                    : 28,

	accentPay            : 60,
	accentPayAtf24       : 64,
	accentPayJetonWallet : 77,
	accentPayKassa24     : 78,
	qiwi                 : 85,
	accentPayYandexMoney : 95,
	accentPayJetonVoucher: 96,

	cardPayQIWI                 : 97,
	cardPayYandexMoney          : 98,
	cardPayAlipay               : 99,
	cardPayAgriculturalBankChina: 100,
	cardPayBankChina            : 101,
	cardPayBankCommunications   : 102,
	cardPayCiticBank            : 103,
	cardPayConstructionBank     : 104,
	cardPayEverbrightBank       : 105,
	cardPayMerchantsBank        : 106,
	cardPayMinshengBank         : 107,
	cardPayPostalSavingsBank    : 108,
	cardPayGuangfaBank          : 109,
	cardPayICBCChina            : 110,
	cardPayIndustrialBank       : 111,
	cardPayPingAnBank           : 112,
	cardPayShanghaiPudongDevBank: 113,
	cardPayBankCentralAsia      : 114,
	cardPayBankDanamon          : 115,
	cardPayBankMandiri          : 116,
	cardPayBankNegaraIndonesia  : 117,
	cardPayBankTabunganNegara   : 118,
	cardPayPermataBank          : 119,
	cardPayBankRakyat           : 120,
	cardPayCIMBBank             : 121,
	cardPayHongLeongBank        : 122,
	cardPayMaybank              : 123,
	cardPayPublicBankBerhad     : 124,
	cardPayRHBBank              : 125,
	cardPayUnitedOverseasBank   : 126,
	cardPayBangkokBank          : 127,
	cardPayBankAyudhya          : 128,
	cardPayKasikornBank         : 129,
	cardPayKrungThaiBank        : 130,
	cardPaySiamCommercialBank   : 131,
	cardPayAsiaCommercialBank   : 132,
	cardPayBIDVBank             : 133,
	cardPayDongABank            : 134,
	cardPayEximBank             : 135,
	cardPaySacomBank            : 136,
	cardPayTechcomBank          : 137,
	cardPayVietcomBank          : 138,
	cardPayVietinBank           : 139,
	cardPayRapiPago             : 140,
	cardPayBoleto               : 141,
	cardPayBradesco             : 142,
	cardPayBancoBrasil          : 143,
	cardPayCaixa                : 144,
	cardPaySantander            : 145,
	cardPayWebPay               : 146,
	cardPayBancoChile           : 147,
	cardPayServipag             : 148,
	cardPayITAU                 : 149,
	cardPayCorpbanca            : 150,
	cardPayBCITBANC             : 151,
	cardPayBancoFalabella       : 152,
	cardPayBancoEstado          : 153,
	cardPayBancoBice            : 154,
	cardPayBancoSecurity        : 155,
	cardPayBancoConsorcio       : 156,
	cardPayBancoRipley          : 157,
	cardPayScotiaBank           : 158,
	cardPayCoopeuch             : 159,
	cardPayBancoDeFalabella     : 160,
	cardPayEfecty               : 161,
	cardPayDavivienda           : 162,
	cardPayPSE                  : 163,
	cardPayAlmacenesExito       : 164,
	cardPayBaloto               : 165,
	cardPayCarulla              : 166,
	cardPayEmpresaEnergi        : 167,
	cardPaySurtimax             : 168,
	cardPayBoletoBancolombia    : 169,
	cardPayBancoAvVillas        : 170,
	cardPayBancoCajaSocial      : 171,
	cardPayBancoColpatria       : 172,
	cardPayBancoDeBogota        : 173,
	cardPayBancoDeOccidente     : 174,
	cardPayBancoGNBSudameris    : 175,
	cardPayBancoPichincha       : 176,
	cardPayBancoPopular         : 177,
	cardPayBancoProcredit       : 178,
	cardPayBancolombia          : 179,
	cardPayBancoomeva           : 180,
	cardPayBBVAColombia         : 181,
	cardPayOXXO                 : 182,
	cardPaySPEI                 : 183,
	cardPayBBVABancomer         : 184,
	cardPayBanamex              : 185,
	cardPayPagoEfectivo         : 186,
	cardPayBCP                  : 187,
	cardPayInterbank            : 188,
	cardPayBBVA                 : 189,
	cardPayRedPagos             : 190,
	cardPayBitcoin              : 191,
	cardPayNeteller             : 192,
	cardPayOnlinePay            : 193,
	cardPayWebMoney             : 194,
	cardPayBankCard             : 195,

	accentPayCashIn       : 196,
	accentPayPromsvyazbank: 197,
	accentPayAlfaClick    : 198,

	gmblWebMoneyZ          : 199,
	gmblWebMoneyR          : 200,
	gmblWebMoneyE          : 201,
	gmblCard               : 202,
	gmblQiWi               : 203,
	gmblYandex             : 204,
	gmblWebMoneyWMRWithdraw: 205,
	gmblMTC                : 206,
	gmblWarface            : 207,
	gmblWebMoneyWMZWithdraw: 208,
	gmblCardWithdraw       : 209,

	interkassaPerfectMoneyUSD   : 210,
	interkassaPerfectMoneyEUR   : 211,
	interkassaAdvCashRUB        : 212,
	interkassaAdvCashUSD        : 213,
	interkassaAdvCashEUR        : 214,
	interkassaAdvCashUAH        : 215,
	interkassaExmoAdvCashRUB    : 216,
	interkassaExmoAdvCashUSD    : 217,
	interkassaExmoAdvCashEUR    : 218,
	interkassaExmoAdvCashUAH    : 219,
	interkassaBTCashAdvCashRUB  : 220,
	interkassaBTCashAdvCashUSD  : 221,
	interkassaBTCashAdvCashEUR  : 222,
	interkassaLitecoinAdvCashRUB: 223,
	interkassaLitecoinAdvCashUSD: 224,
	interkassaLitecoinAdvCashEUR: 225,
	interkassaQiwiRUB           : 226,
	interkassaQiwiTerminalRUB   : 227,
	interkassaSvyaznoyRUB       : 228,
	interkassaAlfaClickRUB      : 229,
	interkassaYandexMoneyRUB    : 230,
	interkassaMasterCardUAH     : 231,
	interkassaVisaUAH           : 232,
	interkassaWorldTterminalRUB : 233,
	interkassaMTSRUB            : 234,
	interkassaMegafonRUB        : 235,
	interkassaBeelineRUB        : 236,
	interkassaTele2RUB          : 237,
	interkassaNixMoneyUSD       : 238,
	interkassaNixMoneyEUR       : 239,

	mercuryo                                  : 240,
	paymega_v2                                : 242,
	royalPay                                  : 249,
	// payeer                               : 250,
	piastrixWalletKzt                         : 251,
	piastrixWalletRub                         : 252,
	piastrixWalletUsd                         : 253,
	piastrixWalletEur                         : 254,
	piastrixVisaMcUah                         : 255,
	muchBetter                                : 256,
	apiPay                                    : 257,
	cryptoprocessing                          : 258,
	appex                                     : 259,
	//maldoPay
	maldoPayDeposit                           : 260,
	maldoPayBank                              : 261,
	maldoPayPapara                            : 262,
	maldoPayPaparaPayDeposit                  : 263,
	maldoPayPayKwikPrepaid                    : 264,
	maldoPayPayFix                            : 265,
	maldoPayMefete                            : 266,
	maldoPayAstroPayDeposit                   : 267,
	maldoPayAstroPayWithdraw                  : 268,
	maldoPayCepBankDeposit                    : 269,
	maldoPayCommunityBankingDeposit           : 270,
	maldoPayAnindaHavaleDeposit               : 271,
	maldoPayMkareKodQrCodeDeposit             : 272,
	maldoPayFaturaDeposit                     : 273,
	jetonCashDeposit                          : 274,
	jetonWalletManuelBankTransfer             : 275,
	jetonWalletP24                            : 276,
	jetonCashWithdrawal                       : 277,
	interkassaInpsCpaytrzMerchantupiInr       : 278,
	interkassaInpsCpaytrzMerchantwalletInr    : 279,
	interkassaInpsCpaytrzMerchantnetbankingInr: 280,
	interkassaCardCpaytrzMerchantInr          : 281,
	interkassaMasterCardEUR                   : 282,
	interkassaMasterCardRUB                   : 283,
	piastrixVisaMcRub                         : 284,
	interkassaInpsCpaytrzTransferBankInr      : 285,
	interkassaInpsCpaytrzTransferUpiInr       : 286,
	tronlink                                  : 287,
	mutchBetterGateway                  						: 288,
};

export const PAYMENT_PROVIDERS_INVERTED = invert(PAYMENT_PROVIDERS);
export const PAYMENT_PROVIDERS_TYPE = {
	[PAYMENT_PROVIDERS.winPay_webmoney_r]                   : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.winPay_yandex_money]                 : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.winPay_qiwi_r]                       : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.winPay_qiwi_visa_wallet]             : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.winPay_mtc_ru]                       : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.winPay_megafon_ru]                   : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.winPay_beeline_ru]                   : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.winPay_tele2_ru]                     : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.winPay_motive_ru]                    : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.winPay_visa_or_masterCard_ru]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.winPay_visa_or_masterCard_crd_or_p2p]: PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.winPay_replenishment_of_world_card]  : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.winPay_visa_or_masterCard_world]     : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.winPay_visa_or_masterCard_ua]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.winPay_visa_or_masterCard_kz]        : PAYMENT_TYPES.card,

	[PAYMENT_PROVIDERS.accentPay]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.accentPayAtf24]        : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.accentPayKassa24]      : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.accentPayJetonWallet]  : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.accentPayJetonVoucher] : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.accentPayYandexMoney]  : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.accentPayCashIn]       : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.accentPayPromsvyazbank]: PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.accentPayAlfaClick]    : PAYMENT_TYPES.card,

	[PAYMENT_PROVIDERS.cardpay]                     : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayQIWI]                 : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayYandexMoney]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayAlipay]               : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayAgriculturalBankChina]: PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankChina]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankCommunications]   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayCiticBank]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayConstructionBank]     : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayEverbrightBank]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayMerchantsBank]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayMinshengBank]         : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayPostalSavingsBank]    : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayGuangfaBank]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayICBCChina]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayIndustrialBank]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayPingAnBank]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayShanghaiPudongDevBank]: PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankCentralAsia]      : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankDanamon]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankMandiri]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankNegaraIndonesia]  : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankTabunganNegara]   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayPermataBank]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankRakyat]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayCIMBBank]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayHongLeongBank]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayMaybank]              : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayPublicBankBerhad]     : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayRHBBank]              : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayUnitedOverseasBank]   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBangkokBank]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankAyudhya]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayKasikornBank]         : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayKrungThaiBank]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPaySiamCommercialBank]   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayAsiaCommercialBank]   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBIDVBank]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayDongABank]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayEximBank]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPaySacomBank]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayTechcomBank]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayVietcomBank]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayVietinBank]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayRapiPago]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBoleto]               : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBradesco]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoBrasil]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayCaixa]                : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPaySantander]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayWebPay]               : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoChile]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayServipag]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayITAU]                 : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayCorpbanca]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBCITBANC]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoFalabella]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoEstado]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoBice]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoSecurity]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoConsorcio]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoRipley]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayScotiaBank]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayCoopeuch]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoDeFalabella]     : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayEfecty]               : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayDavivienda]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayPSE]                  : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayAlmacenesExito]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBaloto]               : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayCarulla]              : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayEmpresaEnergi]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPaySurtimax]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBoletoBancolombia]    : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoAvVillas]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoCajaSocial]      : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoColpatria]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoDeBogota]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoDeOccidente]     : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoGNBSudameris]    : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoPichincha]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoPopular]         : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoProcredit]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancolombia]          : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBancoomeva]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBBVAColombia]         : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayOXXO]                 : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPaySPEI]                 : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBBVABancomer]         : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBanamex]              : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayPagoEfectivo]         : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBCP]                  : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayInterbank]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBBVA]                 : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayRedPagos]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBitcoin]              : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayNeteller]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayOnlinePay]            : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayWebMoney]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cardPayBankCard]             : PAYMENT_TYPES.card,

	[PAYMENT_PROVIDERS.gmblWebMoneyZ]          : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.gmblWebMoneyR]          : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.gmblWebMoneyE]          : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.gmblCard]               : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.gmblQiWi]               : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.gmblYandex]             : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.gmblWebMoneyWMRWithdraw]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.gmblMTC]                : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.gmblWarface]            : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.gmblWebMoneyWMZWithdraw]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.gmblCardWithdraw]       : PAYMENT_TYPES.card,

	[PAYMENT_PROVIDERS.interkassaPerfectMoneyUSD]   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaPerfectMoneyEUR]   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaAdvCashRUB]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaAdvCashUSD]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaAdvCashEUR]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaAdvCashUAH]        : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaExmoAdvCashRUB]    : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaExmoAdvCashUSD]    : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaExmoAdvCashEUR]    : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaExmoAdvCashUAH]    : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaBTCashAdvCashRUB]  : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaBTCashAdvCashUSD]  : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaBTCashAdvCashEUR]  : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaLitecoinAdvCashRUB]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.interkassaLitecoinAdvCashUSD]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.interkassaLitecoinAdvCashEUR]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.interkassaQiwiRUB]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaQiwiTerminalRUB]   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaSvyaznoyRUB]       : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.interkassaAlfaClickRUB]      : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaYandexMoneyRUB]    : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.interkassaMasterCardUAH]     : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaVisaUAH]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaWorldTterminalRUB] : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaMTSRUB]            : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.interkassaMegafonRUB]        : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.interkassaBeelineRUB]        : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.interkassaTele2RUB]          : PAYMENT_TYPES.phone,
	[PAYMENT_PROVIDERS.interkassaNixMoneyUSD]       : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaNixMoneyEUR]       : PAYMENT_TYPES.card,

	[PAYMENT_PROVIDERS.mercuryo]         : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.royalPay]         : PAYMENT_TYPES.card,
	// [PAYMENT_PROVIDERS.payeer]                                       : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.piastrixWalletKzt]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.piastrixWalletRub]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.piastrixWalletUsd]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.piastrixWalletEur]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.piastrixVisaMcUah]: PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.muchBetter]       : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.apiPay]           : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.cryptoprocessing] : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.appex]            : PAYMENT_TYPES.card,

	//maldoPay
	[PAYMENT_PROVIDERS.maldoPayDeposit]                           : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayBank]                              : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayPapara]                            : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayPaparaPayDeposit]                  : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayPayKwikPrepaid]                    : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayPayFix]                            : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayMefete]                            : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayAstroPayDeposit]                   : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayAstroPayWithdraw]                  : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayCepBankDeposit]                    : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayCommunityBankingDeposit]           : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayAnindaHavaleDeposit]               : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayMkareKodQrCodeDeposit]             : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.maldoPayFaturaDeposit]                     : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.jetonCashDeposit]                          : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.jetonWalletManuelBankTransfer]             : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.jetonWalletP24]                            : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.jetonCashWithdrawal]                       : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.interkassaInpsCpaytrzMerchantupiInr]       : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.interkassaInpsCpaytrzMerchantwalletInr]    : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.interkassaInpsCpaytrzMerchantnetbankingInr]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.neteller]                               	  : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.skrill]                                 	  : PAYMENT_TYPES.wallet,

	[PAYMENT_PROVIDERS.interkassaCardCpaytrzMerchantInr]    : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaMasterCardEUR]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaMasterCardRUB]             : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.piastrixVisaMcRub]                   : PAYMENT_TYPES.card,
	[PAYMENT_PROVIDERS.interkassaInpsCpaytrzTransferBankInr]: PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.interkassaInpsCpaytrzTransferUpiInr] : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.tronlink]                            : PAYMENT_TYPES.wallet,
	[PAYMENT_PROVIDERS.mutchBetterGateway]                  : PAYMENT_TYPES.wallet,
};


export 	const walletOptions = [
	{ label: 'PAYTM_WALLET', value: 101 },
	{ label: 'MOBIKWIK_WALLET', value: 102 },
	{ label: 'AIRTEL_MONEY_WALLET', value: 103 },
	{ label: 'RELIANCE_JIO_WALLET', value: 106 },
	{ label: 'OLA_MONEY_WALLET', value: 107 },
	{ label: 'FREECHARGE_WALLET', value: 113 },
	{ label: 'PHONEPE_WALLET', value: 115 },
];

export const bankOptions = [
	{ label: 'BANK_OF_BARODA_RETAIL_ACCOUNTS', value: 1093 },  // INACTIVE FROM PAYMENT SYSTEM
	{ label: 'Bank Of India', value: 1009 },
	{ label: 'Bank Of Maharashtra', value: 1064 },
	{ label: 'Canara Bank', value: 1055 },
	{ label: 'Central Bank Of India', value: 1063 },
	{ label: 'City Union Bank', value: 1060 },
	{ label: 'COSMOS Bank', value: 1104 },
	{ label: 'Deutsche Bank', value: 1026 },
	{ label: 'Development Credit Bank', value: 1040 },
	{ label: 'DHANALAXMI BANK', value: 1107 },
	{ label: 'DHANALAXMI BANK CORPORATE', value: 1126 },  // INACTIVE FROM PAYMENT SYSTEM
	{ label: 'FEDERAL BANK', value: 1027 },
	{ label: 'HDFC Bank', value: 1004 },
	{ label: 'ICICI Bank', value: 1013 },
	{ label: 'IDFC BANK', value: 1120 },
	{ label: 'INDIAN BANK', value: 1069 },
	{ label: 'Indian Overseas Bank', value: 1049 },
	{ label: 'IndusInd Bank', value: 1054 },
	{ label: 'Industrial Development Bank Of India', value: 1003 },
	{ label: 'Jammu And Kashmir Bank', value: 1041 },
	{ label: 'Karnatka Bank Ltd', value: 1032 },
	{ label: 'KarurVysya Bank', value: 1048 },
	{ label: 'Kotak Bank', value: 1012 },
	{ label: 'Lakshmi Vilas Bank NetBanking', value: 1095 },
	{ label: 'Laxmi Vilas Bank - Corporate Net Banking', value: 1128 },  // INACTIVE FROM PAYMENT SYSTEM
	{ label: 'PUNJAB AND SIND BANK', value: 1111 },
	{ label: 'Punjab National Bank', value: 1129 },
	{ label: 'SHAMRAO VITHAL COOPERATIVE BANK', value: 1113 },
	{ label: 'South Indian Bank', value: 1045 },
	{ label: 'Standard Chartered Bank', value: 1097 },  // INACTIVE FROM PAYMENT SYSTEM
	{ label: 'State Bank Of India', value: 1030 },
	{ label: 'Tamilnad Mercantile Bank', value: 1065 },
	{ label: 'UCO Bank', value: 1103 },
	{ label: 'UNION_BANK_OF_INDIA', value: 1038 },  // INACTIVE FROM PAYMENT SYSTEM
	{ label: 'Yes Bank', value: 1001 },
];
