import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { menuReducers } from '../../../redux/menu/slice';
import NotificationsList from '../notifications-list';
import { IPropsNotificationMobile } from './types';
import './notification-mobile.scss';

const {
	setBottomMenuUI,
} = menuReducers;

const NotificationMobile: FC<IPropsNotificationMobile> = ({ visible }) => {
	const [firstOpen, setFirstOpen] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (visible) {
			document.body.style.overflow = 'hidden';
			setFirstOpen(true);
		} else {
			document.body.style.overflow = '';
		}

		return () => {
			if (visible) {
				dispatch(setBottomMenuUI({ notificationVisible: false }));
			}
		};
	}, [dispatch, visible]);

	return (
		<div className={`notification-mobile-container ${visible ? 'open' : !firstOpen ? '' : 'close'}`}>
			<NotificationsList/>
		</div>
	);
};

export default NotificationMobile;