import { combineReducers } from 'redux';
import Settings from './settings/slice';
import Profile from './profile/slice';
import Bonuses from './bonus/slice';
import Games from './games/slices';
import History from './history/slice';
import Menu from './menu/slice';
import Modals from './modals/slice';
import Balance from './balance/slice';
import Deposit from './deposit/slice';
import RGamblingSlice from './gambling/slice';
import Promotion from './promotion/slice';
import Notifications from './notifications/slice';
import Help from './help/slice';
import Socket from './socket/slice';
import Tournaments from './tournaments/reducers';
import Cookies from './cookie/slice';
import CustomHistory from './router/slice';
import SportsBook from './sports-book/slice';
import Withdraw from './withdraw/slice';
import Banners from './banners/slice';
import TwoFA from  './2FA/slice';
import BalloonNotification from './balloon-notification/slice';
import Winners from  './winners/slice';

const reducers = {
	Settings,
	Profile,
	Bonuses,
	Games,
	History,
	Menu,
	Modals,
	Balance,
	Deposit,
	Withdraw,
	RGamblingSlice,
	Promotion,
	Notifications,
	Help,
	Socket,
	Cookies,
	Tournaments,
	CustomHistory,
	SportsBook,
	Banners,
	TwoFA,
	BalloonNotification,
	Winners,
};

const combinedReducers = combineReducers(reducers);

export default combinedReducers;
