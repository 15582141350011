import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { BANNER_GROUPS, IBannerPayload, IBannersInitialState } from './types';

const initState: IBannersInitialState = {
	[BANNER_GROUPS.footerApps]     : [],
	[BANNER_GROUPS.footerCompanies]: [],
	[BANNER_GROUPS.footerPayments] : [],
	[BANNER_GROUPS.footerProviders]: [],
	[BANNER_GROUPS.footerSocial]   : [],
	[BANNER_GROUPS.lumos]          : [],
	[BANNER_GROUPS.sidebarBlog]    : [],
	[BANNER_GROUPS.gameList]       : [],
	[BANNER_GROUPS.promotions]     : [],
};

const banners = createSlice({
	name        : 'banners',
	initialState: initState,
	reducers    : {
		setBanners(state: Draft<IBannersInitialState>, action: PayloadAction<IBannerPayload>) {
			const section = action.payload.section;
			state[section] = action.payload.data;
		},
	},
});

export const bannerReducers = {
	setBanners: banners.actions.setBanners,
};

export default banners.reducer;