import { restoreLocale } from '../helpers/localStorageUtils';
import { createIntl } from 'react-intl';
import translations from './index';
import { ELanguageCodes } from './types';
import { defaultLanguage } from '../config';

const locale = restoreLocale();

const langCode = locale?.code || defaultLanguage.code;
const messages = translations[langCode as ELanguageCodes];
const intl = createIntl({ locale: langCode, messages });


export const translate = (key: string): string => {
	return intl.formatMessage({ id: key });
};