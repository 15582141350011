import { apiRequest } from '../../service/service';
import { IGetTronFeePayload, IWithdrawBaseData } from './types';
import { PartialConfigs } from '../../service/types';

class WithdrawAPI {
	doWithdraw = (data: Partial<IWithdrawBaseData>, method: number) => {
		const url = `/v1/payments/${method}/withdraw`;
		const config: PartialConfigs<Partial<IWithdrawBaseData>> = {
			method: 'post',
			url   : url,
			data  : data,
		};
		const result  = apiRequest(config);
		return result;
	};
	getWithdrawMethods = () => {
		const url = '/v1/profile/withdraw/payways';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};
	getCards = (method_id: number) => {
		const url = `v1/profile/payment/${method_id}/cards`;
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};

	getFeeTronlink = (params: IGetTronFeePayload) => {
		const url =  'v1/payments/tronlink/calculate_fee';
		const config: PartialConfigs<void,IGetTronFeePayload> = {
			method: 'get',
			url   : url,
			params,
		};
		const result  = apiRequest(config);
		return result;
	};
}

export default WithdrawAPI;
