import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	IBaseError,
	IDepositBaseData,
	IDepositMethods,
	IPaymentMethod,
	IState,
	ITempTronAccount,
	IUI,
	PaymentTypes,
} from './types';
import { PickOne } from '../../components/deposit/types';
import { cloneDeep } from 'lodash';
import { ITronAccountEntities } from '../profile/types';


const initialState: IState = {
	baseData: {
		amount      : '',
		payment_type: PaymentTypes.card,
	},
	baseError: {
		phone     : '',
		service_id: '',
		upi_number: '',
		amount    : '',
		email     : '',
	},
	depositMethods: {},
	success       : {
		showSuccess: false,
		successMsg : '',
	},
	tempTronAccount: {
		id        : 1,
		account   : '',
		name      : '',
		saveMethod: true,
		open      : false,
		temp      : true,
		amount    : {
			value: '',
			error: '',
		},
		loading: false,
	},
	tronAccounts: {},
	UI          : {
		loading        : false,
		markAllRead    : false,
		favouriteAmount: null,
		listLoading    : false,
	},
	payment_method: null,
};

const depositSlice = createSlice({
	name    : 'deposit',
	initialState,
	reducers: {
		setDepositMethods: (state: Draft<IState>, action: PayloadAction<IDepositMethods>) => {
			state.depositMethods = action.payload;
		},
		selectPaymentMethod: (state: Draft<IState>, action: PayloadAction<IPaymentMethod | null>) => {
			state.payment_method = action.payload;
		},
		depositUIRefresh: (state: Draft<IState>, action: PayloadAction<Partial<IUI>>) => {
			const source = state.UI;
			const target = action.payload;
			state.UI = { ...source, ...target };
		},
		UIRefresh: (state: Draft<IState>, action: PayloadAction<PickOne<IUI>>) => {
			state.UI = { ...state.UI, ...action.payload };
		},
		accountDataRefresh: (state: Draft<IState>, action: PayloadAction<ITronAccountEntities>) => {
			state.tronAccounts = action.payload;
		},
		tempAccountRefresh: (state: Draft<IState>, action: PayloadAction<ITempTronAccount>) => {
			state.tempTronAccount = action.payload;
		},
		baseDataRefresh: (state: Draft<IState>, action: PayloadAction<Partial<IDepositBaseData>>) => {
			const clonedData = cloneDeep(state.baseData);
			state.baseData = Object.assign({}, clonedData, action.payload);
		},
		baseErrorRefresh: (state: Draft<IState>, action: PayloadAction<Partial<IBaseError>>) => {
			const clonedData = cloneDeep(state.baseError);
			state.baseError = Object.assign({}, clonedData, action.payload);
		},
		resetBaseErrors: (state: Draft<IState>) => {
			state.baseError = cloneDeep(initialState.baseError);
		},
		resetBaseData: (state: Draft<IState>) => {
			const clonedData = cloneDeep(initialState.baseData);
			state.baseData = clonedData;

		},
	},
});

export const depositReducers = {
	...depositSlice.actions,
} ;

export default depositSlice.reducer;
