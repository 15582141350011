import { isNull } from 'lodash';
import { PAYMENT_PROVIDERS, PAYMENT_PROVIDERS_TYPE, PAYMENT_TYPES } from '../../constants/payments';
import { IDepositBaseData, IPaymentMethod, TErrorTuple } from './types';
import { PaymentFieldNames } from '../../components/withdraw/components/PaymentMethodFields/types';
import { isDefinedNullOrZero } from '../../helpers/utils';

export const TronTransactionLSKey = 'TronTrxID';

export const validateDeposit = (baseData: Partial<IDepositBaseData>, depositMethod: IPaymentMethod | null) => {
	if (!depositMethod) {
		return [];
	}
	const { payment_id } = depositMethod;

	const paymentID = Number(payment_id);
	// At first we check payment ID (tronlink, netellar etc.)
	// and later payment type (wallet, card, phone)
	switch (paymentID) {
		case PAYMENT_PROVIDERS.tronlink: {
			return validateTronData(baseData, depositMethod);
		}
		default: {
			// add other cases if there are exceptions like above case(s)
			break;
		}
	}

	if (PAYMENT_PROVIDERS_TYPE[paymentID] === PAYMENT_TYPES.card ) {
		return validateCardFields(baseData, depositMethod);
	}

	if (PAYMENT_PROVIDERS_TYPE[paymentID] === PAYMENT_TYPES.phone ) {
		return validatePhoneFields(baseData, depositMethod);
	}

	if (PAYMENT_PROVIDERS_TYPE[paymentID] === PAYMENT_TYPES.wallet ) {
		return validateWalletFields(baseData, depositMethod);
	}

	return  [];


};

const requiredFields = {
	[PAYMENT_PROVIDERS.tronlink]: [PaymentFieldNames.amount],
	defaultCard                 : [PaymentFieldNames.amount],
	defaultPhone                : [PaymentFieldNames.amount, PaymentFieldNames.phone],
	defaultWallet               : [PaymentFieldNames.amount],
};

export enum ErrorTypes  {
	minMax   = 'min_max_error',
	required = 'required_error',
	valueZero= 'zero_error',
}

export const generateErrorMap = (errors: string[][] = [[]]) => {
	const map: Record<string, string> = {};

	errors.forEach(error => {
		const [errorKey, errorType] = error;
		map[errorKey] = errorType;
	});
	return map;
};


const validateMinMax = (baseData: Partial<IDepositBaseData>, paymentMethod: IPaymentMethod): TErrorTuple[] | null => {
	const fields: TErrorTuple[] = [];
	const { max_deposit, min_deposit } = paymentMethod;
	const amount = Number(baseData[PaymentFieldNames.amount]);

	if (isDefinedNullOrZero(min_deposit) && isDefinedNullOrZero(max_deposit)) {
		return null; // No need to validate if both min and max are null or 0
	}

	if ((!isDefinedNullOrZero(max_deposit) && amount > max_deposit) || (!isNull(min_deposit) && amount < min_deposit)) {
		fields.push([PaymentFieldNames.amount, ErrorTypes.minMax]);
	}

	return fields.length ? fields : null;
};

const validateZero = (baseData: Partial<IDepositBaseData>): TErrorTuple[] | null => {
	const fields: TErrorTuple[] = [];
	const amount = Number(baseData[PaymentFieldNames.amount]);

	if (amount === 0) {
		fields.push([PaymentFieldNames.amount, ErrorTypes.valueZero]);
	}

	return fields.length ? fields : null;
};


const validateTronData = (baseData: Partial<IDepositBaseData>, paymentMethod: IPaymentMethod) => {
	const fields: TErrorTuple[] = [];
	requiredFields[PAYMENT_PROVIDERS.tronlink].forEach(field => {
		if (!baseData[field]) {
			fields.push([field, ErrorTypes.required]);
		}
	});

	const validZero = validateZero(baseData);
	if (validZero) {
		fields.push(...validZero);
	}

	const validMinMax = validateMinMax(baseData, paymentMethod);
	if (validMinMax) {
		fields.push(...validMinMax);
	}

	return fields;
};


const validateWalletFields = (baseData: Partial<IDepositBaseData>, paymentMethod: IPaymentMethod) => {
	const fields: TErrorTuple[] = [];

	if (paymentMethod.payment_id === PAYMENT_PROVIDERS.neteller) {
		if (!requiredFields.defaultWallet.includes(PaymentFieldNames.email)) {
			requiredFields.defaultWallet.push(PaymentFieldNames.email);
		}
	} else {
		requiredFields.defaultWallet = requiredFields.defaultWallet.filter(item => item !== PaymentFieldNames.email);
	}
	requiredFields.defaultWallet.forEach(field => {
		if (!baseData[field] ) {
			fields.push([field, ErrorTypes.required ]);
		}
	});

	const validZero = validateZero(baseData);
	if (validZero) {
		fields.push(...validZero);
	}

	const validMinMax = validateMinMax(baseData, paymentMethod);
	if (validMinMax) {
		fields.push(...validMinMax);
	}
	return fields;
};

const validatePhoneFields = (baseData: Partial<IDepositBaseData>, paymentMethod: IPaymentMethod) => {
	const fields: TErrorTuple[] = [];
	requiredFields.defaultPhone.forEach(field => {
		if (!baseData[field]) {
			fields.push([field, ErrorTypes.required ]);
		}
	});

	const validZero = validateZero(baseData);
	if (validZero) {
		fields.push(...validZero);
	}

	const validMinMax = validateMinMax(baseData, paymentMethod);
	if (validMinMax) {
		fields.push(...validMinMax);
	}

	return fields;
};

const validateCardFields = (baseData: Partial<IDepositBaseData>, paymentMethod: IPaymentMethod ) => {
	const fields = [];
	requiredFields.defaultCard.forEach(field => {
		if (!baseData[field]) {
			fields.push([field, ErrorTypes.required ]);
		}
	});

	const validZero = validateZero(baseData);
	if (validZero) {
		fields.push(...validZero);
	}

	const validMinMax = validateMinMax(baseData, paymentMethod);
	if (validMinMax) {
		fields.push(...validMinMax);
	}

	return fields;
};
