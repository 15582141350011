import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ClickAwayListener, Popper } from '@mui/material';
import Button from '@mui/material/Button';
import { notificationReducers } from '../../../redux/notifications/slice';
import { IDropDown } from './types';

import './dropdown.scss';

const {
	setUI,
} = notificationReducers;
const DropDown: FC<IDropDown> = ({
	notificationDropDown = false,
	dropDownVisible = false,
	btnIcon,
	btnText = '',
	btnClass = null,
	clickAway = true,
	children,
}) => {
	const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
	const dispatch = useDispatch();
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const BtnText = btnText ? <FormattedMessage id={btnText}/> : '';

	const handleClick = (event: SyntheticEvent): void => {
		const anchorElValue = anchorEl ? null : event.currentTarget;

		setAnchorEl(anchorElValue);

		if (notificationDropDown) {
			dispatch(notificationReducers.setUI({ dropDownVisible: true }));
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		dispatch(setUI({ dropDownVisible: false }));
	};

	useEffect(() => {
		if (notificationDropDown && !dropDownVisible) {
			handleClose();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropDownVisible, notificationDropDown]);

	return (
		<div>
			<Button aria-describedby={id} className={`icon_${btnIcon} ${btnClass}`} onClick={handleClick}>
				{BtnText}
			</Button>
			<Popper placement="bottom-start" disablePortal={true} id={id} open={open} anchorEl={anchorEl} className="popper_inner">
				{
					clickAway ?
						<ClickAwayListener onClickAway={handleClose} touchEvent={false}>
							<div>
								{children}
							</div>
						</ClickAwayListener> :
						<div>
							{children}
						</div>
				}
			</Popper>
		</div>
	);
};

export default DropDown;
