import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import api from './api';
import promotionActions from './actions';
import { adaptSeo } from '../settings/utils';
// import settingsActions from '../settings/actions';
import { logger } from '../../helpers/debugLogger';
import { AxiosApiResponse } from '../../service/types';
import { IPromotions } from './types';
import { RootState } from '../store';
import { promotionsReducers } from './slice';
import { ISagaActionType } from '../types';
import { IAdapt } from '../settings/types';
import { settingsReducers } from '../settings/slice';
import { isEmpty } from 'lodash';
// import { settingsReducers } from '../settings/slice';

const API = new api();

const getStoreData = ({ Settings }: RootState) => {
	return {
		lang_id   : Settings.current_language?.id,
		channel_id: Settings.channelID,
	};
};

function* getPromotions() {
	yield takeEvery(promotionActions.GET_PROMOTIONS, function* () {
		const { lang_id, channel_id } = yield select(getStoreData);
		try {
			const params: { lang_id: number; channel_id: number } = {
				lang_id,
				channel_id,
			};
			const response: AxiosApiResponse<IPromotions[]> = yield call(API.getPromotions, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(promotionsReducers.setPromotions(data));
			}
		} catch (e) {
			logger.log(e);
		}
	});

}

function* getPromotionInner() {
	yield takeEvery(promotionActions.GET_PROMOTION_INNER, function* (action: ISagaActionType<string>) {
		const { lang_id, channel_id } = yield select(getStoreData);
		try {
			yield put(promotionsReducers.setPromotionUI({ innerLoading: true }));
			const params: { lang_id: number; alias: string; channel_id: number } = {
				lang_id,
				channel_id,
				alias: action.data,
			};
			const response: AxiosApiResponse<IPromotions> = yield call(API.getPromotionInner, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				const noData = isEmpty(data);
				yield put(promotionsReducers.setPromotionInner(noData ? null : data));
				const { promotion } = data;
				const promotionData:IAdapt = {
					alias: data.alias,
					...promotion,
				};

				const dataSeo = adaptSeo(promotionData);
				yield put(settingsReducers.setSEOData(dataSeo));
			}
		} catch (e) {
			logger.log(e);
		}
		yield put(promotionsReducers.setPromotionUI({ innerLoading: false }));

	});

}


function* promotionSaga() {
	yield all([
		fork(getPromotions),
		fork(getPromotionInner),
	]);
}

export default promotionSaga;
