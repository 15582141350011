import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { AxiosApiResponse } from '../../service/types';
import profileActions from '../../redux/profile/actions';
import { objectCloneDeep } from '../../helpers/utils';
import { logger } from '../../helpers/debugLogger';
import { TWO_FA_TYPES } from '../../components/modals/TwoFaLogin/utils';
import { clearData, restoreReactiveToken, storeAuthToken } from '../../helpers/localStorageUtils';
import { STORAGE_KEYS } from '../../helpers/constants';
import { countResendTime } from '../../components/uiElements/InputWithButton/utils';
import { modalReducers } from '../modals/slice';
import Api from '../profile/api';
import { RootState } from '../store';
import { twoFAActions } from './actions';
import TwoFAApi from './api';
import { twoFactorReducers } from './slice';
import {
	I2FAGetStore,
	IActionsAddValues2FA,
	IActionsConfirm,
	IActionsConfirm2FALogin,
	IActionsDisabled2FA,
	IActionSendSms,
	IConfirmPassParams,
	IResConfirmPass,
	IResSMS2FA,
	ISagaActionConfirmPass,
	ISagaActionSendCodeApp,
	ISendSms,
	ISendSmsReq,
	IValuesMethods2FA,
} from './types';
import { methods2FA } from './utils';
import { toInteger } from 'lodash';
import { ErrorCodes } from '../../errors/ErrorCodes';

const twoFAApi = new TwoFAApi();
const profileApi = new Api();

const messages = {
	twoFAGlobalError: 'SMS_2FA_error',
};

const getStoreData = ({ TwoFA, Profile }: RootState): I2FAGetStore => {
	const { currentType, errorProfileReq } = TwoFA.UI;
	const { time_zone } = Profile.profileData;
	return {
		twoFAMethods: TwoFA.method,
		twoFAData   : TwoFA.baseData,
		typeID      : currentType,
		errorProfileReq,
		time_zone,
	};
};

function* sendSms({ next, pageNumber, typeID, dataReq, isLeft = true }: ISendSms) {
	const { time_zone } = yield select(getStoreData);
	const res: AxiosApiResponse<IResSMS2FA> = yield call(twoFAApi.confirmPass2FA, typeID || TWO_FA_TYPES.none, dataReq);
	const { data } = res.data;
	const { codeExpTimeMin, sendDate } = data;
	const resendTime = countResendTime(sendDate, time_zone, codeExpTimeMin * 60 );

	if (!data.error_code) {
		if (isLeft) {
			next(pageNumber);
		}
		yield put(twoFactorReducers.setUI({ error: false, loading: false, resendTime }));
	} else {
		yield put(twoFactorReducers.setUI({ error: true, loading: false, isAttempt: true }));
	}
}

function* addActiveValue2FA() {
	yield takeEvery(twoFAActions.ADD_VALUES_2FA, function* (action: IActionsAddValues2FA) {
		try {
			const { enable_2fa_app, enable_2fa_sms } = action.data;
			const { twoFAMethods } = yield select(getStoreData);
			const cloneTwoFAMethods = objectCloneDeep(twoFAMethods);
			const methods = methods2FA(cloneTwoFAMethods, enable_2fa_app, enable_2fa_sms);
			yield put(twoFactorReducers.setMethod(methods));
		} catch (e) {
			logger.log(e);
		}
	});
}


function* confirmPass2FA() {
	yield takeEvery(twoFAActions.CONFIRM_PASS_2FA, function* (action: ISagaActionConfirmPass) {
		const { next, pageNumber } = action.data;
		yield put(twoFactorReducers.setUI({ error: false, loading: true }));
		const { twoFAData, typeID }: I2FAGetStore = yield select(getStoreData);
		const dataReq: IConfirmPassParams = {
			password: twoFAData.pass,
			enable  : true,
		};

		try {
			const res: AxiosApiResponse<IResConfirmPass> = yield call(twoFAApi.confirmPass2FA, typeID || TWO_FA_TYPES.none, dataReq);
			if (res.status === 200) {
				const { data } = res.data;
				yield put(twoFactorReducers.setBaseData({ qrHash: data.hash }));
				next(pageNumber);
				yield put(twoFactorReducers.setUI({ loading: false }));
			}
		} catch (e) {
			yield put(twoFactorReducers.setUI({ error: true, loading: false }));
		}
	});
}

function* sendCodeApp2FA() {
	yield takeEvery(twoFAActions.SEND_APP_CODE_2FA, function* (action: ISagaActionSendCodeApp) {
		const { next, pageNumber, code } = action.data;
		yield put(twoFactorReducers.setUI({ error: false, loading: true }));
		const { typeID }: I2FAGetStore = yield select(getStoreData);
		const dataReq = {
			code,
		};

		try {
			const res: AxiosApiResponse<IValuesMethods2FA> = yield call(twoFAApi.sendAppCode2FA, typeID || TWO_FA_TYPES.none, dataReq);
			if (res.status === 200) {
				yield put(profileActions.profileDataReload());
				yield put(twoFactorReducers.setUI({ loading: false, error: false }));
				next(pageNumber);
			}
		} catch (e) {
			yield put(twoFactorReducers.setUI({ error: true, loading: false }));
		}
	});
}
function* send2FASms() {
	yield takeEvery(twoFAActions.SEND_SMS_2FA, function* ({ data }: IActionSendSms) {
		yield put(twoFactorReducers.setUI({ error: false, loading: true }));
		const { dontHavePhone, next, pageNumber, phoneNumber } = data;
		const { twoFAData, typeID, errorProfileReq } = yield select(getStoreData);
		const dataReq:ISendSmsReq = {
			password: twoFAData.pass,
			enable  : true,
		};
		try {
			if (dontHavePhone) {
				const formData: FormData = new FormData();
				formData.append('phone', phoneNumber);
				yield put(profileActions.editProfileData(formData, false, true));
				if (!errorProfileReq) {
					yield call(sendSms, { next, pageNumber, typeID, dataReq });
				}
			} else {
				yield call(sendSms, { next, pageNumber, typeID, dataReq });
			}
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = toInteger(e.message);
				if (errorCode === ErrorCodes.INVALID_PASSWORD) {
					yield put(twoFactorReducers.setUI({ error: true, loading: false }));
				} else {
					yield put(twoFactorReducers.setUI({ error: false, loading: false, errorText: messages.twoFAGlobalError }));
				}
			}
		}
	});
}


function* send2FASmsCode() {
	yield takeEvery(twoFAActions.SEND_SMS_CODE_2FA, function* (action: IActionsConfirm) {
		yield put(twoFactorReducers.setUI({ error: false, loading: true }));
		const { next, pageNumber } = action.data;
		const { twoFAData, typeID }: I2FAGetStore = yield select(getStoreData);
		const dataReq = {
			code: twoFAData.codeSms || '',
		};
		try {
			const res: AxiosApiResponse<IResSMS2FA> = yield call(twoFAApi.sendAppCode2FA, typeID || TWO_FA_TYPES.none, dataReq);
			const { data } = res.data;
			if (!data.error_code) {
				yield put(profileActions.profileDataReload());
				yield put(twoFactorReducers.setUI({ loading: false, error: false }));
				next(pageNumber);
			} else {
				yield put(twoFactorReducers.setUI({
					error    : true,
					loading  : false,
					isAttempt: true,
					attempt  : data.attemptsLimit - data.attempts,
				}));
			}
		} catch (e) {
			yield put(twoFactorReducers.setUI({ error: true, loading: false }));
		}
	});
}

function* resendSms() {
	yield takeEvery(twoFAActions.RESEND_SMS_CODE_2FA, function* (action: IActionsConfirm) {
		yield put(twoFactorReducers.setUI({ error: false, loading: true }));
		const { next, pageNumber } = action.data;
		const { twoFAData, typeID }: I2FAGetStore = yield select(getStoreData);
		const dataReq = {
			password: twoFAData.pass,
			enable  : true,
		};
		try {
			yield call(sendSms, { next, pageNumber, typeID, dataReq, isLeft: false });
		} catch (e) {
			yield put(twoFactorReducers.setUI({ error: true, loading: false }));
		}
	});
}


function* disabled2FA() {
	yield takeEvery(twoFAActions.DISABLED_2FA, function* (action: IActionsDisabled2FA) {
		const { next, pageNumber } = action.data;
		yield put(twoFactorReducers.setUI({ error: false, loading: true }));
		const { twoFAData, typeID = TWO_FA_TYPES.none }: I2FAGetStore = yield select(getStoreData);
		const dataReq = {
			password: twoFAData.pass,
			enable  : false,
		};

		try {
			const res: AxiosApiResponse<IResConfirmPass> = yield call(twoFAApi.confirmPass2FA, typeID, dataReq);
			if (res.status === 200) {
				yield put(profileActions.profileDataReload());
				next(pageNumber);
				yield put(twoFactorReducers.setUI({ loading: false }));
			}
		} catch (e) {
			yield put(twoFactorReducers.setUI({ error: true, loading: false }));
		}
	});
}

// --------------------- TWO FA LOGIN --------------------------------

function* confirm2FALogin() {
	yield takeEvery(twoFAActions.CONFIRM_PASS_2FA_LOGIN, function* (action: IActionsConfirm2FALogin) {
		const { code, typeID } = action.data;
		yield put(twoFactorReducers.setUI({ error: false, loading: true }));
		const dataReq = {
			code,
		};

		try {
			const res: AxiosApiResponse<IResSMS2FA> = yield call(twoFAApi.confirm2FALogin, typeID, dataReq);
			const { data } = res.data;
			if (!data.error_code) {
				const reactiveToken = restoreReactiveToken();
				const userToken = reactiveToken || '';
				if (userToken) {
					const resultReactivate = yield call(profileApi.reactivateAccount);
					if (resultReactivate && resultReactivate.status === 200) {
						storeAuthToken(userToken);
						clearData(STORAGE_KEYS.SOCKET_AUTH_TOKEN);
						yield put(profileActions.profileDataReload());
						clearData(STORAGE_KEYS.REACTIVE_TOKEN);
					}
				} else {
					yield put(profileActions.profileDataReload());
				}
				yield put(twoFactorReducers.setUI({ error: false, loading: false }));
				yield put(modalReducers.setTwoFALoginModalUI({ visible: false }));
			} else {
				yield put(twoFactorReducers.setUI({
					error    : true,
					loading  : false,
					isAttempt: true,
					attempt  : data.attemptsLimit - data.attempts,
				}));
			}
		} catch (e) {
			yield put(twoFactorReducers.setUI({ error: true, loading: false }));
		}
	});
}

function* sendSMSFALogin() {
	yield takeEvery(twoFAActions.SEND_SMS_2FA_LOGIN, function* () {
		yield put(twoFactorReducers.setUI({ error: false, loading: true }));

		const typeID = TWO_FA_TYPES.smsAuth.toString();

		try {
			const res: AxiosApiResponse<IResSMS2FA> = yield call(twoFAApi.sendSms2FALogin, typeID, {});
			const { data } = res.data;
			const { codeExpTimeMin, sendDate, time_zone } = data;
			const resendTime = countResendTime(sendDate, time_zone, codeExpTimeMin * 60 );
			yield put(twoFactorReducers.setUI({ resendTime }));
			if (!data.error_code) {
				yield put(twoFactorReducers.setUI({ error: false, loading: false }));
			} else {
				yield put(twoFactorReducers.setUI({
					error    : true,
					loading  : false,
					isAttempt: true,
					attempt  : data.attemptsLimit - data.attempts,
				}));
			}
		} catch (e) {
			yield put(twoFactorReducers.setUI({ error: true, loading: false }));
		}
	});
}


export default function* twoFASaga() {
	yield all([
		fork(confirmPass2FA),
		fork(sendCodeApp2FA),
		fork(addActiveValue2FA),
		fork(send2FASms),
		fork(send2FASmsCode),
		fork(resendSms),
		fork(disabled2FA),
		fork(confirm2FALogin),
		fork(sendSMSFALogin),
	]);
}
