import React from 'react';
import { FormattedMessage } from 'react-intl';
import {  storeDenyDate } from '../../helpers/localStorageUtils';
import './pwa.scss';

//pwa_accept click handler added in pwa.js (src/pwa.js)
//styles in App.scss
const PwaComponent: React.FC = () => {
	const handleDenyClick = () => {
		storeDenyDate(new Date());
		const pwaBlock = document.getElementById('pwa_block');
		if (pwaBlock) {
			pwaBlock.style.opacity = '0';
			setTimeout(() => {
				pwaBlock.style.display = 'none';
			}, 400);
		}
	};
	return (
		<div id="pwa_block">
			<div className="block_inner">
				<div className="image_block">
					<img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
				</div>
				<div className="info_block">
					<div className="info_inner">
						<div className="pwa_title">
							<FormattedMessage id="pwa.title"/>
						</div>
						<div className="pwa_text">
							<FormattedMessage id="pwa.text"/>
						</div>
					</div>
					<div className="pwa_buttons">
						<button className="accept_btn" id="pwa_accept">
							<FormattedMessage id="pwa.accept"/>
						</button>
						<button className="deny_btn icon_close" onClick={handleDenyClick}></button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PwaComponent;
