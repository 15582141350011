import { AxiosResponse } from 'axios';

export const getHeaderTotalCount = (res: AxiosResponse): number => {
	return res.headers['x-total-count'] ? parseInt(res.headers['x-total-count'], 10) : 0;
};

export const createEntities = <T>(data: T[] = [], key: keyof T): Record<string, T> => {
	if (!key) {
		return {};
	}
	return data.reduce((acc: Record<string, T>, item) => {
		acc[item[key] as unknown as string] = item;
		return acc;
	}, {});
};

export function createEntitiesArray<T>(data: T[], key: keyof T) {
	return data.reduce((acc: { [key: number]: T[] }, item: T) => {
		const val = item[key];
		const numVal = Number(val);
		if (!isNaN(numVal)) {
			if (acc[numVal]) {
				acc[numVal].push(item);
			} else {
				acc[numVal] = [item];
			}
		}
		return acc;
	}, {});
}
