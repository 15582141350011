import React, { useEffect, useMemo } from 'react';
import Listing from '../../controls/listing';
import DropDownList from '../../controls/dropdown-list';
import SidebarBlog from '../sitebar-blog';
import SidebarFooter from '../sidebar-footer';
import menuActions from '../../../redux/menu/actions';
import { Desktop, NotDesktop } from '../../../helpers/devices';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { gamesSelectors } from '../../../redux/games/games_list/selectors';
import { menuSelectors } from '../../../redux/menu/selectors';
import { menuReducers } from '../../../redux/menu/slice';
import { defaultLanguage } from '../../../config';
import { ILeftCategories } from './types';

const {
	selectCategories,
} = gamesSelectors;
const {
	selectBottomMenu,
	selectBottomLeftMenu,
	selectSidebar,
} = menuSelectors;
const {
	toggleSidebar,
} = menuReducers;
const {
	getBottomMenuAction,
	getLeftMenuAction,
} = menuActions;
const SidebarBottom: React.FC = () => {

	const bottomMenu = useAppSelector(selectBottomMenu);
	const bottomLeftMenu = useAppSelector(selectBottomLeftMenu);
	const sidebar = useAppSelector(selectSidebar);
	const categories = useAppSelector(selectCategories);
	const currentLanguage = useAppSelector(store => store.Settings.current_language);
	const dispatch = useAppDispatch();

	const leftMenuCategories: ILeftCategories[] = useMemo(() => {
		const leftCategories = categories ? categories.filter(c => c.left_menu && c.alias) : [];
		return leftCategories.map(item =>({
			id   : item.id,
			name : item.name,
			icon : item.alias,
			url  : `/${item.alias}/`,
			alias: item.alias,
		}));
	}, [categories]);

	const onSidebarToggle = () => {
		dispatch(toggleSidebar());
	};

	useEffect(() => {
		dispatch(getBottomMenuAction());
		dispatch(getLeftMenuAction());
	}, [dispatch]);

	if (!bottomMenu) {
		return false;
	}

	if (!currentLanguage) {
		return  <div />;
	}

	return (
		<div className="sidebar_bottom">
			<Desktop>
				<div className="menues_container">
					<div className={`menues_list ${!sidebar ? 'closedSidbar': ''}`}>
						{leftMenuCategories.length > 0 &&
							<div className="main_menu">
								<Listing data={leftMenuCategories} langCode={currentLanguage.code || defaultLanguage.code} />
							</div>
						}
						{sidebar && <div className="second_menu">
							{bottomLeftMenu.columns && bottomLeftMenu.columns.length > 0 && (
								<DropDownList 
									data={bottomLeftMenu} 
									sidebar={sidebar} 
									callbackAction={onSidebarToggle}
									langCode={currentLanguage?.code || ''}
									translated 
								/>
							)}
						</div>}
					</div>
				</div>
			</Desktop>
			<NotDesktop>
				<div className="main_menu">
					<Listing langCode={currentLanguage?.code || defaultLanguage.code} data={leftMenuCategories} />
				</div>
				<div className="second_menu">
					<DropDownList 
						data={bottomLeftMenu} 
						sidebar={sidebar} 
						callbackAction={onSidebarToggle} 
						langCode={currentLanguage?.code || ''}
						translated 
					/>
				</div>
			</NotDesktop>
			<SidebarBlog />
			<SidebarFooter />
		</div>
	);
};

export default SidebarBottom;
