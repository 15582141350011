import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ROUTE_PATH } from '../../../routes/utils';

import './sidebar-user-actions.scss';
import { useParams } from 'react-router';

const lang = {
	profile   : <FormattedMessage id="profile.title"/>,
	promotions: <FormattedMessage id="promotions"/>,
};

const SidebarUserActions = () => {
	const { pathname } = useLocation();
	const { locale } = useParams();

	return (
		<div className="buttons_block">
			<div className="inner_block">
				<Link
					className={
						`icon_profile
						${(pathname === `/${locale}${ROUTE_PATH.root}${ROUTE_PATH.profile}`) ? 'active_nav_link' : ''}`
					}
					to={ROUTE_PATH.profile}>
					<span>{lang.profile}</span>
				</Link>
			</div>
			<div className="inner_block">
				<Link
					className={
						`icon_gift
					   ${pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.promotions}`) ? 'active_nav_link' : ''}`
					}
					to={`${ROUTE_PATH.promotions}/`}>
					<span>{lang.promotions}</span>
				</Link>
			</div>
		</div>
	);
};

export default SidebarUserActions;
