import { FormattedMessage } from 'react-intl';
import FooterApps from './components/footer-apps';
import FooterMenu from './components/footer-menu';
import LeftBlock from './components/leftBlock';
import Social from '../social';

import './components/components.scss';
import { AppCreateDate } from '../../../helpers/constants';

const Context =()=> {
	return (
		<div className="footer_middle" style={{ backgroundImage: 'url(/resources/images/footer_background.png)' }}>
			<div className="left_block">
				<LeftBlock />
			</div>
			<div className="right_block">
				<Social />
				<FooterMenu />
				<FooterApps />
			</div>
			<div className="copyright_text">
				<FormattedMessage id="sidebar_footer" values={{ b: msg => `© ${new Date(AppCreateDate).getFullYear()} - ${(new Date().getFullYear())} ${msg} ` }}/>
			</div>
		</div>
	);
};

export default Context; 