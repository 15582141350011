import { GamblingTypes } from '../../helpers/constants';
import { IResponsibleGambling, IRGamblingUIPayload, ITimeOutPayload } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from '../store';

const initialState: IResponsibleGambling =  {
	timeOutData: null,
	success    : {
		showSuccess: false,
		successMsg : '',
	},
	UI: {
		loading      : false,
		currentMenuId: GamblingTypes.TIME_OUT,
	},
};

const slice = createSlice({
	name    : 'gambling',
	initialState,
	reducers: {
		timeOutDataRefresh(state, action: PayloadAction<ITimeOutPayload>) {
			state.timeOutData = action.payload;
		},
		uiRefresh(state, action: PayloadAction<IRGamblingUIPayload>) {
			state.UI = { ...state.UI, ...action.payload };
		},
		resetSlice(){
			return cloneDeep(initialState);
		},
	},
});

export const RGamblingReducers = {
	timeOutDataRefresh: slice.actions.timeOutDataRefresh,
	uiRefresh         : slice.actions.uiRefresh,
	resetSlice        : slice.actions.resetSlice,
};

export const selectRGambling = (state: RootState) => state.RGamblingSlice;

export default slice.reducer;
