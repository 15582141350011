import { FC } from 'react';
import './loader.scss';
import { ILoaderProps } from './types';

const Loader: FC<ILoaderProps> = ({ notFixed }) => {
	return (
		<div className={`page_loader ${notFixed ? 'notfixed' : ''}`} style={{ backgroundImage: 'url(/resources/images/loader_bg.png)' }}>
			<div className="logo_block">
				<img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
			</div>
		</div>
	);
};

export default Loader;