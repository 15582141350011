import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks/storeHooks';
import { profileSelectors } from '../../../redux/profile/selectors';
import { BANNER_GROUPS } from '../../../redux/banners/types';
import { bannerActions } from '../../../redux/banners/actions';

import './payments.scss';
import { bannersSelectors } from '../../../redux/banners/selectors';

const {
	selectAuthToken,
} = profileSelectors;
const {
	selectFooterPayments,
} = bannersSelectors;
const Payments: React.FC = () => {
	const footerPayments = useAppSelector(selectFooterPayments);
	const authToken  = useAppSelector(selectAuthToken);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(bannerActions.getBanners(BANNER_GROUPS.footerPayments));
	}, [dispatch, authToken]);

	return (

		<ul className="payments_list">
			{
				footerPayments.map((item,index) => {
					if (item.link){
						return (
							<li key={index}>
								<a href={item.link} target='_blank' rel="noopener noreferrer">
									<img src={item.image_url} title={item.title} alt={item.name} />
								</a>
							</li>
						);
					}
					return (
						<li key={index}>
							<img src={item.image_url} title={item.title} alt={item.name} />
						</li>
					);
				})
			}
		</ul>
	);
};

export default Payments;
