import { PAYMENT_PROVIDERS, PAYMENT_TYPES } from '../../../../constants/payments';
import { currencyList } from '../../../../config';
import { IPaymentMethod } from '../../../../redux/deposit/types';
import { CurrencyList, PickOne } from '../../../deposit/types';
import { IWithdrawBaseError } from '../../../../redux/withdraw/types';

export enum PaymentFieldNames {
    muchBetter = 'muchBetter',
    jetonWalletP24 = 'jetonWalletP24',
    interkassaInpsCpaytrzTransferUpiInr = 'interkassaInpsCpaytrzTransferUpiInr',
    interkassaInpsCpaytrzTransferBankInr = 'interkassaInpsCpaytrzTransferBankInr',
    ifsc = 'ifsc',
    address = 'address',
    crypto = 'convert_to',
    missingNumbers = 'missing_numbers',
    masterCardEur = 'masterCardEur',
    amount = 'amount',
    number = 'card_id',
    phone = 'phone',
    account = 'account',
    ewallet_id = 'ewallet_id',
    email= 'email'
}

export type TPaymentName = keyof typeof PAYMENT_PROVIDERS;
export type TPaymentID = typeof PAYMENT_PROVIDERS[TPaymentName];

export interface  IPaymentMethodsFieldProps {
    onChange : (value: string, key: PaymentFieldNames) => void;
    paymentID: TPaymentID;
    currency : { code: keyof typeof currencyList };
    error    : Partial<IWithdrawBaseError>;
}

export interface IMasterCardEURProps {
    onChange : (value: string, key: PaymentFieldNames) => void;
    value    : string;
    paymentID: TPaymentID;

}

export enum WithdrawFieldNames {
    muchBetter = PaymentFieldNames.muchBetter,
    jetonWalletP24 = PaymentFieldNames.jetonWalletP24,
    interkassaInpsCpaytrzTransferUpiInr = PaymentFieldNames.interkassaInpsCpaytrzTransferUpiInr,
    interkassaInpsCpaytrzTransferBankInr = PaymentFieldNames.interkassaInpsCpaytrzTransferBankInr,
}

export type TPaymentType = keyof typeof PAYMENT_TYPES;
export interface IMissingNumberFieldProps {
    card_id    : string;
    onChange   : (value: string, key: PaymentFieldNames) => void;
    error      : string;
    paymentType: TPaymentType;
}

export interface ICard {
    id    : number;
    name  : string | null;
    number: string;
    label : string;
    value : string;
}

export interface IAdaptedCard extends Partial<ICard> {
    account: string;
    amount: {
        value: string;
        error: string;
    };
    loading: boolean;
    open   : boolean;
}
export interface IFormFieldProps {
    paymentType: keyof typeof PAYMENT_TYPES;
    cards?     : ICard[];
    error      : string;
    onChange   : (value: string, key: string) => void;
}

export interface ITronlinkProps {
    error        : Partial<IWithdrawBaseError>;
    className?   : string;
    paymentMethod : {
        max_withdraw: number;
        min_withdraw: number;
    }  & IPaymentMethod;
    handleWithdraw: () => void;
    cards?        : ICard[];
    currency: {
        code: PickOne<CurrencyList>;
    };
    onChange: (value: string, key: string) => void;
}
