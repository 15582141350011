import { useSelector } from 'react-redux';
import './footer.scss';
import Context from './Context';
import Payments from '../payments';
import FooterProviders from '../footer-providers';
import React from 'react';


const Footer: React.FC = () => {
	const layout = useSelector(({ Settings }) => Settings.layout);

	if (layout === 'game') {
		return null;
	}
	return (
		<div className="footer">
			<div className="custom_container">
				<Payments />
				<Context />
				<FooterProviders />
			</div>
		</div>
	);
};

export default Footer;
