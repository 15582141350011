import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import BonusAPI from './api';
import bonusActions from './actions';
import { adaptBonuses } from './adapter';
import { BONUS_STATUSES, bonusStatusIDs } from '../../constants/common';
import { restoreLocale } from '../../helpers/localStorageUtils';
import { setBonusItemLoading } from './utils';
import { logger } from '../../helpers/debugLogger';
import { profileReducers } from '../profile/slice';
import { bonusReducers } from './slice';
import { RootState } from '../store';
import {
	IBonusData,
	IBonusAdapted,
	IBonusParams,
	IBonusResponse,
	IClaimDeclineBonusPayload,
	IGetStore,
	ICasinoBonusesItem,
	ICasinoSpinItem,
} from './types';
import { AxiosApiResponse } from '../../service/types';
import { ISagaActionType } from '../types';
import { modalReducers } from '../modals/slice';
import { notificationReducers } from '../balloon-notification/slice';
import { IBalloonNotificationClaimType } from '../balloon-notification/types';
import { IHistoryFilters } from '../history/types';
import { getHeaderTotalCount } from '../utils';

const API = new BonusAPI();

const getStoreData = ({ Bonuses, Profile }: RootState): IGetStore => {
	const bonusData = Bonuses.bonusData;
	const profileData = Profile.profileData;
	return {
		bonusData,
		profileData,
	};
};

function* bonuses() {
	yield takeEvery(bonusActions.GET_BONUSES, function* () {
		const locale = restoreLocale();
		yield put(bonusReducers.setUI({ loading: true }));

		try {
			const params: IBonusParams = {
				status_ids : [bonusStatusIDs.CLAIMED, bonusStatusIDs.GRANTED],
				language_id: null,
			};
			if (locale) {
				params.language_id = locale.id;
			}
			const response: AxiosApiResponse<IBonusResponse> = yield call(API.getBonuses, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				const { bonuses, unclaimedBonusCount } = adaptBonuses(data);
				yield put(profileReducers.unclaimedBonusCountRefresh(unclaimedBonusCount));
				yield put(bonusReducers.setBonusData(bonuses));
				// yield put(bonusActions.setBonuses(bonuses));
			}
		} catch (e) {
			if (e instanceof Error) {
				const message = {
					title  : 'Error',
					message: e.message,
					type   : 'danger',
				};
				logger.log(message);
			}
		}

		yield put(bonusReducers.setUI({ loading: false }));
	});

}

function* claimBonus() {
	yield takeEvery(bonusActions.CLAIM_BONUS, function* (action: ISagaActionType<IClaimDeclineBonusPayload>) {
		yield put(bonusReducers.setUI({ loading: true }));
		const { bonusData = [], profileData }: IGetStore = yield select(getStoreData);
		const { data } = action;

		let isError = false;
		let statusID: BONUS_STATUSES | null = null;

		try {
			const mappedBonusData: IBonusAdapted[] = setBonusItemLoading(bonusData, data.id, true, isError);
			yield put(bonusReducers.setBonusData(mappedBonusData));
			const response: AxiosApiResponse<IBonusData> = yield call(API.claimBonus, data.bonus_id );
			if (response && response.status === 200) {
				statusID = response.data.data.status_id;
				const updatedProfileData = { ...profileData, unclaimed_bonus_count: Number(profileData.unclaimed_bonus_count) - 1 };
				yield put(profileReducers.setProfileData(updatedProfileData));

				const bonusClaimNotificationData: IBalloonNotificationClaimType = {
					visible    : true,
					title      : 'bonus_claimed_successfully',
					description: 'bonus_has_been_successfully_claimed',
					action     : notificationReducers.setBonusClaimUI,
				};
				yield put(notificationReducers.setBonusClaimUI(bonusClaimNotificationData));
			}
		} catch (e) {
			isError = true;

			if (e instanceof Error) {
				const message = {
					title  : 'Error',
					message: e.message,
					type   : 'danger',
				};
				logger.log(message);
			}
		}

		yield put(bonusReducers.setUI({ loading: false }));
		const mappedBonusData = setBonusItemLoading(bonusData, data.id, false, isError, statusID);
		yield put(bonusReducers.setBonusData(mappedBonusData));
		// yield put(bonusActions.setBonuses(mappedBonusData));
	});

}

function* declineBonus() {
	yield takeEvery(bonusActions.DECLINE_BONUS, function* (action: ISagaActionType<IClaimDeclineBonusPayload>) {
		const { bonusData = [] }: IGetStore = yield select(getStoreData);
		const { data } = action;
		yield put(modalReducers.setConfirmModalUI({ loading: true }));
		yield put(bonusReducers.setUI({ loading: true }));
		let isError = false;

		try {
			const mappedBonusData = setBonusItemLoading(bonusData, data.id, true, isError);
			// yield put(bonusActions.setBonuses(mappedBonusData));
			yield put(bonusReducers.setBonusData(mappedBonusData));

			const response: AxiosApiResponse<IBonusData>  = yield call(API.declineBonus, data.bonus_id);
			if (response && response.status === 200) {
				yield put(bonusActions.getBonuses());

				yield put(profileReducers.deleteBonusCount());
				yield put(modalReducers.setConfirmModalUI({ visible: false, item: null }));
				yield put(modalReducers.setBonusDetailsModalUI({ visible: false, item: null }));
			}
		} catch (e) {
			if (e instanceof Error) {
				const message = {
					title  : 'Error',
					message: e.message,
					type   : 'danger',
				};
				logger.log(message);
			}
			isError = true;
		}

		const mappedBonusData: IBonusAdapted[] = setBonusItemLoading(bonusData, data.id, false, isError);
		yield put(bonusReducers.setBonusData(mappedBonusData));
		yield put(bonusReducers.setUI({ loading: false }));
		yield put(modalReducers.setConfirmModalUI({ visible: false, item: null, loading: false }));

	});
}

function* casinoHistoryBonus() {
	yield takeEvery(bonusActions.GET_CASINO_BONUSES, function* (action: ISagaActionType<IHistoryFilters>) {
		const filters = action.data;
		try {
			yield put(bonusReducers.setCasinoBonuses({ UI: { loading: true } }));
			const response: AxiosApiResponse<ICasinoBonusesItem[]> = yield call(API.getCasinoBonuses, filters);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(bonusReducers.setCasinoBonuses({ data, dataCount: getHeaderTotalCount(response), UI: { loading: false } }));
			}
		} catch (e) {
			yield put(bonusReducers.setCasinoBonuses({ data: [], dataCount: 0, UI: { loading: false } }));
			logger.log(e);
		}
	});

}

function* casinoHistorySpins() {
	yield takeEvery(bonusActions.GET_CASINO_SPINS, function* (action: ISagaActionType<IHistoryFilters>) {
		const filters = action.data;
		try {
			yield put(bonusReducers.setCasinoSpins({ UI: { loading: true } }));
			const response: AxiosApiResponse<ICasinoSpinItem[]> = yield call(API.getCasinoSpins, filters);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(bonusReducers.setCasinoSpins({ data, dataCount: getHeaderTotalCount(response), UI: { loading: false } }));
			}
		} catch (e) {
			yield put(bonusReducers.setCasinoSpins({ data: [], dataCount: 0, UI: { loading: false } }));
			logger.log(e);
		}
	});

}

function* bonusSaga() {
	yield all([
		fork(bonuses),
		fork(claimBonus),
		fork(declineBonus),
		fork(casinoHistoryBonus),
		fork(casinoHistorySpins),
	]);
}

export default bonusSaga;
