import { FC, useState } from 'react';
import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IToolTipProps } from './types';
import './tooltip.scss';


export const ToolTip: FC<IToolTipProps> = ({
	children,
	tooltipText = '',
	info,
	dontShowIcon,
	minTextLength,
	customClassName,
}) => {
	const [showTooltip, setShowTooltip] = useState(false);

	const renderTooltip = () => {
		return (
			<span className={'tooltip_text'}>
				{tooltipText}
			</span>
		);
	};
	const hover = (val: boolean) => {
		if (!minTextLength) {
			setShowTooltip(val);
			return;
		}
		if (tooltipText.length > minTextLength) {
			setShowTooltip(val);
		} else {
			setShowTooltip(false);
		}
	};

	return (
		<Tooltip
			open={showTooltip}
			arrow
			leaveDelay={100}
			placement="top"
			title={renderTooltip()}
			onOpen={() => hover(true)}
			onClose={() => hover(false)}
			className={customClassName}
		>
			<span
				onClick={() => hover(!showTooltip)}
				className='tooltip-item'
			>
				{children}
				{!dontShowIcon ? !info ? <HelpOutlineIcon/> :
					<img width={'16px'} height={'14px'} style={{ marginInlineStart: '5px' }} src={'/resources/images/svg/info.svg'} alt={info}/>
					: null}
			</span>

		</Tooltip>
	);
};
