import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IBonusBalance, IBonusBalanceInitState } from './types';
import { cloneDeep } from 'lodash';

const initState: IBonusBalanceInitState = {
	bonusBalanceData: [],
};

const bonusBalance = createSlice({
	name        : 'bonusBalance',
	initialState: initState,
	reducers    : {
		setBonusBalance(state: Draft<IBonusBalanceInitState>, action: PayloadAction<IBonusBalance[]>) {
			state.bonusBalanceData = action.payload;
		},
		addBonusBalance(state: Draft<IBonusBalanceInitState>, action: PayloadAction<IBonusBalance>) {
			const clonedBonusBalance = cloneDeep(state.bonusBalanceData);
			clonedBonusBalance.unshift(action.payload);
			state.bonusBalanceData = clonedBonusBalance;
		},
	},
});


export const bonusBalanceReducers = {
	setBonusBalance: bonusBalance.actions.setBonusBalance,
	addBonusBalance: bonusBalance.actions.addBonusBalance,
};

export default bonusBalance.reducer;