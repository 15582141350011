import { FC } from 'react';
import { useSelector } from 'react-redux';
import NotificationsList from './notifications-list';
import DropDown from '../controls/dropdown'; 
import { useAppSelector } from '../../hooks/storeHooks';
import './notifications.scss';
import { notificationsSelectors } from '../../redux/notifications/selectors';

const {
	selectUI,
	selectMeta,
} = notificationsSelectors;
const Notifications: FC = () => {
	const { unread } = useSelector(selectMeta);
	const { dropDownVisible } = useAppSelector(selectUI);

	return (
		<div className="notifications">
			<DropDown
				btnIcon="notification"
				btnClass={unread ? 'new_income' : ''}
				dropDownVisible={dropDownVisible}
				notificationDropDown
			>
				<NotificationsList />
			</DropDown>
		</div>
	);
};

export default Notifications;

