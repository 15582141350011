import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { menuSelectors } from '../../../redux/menu/selectors';

import { useAppSelector } from '../../../hooks/storeHooks';
import { ToolTip } from '../../uiElements/ToolTip/ToolTip';
import { IBreadcrumbProps } from './types';
import './style.scss';

const {
	selectSitemap,
} = menuSelectors;
const Breadcrumb: React.FC<IBreadcrumbProps> = ({ excerpt, showHome }) => {
	const sitemap = useAppSelector((selectSitemap));
	const location = useLocation();
	const { pathname } = location;
	const pattern = pathname.replace(/\//g, '');
	const showBread = !pathname.includes('tournament') && !pathname.includes('game');

	if (excerpt.includes(pattern) || pattern === '')
		return null;

	const generateBreadCrumb = () => {
		let paths = pathname.split('/');

		// remove the last element if there was a / at the end of the pathname
		paths = paths[paths.length - 1] === '' ? paths.slice(0, paths.length - 1) : paths;

		// remove the first element if the second one is an empty string which means that we are in the root of the website
		paths = paths[1] === '' ? paths.slice(1) : paths;
		paths = paths.filter(path =>  path);

		const breadcrumb = paths.map((path, index) => {
			if (index === 0) {
				return (
					<li style={{ display: showHome ? 'inherit' : 'none' }} key={`rout-item-${index}`}>
						<Link key={index} to="/">
							<FormattedMessage id={'home'}/>
						</Link>
					</li>
				);
			}
			const url = paths.slice(0, index + 1).join('/');

			const label = sitemap[path] && index === paths.length-1
				? <p>{sitemap[path].label}</p>
				: sitemap[path] && sitemap[path].label ? sitemap[path].label
					: path;

			const isLabelString = (typeof label === 'string');
			const breadcrumb = isLabelString ? decodeURIComponent(label) : label;

			const breadcrumbText = typeof breadcrumb === 'string' ? breadcrumb : breadcrumb?.props?.children;
			if (index === paths.length - 1) {
				return (
					<li key={index}>
						<ToolTip
							dontShowIcon
							tooltipText={breadcrumbText}
							minTextLength={32}
						>
							<span>
								<FormattedMessage id ={breadcrumbText}/>
							</span>
						</ToolTip>
					</li>
				);
			}
			return (
				<li key={index}>
					{
						sitemap[path] && sitemap[path].url
							? (
								<Link to={`/${url}`}>
									<FormattedMessage id ={breadcrumbText}/>
								</Link>)
							: <span><FormattedMessage id ={breadcrumbText}/></span>
					}
				</li>
			);
		});

		// Return a list of links
		return (<ul className={!showHome ? 'note_slash' : ''}>{breadcrumb}</ul>);
	};
	return (
		<>
			{showBread && (<div className="breadcrumbs">
				<div className="custom_container">
					{generateBreadCrumb()}
				</div>

			</div>)
			}
		</>
	);
};

export default Breadcrumb;
