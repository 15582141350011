import { Link } from 'react-router-dom';
import { menuSelectors } from '../../../../redux/menu/selectors';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { LINK_ACTION_TYPE, MENU_DISPLAY_STATUS } from '../../../../redux/menu/types';
import React from 'react';

const {
	selectBottomMenu,
} = menuSelectors;
const FooterMenu: React.FC = () => {
	const bottom_menu = useAppSelector((selectBottomMenu));
	const currentLanguage = useAppSelector(state => state.Settings.current_language);

	return (
		<div className="footer_menu">
			{bottom_menu.columns.map((item, index) => {
				return (
					<ul data-title={item.title} key={index}>
						{item.menuItems.map((item, index) => {
							return (
								item.display_status_id === MENU_DISPLAY_STATUS.VISIBLE && (
									<li key={index}>
										<Link
											to={`/${currentLanguage?.code}${item.url}`}
											target={item.link_action_type !== LINK_ACTION_TYPE.SELF ? '_blank': undefined}
											className={'footer_menu_item'}
										>
											{item.link_title}
										</Link>
									</li>
								)
							);
						})
						}
					</ul>
				);
			})}
		</div>
	);
};

export default FooterMenu;
