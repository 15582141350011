import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks/storeHooks';
import { profileSelectors } from '../../../redux/profile/selectors';
import { BANNER_GROUPS } from '../../../redux/banners/types';
import { bannerActions } from '../../../redux/banners/actions';
import { bannersSelectors } from '../../../redux/banners/selectors';

import './providers.scss';


const {
	selectAuthToken,
} = profileSelectors;
const {
	selectFooterProviders,
} = bannersSelectors;
const Providers: React.FC = () => {

	const dispatch = useDispatch();
	const footerProviders = useAppSelector((selectFooterProviders));
	const authToken  = useAppSelector(selectAuthToken);

	useEffect(() => {
		dispatch(bannerActions.getBanners(BANNER_GROUPS.footerProviders));
	}, [dispatch, authToken]);

	return  footerProviders.map((item, inx) => {
		if (item.link) {
			return (
				<a key={item.id} href={item.link} target='_blank' rel="noopener noreferrer">
					<img src={item.image_url} title={item.title} alt={item.title}/>
				</a>
			);
		}
		return (
			<img src={item.image_url} title={item.title} alt={item.title} key={inx}/>
		);
	});
};

export default Providers;
