import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Desktop, NotDesktop } from '../../../helpers/devices';
import React from 'react';

const SidebarBonus: React.FC = () => {
	return (
		<>
			<Desktop>
				<div className="reg_bonus">
					<div className="bonus_description">
						<FormattedMessage id="regiter_promotion_message" values={{
							a: msg => <div className="about_bonus">{msg}</div>,
							b: msg => <div className="bonus_size">{msg}</div>,
						}}/>
					</div>
				</div>
			</Desktop>
			<NotDesktop>
				<div className="main_logo">
					<Link to="/">
						<img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
					</Link>
				</div>
			</NotDesktop>
		</>
	);
};

export default SidebarBonus;
