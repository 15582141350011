import moment from 'moment/moment';
import { TypesInput } from './types';

export function checkValue(val: string, type?: string) {
	if (!val) {
		return {
			error: true,
			value: '',
		};
	}

	const result = {
		error: false,
		value: '',
	};


	switch (type) {
		case TypesInput.phone: {
			const phoneNumberRegex = /\+[0-9]$/; // FOR '+' IN START REGEXP :   /\+[0-9]$/
			const isValidPhoneNumber = phoneNumberRegex.test(val);

			if (isValidPhoneNumber) {
				result.error = isValidPhoneNumber;
				result.value = val;
				return result;
			} else {
			// ------------------------------------------------------------------------------------
			// FOR '+' IN START DOWN COMMENTED CODE

				// const cleanedValue = val.replace(/[^+\d]/g, '');
				// const hasPlusAtStart = val.startsWith('+');
				// if (hasPlusAtStart) {
				// 	const firstPlusIndex = cleanedValue.indexOf('+');
				// 	if (firstPlusIndex !== -1) {
				// 		const res = cleanedValue.substring(0, firstPlusIndex + 1) + cleanedValue.substring(firstPlusIndex + 1).replace(/\+/g, '');
				// 		result.error = isValidPhoneNumber;
				// 		result.value = res;
				// 		return result;
				// 	}
				// }
				// ------------------------------------------------------------------------------------

				result.error = isValidPhoneNumber;
				result.value = val.replace(/\D/g, '');
				return result;
			}
		}
		case TypesInput.email: {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			result.error = !emailRegex.test(val);
			result.value = val;
			return result;
		}
		default: {
			result.error = false;
			result.value = val;
			return result;
		}
	}
}

export const countResendTime = (date?:string, timeZone?: string, resendInitialTime: number = 0) => {
	if (!timeZone) return 0;
	const resendTime = resendInitialTime * 1000;
	const dateHash = moment(date).utcOffset(timeZone).toDate().getTime();
	const timeExist = dateHash + resendTime;
	const duringTime = timeExist - new Date().getTime();
	if (duringTime > 0) {
		return Math.floor((duringTime) / 1000);
	}  else {
		return 0;
	}
};
