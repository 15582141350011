import React from 'react';
// import { FormattedMessage } from 'react-intl';
import { logger } from '../../helpers/debugLogger';
import { ErrorInfo } from 'react-dom/client';
import { FormattedMessage } from 'react-intl';


/* eslint-disable */
export class ErrorBoundary extends React.Component<{ children: React.ReactNode; id?: string }, { hasError: boolean }> {
	// @ts-ignore
    constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.onReload = this.onReload.bind(this);
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}


	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		logger.log(error, errorInfo);
	}

	onReload() {
		window.location.reload();
	}

	render() {
		if (this.state.hasError) {
			logger.log('Error boundary ID: ', this.props.id);
			return (
				<div>
					<FormattedMessage id="errorBoundary.error"/>
				</div>
			);
		}

		return this.props.children;
	}
}
