import React, { useEffect, useState } from 'react';
import { LiveChatWidget, WidgetState } from '@livechat/widget-react';
import { live_chat_license } from '../../../config';
import { ErrorBoundary } from '../../errorBoundary/ErrorBoundary';
import { profileSelectors } from '../../../redux/profile/selectors';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { helpSelectors } from '../../../redux/help/selector';
import { helpReducers } from '../../../redux/help/slice';
import { ILiveChatVisibility } from './types';
import { CHAT_VIEW_STATUS } from '../../../redux/help/types';

const {
	selectProfileData,
} = profileSelectors;
const {
	setChat,
} = helpReducers;
const {
	selectChat,
} = helpSelectors;

const Chat = () => {
	const profileData = useAppSelector(selectProfileData);
	const status = useAppSelector(selectChat);
	const [visibility, setVisibility] = useState<ILiveChatVisibility>('minimized');
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (visibility !== 'maximized' && status === CHAT_VIEW_STATUS.OPEN) {
			setVisibility('maximized');
		}
		// eslint-disable-next-line
	}, [status]);

	const minimize = (data: Pick<WidgetState, 'visibility'>) => {
		if (data.visibility === 'hidden') {
			setVisibility('minimized');
			dispatch(setChat(CHAT_VIEW_STATUS.CLOSE));
		}
	};

	let first_name = '';
	let last_name = '';
	let email = '';
	if (profileData) {
		first_name = profileData.first_name || '';
		last_name = profileData.last_name || '';
		email = profileData.email || '';
	}
	const name = `${first_name} ${last_name}`;

	return (
		<ErrorBoundary>
			<LiveChatWidget
				license={String(live_chat_license)}
				onVisibilityChanged={minimize}
				customerEmail={email}
				customerName={name}
				visibility={visibility}
			/>
		</ErrorBoundary>
	);
};

export default Chat;
